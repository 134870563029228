/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type Range from 'embercom/models/reporting/range';
import { inject as service } from '@ember/service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import {
  DYNAMIC_FILTER_TYPES,
  TIME_FILTER_IDENTIFIER,
  FilterLogicalOperators,
} from 'embercom/lib/reporting/custom/filter-helpers';
import { type MetricPropertyDefinition } from 'embercom/objects/reporting/unified/properties/types';
import { captureException } from 'embercom/lib/sentry';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';

export interface Filter {
  type: string;
  data: {
    attribute?: string;
    property: string;
    values?: any[];
    operators?: string[];
  };
}

export type BarFilter = {
  index: number;
  id: string;
  field: string;
  values?: any[] | Range | null;
  operator?: string;
};

type EmptyObject = Record<never, never>;
export type LogicalFilterOperator = 'and' | 'or';
export type LogicalFilter = AndFilter | OrFilter;
export type TopLevelFilter = LogicalFilter | EmptyObject | null;

interface AndFilter {
  type: 'and';
  filters: Filter[];
}

interface OrFilter {
  type: 'or';
  filters: Filter[];
}

export interface LogicalBarFilter {
  type: string;
  filters: BarFilter[];
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}
interface Args {
  chartConfig: RenderableChart;
  filters: AndFilter | OrFilter | null;
  filterableMetricProperties: MetricPropertyDefinition[];
  onFiltersChanged: (
    index: number,
    attribute: { id: string; field: string },
    filterValues: any,
    operator: any,
    chartSeries: ChartSeries | null,
  ) => void;
  applyLogicalFilterOperator?: (operator: LogicalFilterOperator) => void;
  onFiltersCleared: (chartSeries: ChartSeries | null) => void;
  conversationAttributeDescriptors: any;
  range: Range | null;
  showDateOverwritten: boolean;
  loadKnownValuesSources: string[];
  stickyFilterTypes?: unknown[];
  advancedFiltersEnabled: boolean;
  timezone: string | null;
  isAddFilterDisabled: boolean;
  chartSeries: ChartSeries | null;
  chartSeriesIndex: number | null;
}

export default class Filters extends Component<Signature> {
  @service declare reportingMetrics: ReportingMetrics;
  @service declare appService: any;

  get incomingFilters(): Filter[] {
    return this.args.filters?.filters || [];
  }

  getPropertyForPersistedFilter(filter: Filter): MetricPropertyDefinition {
    if (this.appService.app.canUseAttributesInFilters) {
      let attribute = filter.data.attribute;
      if (attribute) {
        return this.reportingMetrics.getPropertyById(attribute);
      } else {
        // TODO: we can remove this when we are sure that all filters have an attribute
        let error = new Error('Attribute is missing from filter');
        console.error(error);
        captureException(error);
      }
    }

    let property = this.args.filterableMetricProperties.find(
      ({ field }) => field === filter.data.property,
    );
    if (!property) {
      // For unsupported filters, search all known filters so a filter
      // can still be displayed even if it's not supported.
      property = this.reportingMetrics.metricProperties.find(
        ({ field }) => field === filter.data.property,
      );
    }
    if (!property) {
      // If the property is still not found, it's probably an archived CDA
      return {
        id: filter.data.property,
        field: filter.data.property,
      } as MetricPropertyDefinition;
    }
    return property;
  }

  get barFilters(): LogicalBarFilter {
    let filters: BarFilter[] = this.incomingFilters.map((filter, index) => {
      if (this.isDynamicFilter(filter.data.attribute || filter.data.property)) {
        return {
          id: filter.data.property,
          values: filter.data.values,
          field: filter.data.property,
          index,
          operator: filter.type,
        };
      }

      let property = this.getPropertyForPersistedFilter(filter);
      return {
        id: property.id,
        values: filter.data.values,
        field: property.field,
        index,
        operator: filter.type,
      };
    });

    if (this.args.range) {
      filters.push({
        id: TIME_FILTER_IDENTIFIER,
        values: this.args.range,
        field: TIME_FILTER_IDENTIFIER,
        index: filters.length,
      });
    }
    this.addStickyFilters(filters);

    return {
      type: this.args.filters?.type || FilterLogicalOperators.and,
      filters,
    };
  }

  private isDynamicFilter(id: string) {
    return DYNAMIC_FILTER_TYPES.some((type) => id.startsWith(type));
  }

  private filtersContainsFilterType(filters: BarFilter[], filterType: string) {
    return filters.some(
      (filter) =>
        filter.id === filterType ||
        (DYNAMIC_FILTER_TYPES.includes(filterType) && filter.id.startsWith(filterType)),
    );
  }

  private createNewFilter(filterType: string, index: number) {
    let result: BarFilter;
    let values: typeof result.values = [REPORTING_FILTER_SELECT_ALL];
    if (filterType === TIME_FILTER_IDENTIFIER) {
      values = this.args.range;
    }

    if (DYNAMIC_FILTER_TYPES.includes(filterType)) {
      return {
        id: filterType,
        field: filterType,
        values,
        index,
      };
    }
    let property = this.reportingMetrics.getPropertyById(filterType);
    return {
      id: property.id,
      field: property.field,
      values,
      index,
    };
  }

  private addStickyFilters(filters: BarFilter[]) {
    for (let filterType of this.stickyFilterTypes) {
      if (!this.filtersContainsFilterType(filters, filterType)) {
        filters.push(this.createNewFilter(filterType, filters.length));
      }
    }
  }

  get stickyFilterTypes() {
    return this.args.stickyFilterTypes?.map((filterType: any) => filterType.id) || [];
  }

  tryGetPropertyById(attributeId: string): MetricPropertyDefinition | undefined {
    return this.args.filterableMetricProperties.find(({ id }) => id === attributeId);
  }

  @action
  onFiltersChanged(index: number, attributeId: string, filterValues: any, operator: any) {
    // For dynamic properties (survey questions) we won't have a MetricPropertyDefinition
    // So we can just pass something that looks a bit like one
    let attribute = this.tryGetPropertyById(attributeId) || {
      id: attributeId,
      field: attributeId,
    };
    this.args.onFiltersChanged(index, attribute, filterValues, operator, this.args.chartSeries);
  }

  @action
  applyLogicalFilterOperator(operator: LogicalFilterOperator) {
    this.args.applyLogicalFilterOperator?.(operator);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::Filters': typeof Filters;
  }
}
