/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class Content extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service intl;

  @tracked showVerificationError = false;
  @tracked isVerifying = false;
  @tracked openSectionId;

  get app() {
    return this.appService.app;
  }

  get hasAllDnsRecordsAuthenticated() {
    return (
      this.args.domainSettings.dkimRecord?.validRecordExists &&
      this.args.domainSettings.customBounceSetting?.validRecordExists &&
      this.args.domainSettings.dkimRecord?.validDmarcExists
    );
  }

  get hasVerifiedEmails() {
    return (
      this.args.domainSettings.dkimRecord.hasVerifiedAdminEmails ||
      (this.customSenderAddresses &&
        this.customSenderAddresses.filter((address) => address.verified).length > 0)
    );
  }

  get customSenderAddresses() {
    return this.args.domainSettings.customAddresses;
  }

  @action onOpenSectionChange(newOpenSectionId) {
    this.openSectionId = newOpenSectionId;
  }

  @action
  async verifyDomain(domainSettings) {
    try {
      this.isVerifying = true;
      let dkimSettingsModel = domainSettings.dkimRecord;
      await dkimSettingsModel.verify();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.domains.manage.domain-verification-success'),
      );
    } catch (error) {
      this.showVerificationError = true;
    } finally {
      this.isVerifying = false;
    }
  }
}
