/* import __COLOCATED_TEMPLATE__ from './ticket-type-detail.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type Router from '@ember/routing/router-service';
import type TicketType from 'embercom/models/inbox/ticket-type';

interface Args {
  convertInboundEmailsToTicketsSettings: $TSFixMe;
  ticketType: TicketType;
  newSettings?: boolean;
}

export default class TicketTypeDetail extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare router: Router;

  @tracked showDescriptorDetailsModal = false;
  @tracked showTicketTypeModal = false;
  @tracked showArchiveTicketTypeModal = false;
  @tracked dependentObjects: $TSFixMe;
  @tracked showDuplicateTicketTypeModal = false;

  get ticketType() {
    return this.args.ticketType;
  }

  get breadcrumbsRoute() {
    return this.appService.app.canSeeNewIASettingsHub
      ? 'apps.app.settings.helpdesk.tickets.index'
      : 'apps.app.settings.ticket-data';
  }

  get categoryLabel() {
    return this.intl.t(this.ticketType.categoryLabelIntlKey);
  }

  get categoryIconName() {
    return this.ticketType.categoryIconData.name;
  }

  get categoryIconSet() {
    return this.ticketType.categoryIconData.set;
  }

  get duplicateButtonLabel() {
    return this.intl.t('settings.ticket-data.duplicate-ticket-type.duplicate');
  }

  get isCurrentTicketTypeInUse() {
    return (
      this.args.convertInboundEmailsToTicketsSettings?.ticketTypeId ===
      parseInt(this.args.ticketType?.id, 10)
    );
  }

  @action attributeCreated() {
    this.args.ticketType.reload();
  }

  @action archiveTicketType() {
    this.showTicketTypeModal = false;
    this.showArchiveTicketTypeModal = true;
  }

  @action setDependentObjects(dependentObjects: $TSFixMe) {
    this.showTicketTypeModal = false;
    this.dependentObjects = dependentObjects;
  }

  @action toggleDuplicateTicketTypeModal() {
    this.showDuplicateTicketTypeModal = !this.showDuplicateTicketTypeModal;
  }

  @action hideDependentObjects() {
    this.dependentObjects = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketData::TicketTypeDetail': typeof TicketTypeDetail;
    'settings/ticket-data/ticket-type-detail': typeof TicketTypeDetail;
  }
}
