/* import __COLOCATED_TEMPLATE__ from './report-access-request-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import type ReportAccessService from 'embercom/services/report-access-service';
import { AccessType } from 'embercom/services/report-access-service';
import type Admin from 'embercom/models/admin';

interface Signature {
  Args: {
    model: { reportId: string; onClose: () => void };
  };
}

class ReportAccessRequestModal extends Component<Signature> {
  @service declare modalService: $TSFixMe;
  @service declare store: Store;
  @service declare reportAccessService: ReportAccessService;
  @service declare appService: $TSFixMe;

  @tracked adminsWithPermission: Admin[] = [];
  onClose = this.args.model.onClose;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    taskFor(this.loadReportAccess).perform();
  }

  @task({ drop: true })
  *loadReportAccess() {
    let accessControlList: ReportAccessControlList[] = yield taskFor(
      this.reportAccessService.fetchReportAccess,
    ).perform(this.args.model.reportId);

    let adminIds = accessControlList
      .filter((access: ReportAccessControlList) => access.accessType === AccessType.EDIT)
      .map((access: ReportAccessControlList) => access.adminId);

    let report = this.store.peekRecord('reporting/custom/report', this.args.model.reportId);
    let reportOwner = report ? [report.createdById] : [];
    adminIds = [...reportOwner, ...adminIds];

    this.adminsWithPermission = this.appService.app.admins.filter(function (admin: any) {
      return adminIds.indexOf(admin.id) > -1;
    });
  }

  get isReportAdminsLoaded() {
    return taskFor(this.loadReportAccess).isIdle;
  }

  get adminsWhoCanChangePermissions() {
    return this.adminsWithPermission;
  }

  @action closeModal() {
    this.modalService.closeModal();
    this.onClose();
  }
}

export default ReportAccessRequestModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ReportAccessRequestModal': typeof ReportAccessRequestModal;
    'reporting/custom/report-access-request-modal': typeof ReportAccessRequestModal;
  }
}
