/* import __COLOCATED_TEMPLATE__ from './permissions-editor-with-seats-selector.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  getUnallowedPermissionsForSeats,
  getSeatDependentPermissions,
} from 'embercom/lib/seats-permissions-map';
import ModelWithPermissions from 'embercom/models/model-with-permissions';
import {
  SUPPORT_SEAT_TYPE as SUPPORT,
  PROACTIVE_SUPPORT_SEAT_TYPE as PROACTIVE_SUPPORT,
  COPILOT_SEAT_TYPE as COPILOT,
  COLLABORATOR_SEAT_TYPE as COLLABORATOR,
  PRICING_5_X_LITE_SEAT_TYPE as LITE,
  INVALID_SEAT_COMBINATIONS,
  CONVERSATION_SECTION_ID,
  COPILOT_SECTION_ID,
} from 'embercom/lib/settings/seats/constants';
import { COPILOT_PERMISSION } from 'embercom/lib/settings/copilot-access-editor/constants';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import toBackendPermissionKey from 'embercom/lib/settings/permissions/to-backend-permission-key';
import { tracked } from '@glimmer/tracking';

export default class PermissionsEditorWithSeatsSelector extends Component {
  @service appService;
  @service store;
  @service intl;
  @service permissionsHierarchyService;
  @service customerService;
  @tracked lastSwitchState = false;
  @tracked openSections = {
    [CONVERSATION_SECTION_ID]: false,
    [COPILOT_SECTION_ID]: true,
  };

  isSecondaryWorkspace = this.customerService.customer.isAnnualContractSecondarySubscription;

  constructor() {
    super(...arguments);
    this.toggleOffDisallowedPermissions();
    if (this.args.isInvite && !this.args.isEditInvite && this.isOnPricing5) {
      this.args.permissionsObject[COPILOT_PERMISSION] = true;
    }
  }

  get shouldShowRoleSelector() {
    return !!this.args.roles;
  }

  get hasCollaboratorSeat() {
    return this.args.seatTypes.includes(COLLABORATOR);
  }

  get hasLimitedInboxAccess() {
    return (
      (this.args.seatTypes.includes(COLLABORATOR) || this.args.seatTypes.includes(LITE)) &&
      this.args.seatTypes.length === 1
    );
  }

  get usingRole() {
    return this.shouldShowRoleSelector && this.args.permissionsObject.role !== null;
  }

  get disableConversationAccessControls() {
    return (
      (this.shouldShowRoleSelector && this.args.permissionsObject.role !== null) ||
      (isEmpty(this.args.seatTypes) && !this.args.ignoreSeatDisabling)
    );
  }

  get unallowedPermissionList() {
    return getUnallowedPermissionsForSeats(
      this.args.seatTypes,
      this.permissionsHierarchyService.seatPermissionMap,
    );
  }

  get seatDependentPermissionList() {
    return getSeatDependentPermissions(
      this.permissionsHierarchyService.seatPermissionMap,
      this.isOnPricing5,
    );
  }

  get rolesGroupList() {
    let roleItems = this._roles.map((role) => {
      return { text: role.name, value: role };
    });
    return [
      {
        items: [
          {
            text: this.intl.t('settings.teammates.edit.empty-role-label'),
            value: null,
          },
        ],
      },
      ...(!this.showAddRoleButton
        ? [
            {
              heading: this.intl.t('settings.teammates.edit.non-empty-role-label'),
              items: roleItems,
            },
          ]
        : []),
    ];
  }

  get _roles() {
    return (this.args.roles || []).sortBy('name');
  }

  get showAddRoleButton() {
    return !this._roles.length;
  }

  get isOnPricing5() {
    return this.appService.app.onPricing5;
  }

  get disabledInboxAccessTooltipContent() {
    if (this.isOnPricing5) {
      return this.intl.t(
        'settings.teammates.permissions.permissions-editor-with-seats-selector.assign-one-pricing5-seat',
        { htmlSafe: true },
      );
    } else {
      return this.intl.t(
        'settings.teammates.permissions.permissions-editor-with-seats-selector.assign-one-seat',
        { htmlSafe: true },
      );
    }
  }

  get shouldShowCopilotAccessEditor() {
    return this.appService.app.canUseFinAiCopilotAddon;
  }

  @action
  onChangeRole(role) {
    let conversationAccess;
    if (role === null && !this.args.permissionsObject.isNew) {
      this.args.permissionsObject.rollbackAttributes();
      conversationAccess = {
        accessType: 'all',
      };
    } else {
      conversationAccess = {
        accessType: role?.accessType || 'all',
        includeUnassigned: role?.includeUnassigned,
        assigneeBlockedList: role?.assigneeBlockedList,
      };

      if (!role?.can_inbox__access_copilot && !this.args.isBulkEditing) {
        this._removeCopilotSeatAndPermission();
      }
    }

    Object.assign(this.args.permissionsObject, role?.permissions(), conversationAccess, {
      role_id: role?.id || null,
    });
    this.toggleOffDisallowedPermissions();
  }

  _handleCopilotSeatChange(selectedSeat) {
    if (selectedSeat === LITE) {
      this._removeCopilotSeatAndPermission();
    } else if (selectedSeat === SUPPORT && this._togglingOffSupportSeat()) {
      this._removeCopilotSeatAndPermission();
    }
  }

  _togglingOffSupportSeat() {
    return !this.args.seatTypes.includes(SUPPORT);
  }

  _removeCopilotSeatAndPermission() {
    if (this.args.seatTypes.includes(COPILOT)) {
      this._removeSeat(COPILOT);
    }
    if (this.args.permissionsObject[COPILOT_PERMISSION]) {
      this.args.permissionsObject[COPILOT_PERMISSION] = false;
    }
  }

  @action
  toggleSeat(seatType) {
    this.args.seatTypes.includes(seatType)
      ? this._removeSeat(seatType)
      : this._assignSeat(seatType);

    if (this._noSeatActive()) {
      this.onChangeRole(null);
    }

    this._assignEffectivePermissions();
    if (this.args.onSeatChange) {
      this.args.onSeatChange();
    }
    if (this.appService.app.canUseFinAiCopilotAddon) {
      this._handleCopilotSeatChange(seatType);
    }
    this.toggleOffDisallowedPermissions();
  }

  @action handleChangeButtonClick(key, value) {
    if (this.args.seatsSelections[key] !== value) {
      this.args.seatsSelections[key] = value;
      this.toggleSeat(key);
    }
  }

  _assignEffectivePermissions() {
    Object.assign(this.args.permissionsObject, this.args.permissionsObject.role?.permissions());
    this.toggleOffDisallowedPermissions();
  }

  @action toggleOffDisallowedPermissions() {
    if (!this.args.ignoreSeatDisabling) {
      this.unallowedPermissionList.forEach((permissionKey) => {
        if (ModelWithPermissions.editablePermissionKeys.includes(permissionKey)) {
          this._updatePermission(permissionKey, false);
        } else if (
          this.permissionsHierarchyService.permissionsHierarchy[
            toBackendPermissionKey(permissionKey)
          ].type === 'crud'
        ) {
          this.permissionsHierarchyService.permissionsHierarchy[
            toBackendPermissionKey(permissionKey)
          ].actions.forEach((action) => {
            this._updatePermission(`can_${action}`, false);
          });
        }
      });
    }
  }

  _updatePermission(key, value) {
    if (this.args.permissionsObject[key] !== value) {
      this.args.permissionsObject[key] = value;
    }
  }

  _assignSeat(seatType) {
    this.args.seatTypes.pushObject(seatType);

    if (seatType in INVALID_SEAT_COMBINATIONS) {
      INVALID_SEAT_COMBINATIONS[seatType].forEach((seat) => {
        this.args.seatTypes.removeObject(seat);
        if (this.args.seatsSelections) {
          this.args.seatsSelections[seat] = false;
        }
      });
    }
  }

  _removeSeat(seatType) {
    this.args.seatTypes.removeObject(seatType);
    if (seatType === SUPPORT) {
      this.args.seatTypes.removeObject(PROACTIVE_SUPPORT);
      if (this.args.seatsSelections) {
        this.args.seatsSelections[PROACTIVE_SUPPORT] = false;
      }
    }
  }

  _noSeatActive() {
    return isEmpty(this.args.seatTypes);
  }

  @action
  setAllAccordions(state) {
    this.lastSwitchState = state;
    let sections = Object.values(this.permissionsHierarchyService.sections);
    let clonedOpenSections = {
      ...this.openSections,
      [CONVERSATION_SECTION_ID]: state,
      [COPILOT_SECTION_ID]: state,
    };
    sections.forEach((section) => {
      clonedOpenSections[section.key] = state;
    });
    this.openSections = clonedOpenSections;
  }

  @action
  onClickOpenSection(sectionId) {
    this.openSections = {
      ...this.openSections,
      [sectionId]: !this.openSections[sectionId],
    };
  }

  get showToggleAllPermissionsButton() {
    return !!this.args.isInvite || !!this.args.isNewRole || !this.disableToggles;
  }

  get disableToggleAllPermissionsButton() {
    if (this.args.isNewRole) {
      return false;
    }

    if (this.noSeatAssigned) {
      return !this.appService.app.hasMultipleSeatTypesPaywalled;
    }
    return this.args.permissionsObject.role !== null;
  }

  get isSelectAllState() {
    if (this.noSeatAssigned && !this.appService.app.hasMultipleSeatTypesPaywalled) {
      return true;
    }

    return this._doesNotHaveAllPermissions();
  }

  get noSeatAssigned() {
    let seatTypes = this.args.seatTypes;
    if (!seatTypes) {
      return true;
    }

    if (seatTypes.length === 0) {
      return true;
    }

    return false;
  }

  _doesNotHaveAllPermissions() {
    let permissions = { ...this.args.permissionsObject.permissions() };
    let permissionsKeys = Object.keys(permissions);

    if (this.args.isNewRole) {
      return permissionsKeys.some((perm) => permissions[perm] === false);
    }

    if (this.unallowedPermissionList) {
      return permissionsKeys.some(
        (perm) => permissions[perm] === false && !this.unallowedPermissionList.includes(perm),
      );
    }

    return permissionsKeys.some((perm) => permissions[perm] === false);
  }

  @action
  toggleSelectAll() {
    let permissions = { ...this.args.permissionsObject.permissions() };

    let selectAllPermissionsState = !!this.isSelectAllState;
    Object.keys(permissions).forEach((key) => {
      permissions[key] = selectAllPermissionsState;
    });

    Object.assign(this.args.permissionsObject, permissions);
    this.toggleOffDisallowedPermissions();
  }

  get disabledTooltipContent() {
    if (!this.args.seatTypes?.length) {
      if (this.appService?.app.onPricing5) {
        return this.intl.t(
          'settings.teammates.permissions.permissions-editor-with-seats-selector.assign-one-pricing5-seat',
          { htmlSafe: true },
        );
      } else {
        return this.intl.t(
          'settings.teammates.permissions.permissions-editor-with-seats-selector.assign-one-seat',
          { htmlSafe: true },
        );
      }
    }

    return this.intl.t(
      'components.settings.teammates.conversation-access-editor.change-conversation-access',
      { htmlSafe: true },
    );
  }
}
