/* import __COLOCATED_TEMPLATE__ from './tags-table.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tagFilterUrl } from 'embercom/vendor/intercom/tag-filter-url';
import EmberObject, { action } from '@ember/object';
import TagList from 'embercom/lib/tags/tag-settings-list';
import ENV from 'embercom/config/environment';
import { captureException } from 'embercom/lib/sentry';
import { tracked } from '@glimmer/tracking';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { IN_OPERATOR } from 'embercom/lib/outbound/constants';

export default class TagsTable extends Component {
  @service intl;
  @service intercomEventService;
  @service appService;
  @service store;
  @service notificationsService;
  @service intercomConfirmService;
  @service outboundHomeService;
  @service router;
  @service modelDataCacheService;

  @tracked rowBeingDeleted = null;
  @tracked rowBeingArchived = null;
  @tracked currentTab = 'live';

  confirmationModalContent = {};
  get app() {
    return this.appService.app;
  }

  constructor() {
    super(...arguments);

    this.confirmationModalContent = {
      title: this.intl.t('settings.data.tags-table.title'),
      body: this.intl.t('settings.data.tags-table.body'),
      confirmButtonText: this.intl.t('settings.data.tags-table.confirmButtonText'),
      cancelButtonText: this.intl.t('settings.data.tags-table.cancelButtonText'),
    };

    this.rows = this.args.tags.map((tag) => {
      return EmberObject.create({
        usersLink: tagFilterUrl(tag, 'user'),
        companiesLink: tagFilterUrl(tag, 'company'),
        conversationsLink: tagFilterUrl(tag, 'conversation'),
        editing: false,
        tag,
      });
    });

    this.allTagList = new TagList(this.rows);
    this.liveTagList = new TagList(this.rows.reject((row) => row.tag.archived));
    this.archivedTagList = new TagList(this.rows.filter((row) => row.tag.archived));

    this.loadStats.perform();
  }

  get hasTags() {
    return this.liveTagList.rows.length > 0 || this.archivedTagList.rows.length > 0;
  }

  get tagList() {
    return this.currentTab === 'live' ? this.liveTagList : this.archivedTagList;
  }

  get columns() {
    let columns = [];
    columns.push({
      label: this.intl.t('settings.data.tags-table.tag-name'),
      valuePath: 'tag.name',
      width: '30%',
      isSortable: true,
    });

    if (this.currentTab === 'live') {
      columns.push(
        {
          label: this.intl.t('settings.data.tags-table.created'),
          valuePath: 'tag.created_at',
          isSortable: true,
        },
        {
          label: this.intl.t('settings.data.tags-table.created-by'),
          valuePath: 'tag.createdBy.name',
          isSortable: true,
        },
      );
    } else {
      columns.push(
        {
          label: this.intl.t('settings.data.tags-table.archived-without-live'),
          valuePath: 'tag.archived_at',
          isSortable: true,
        },
        {
          label: this.intl.t('settings.data.tags-table.archived-by-without-live'),
          valuePath: 'tag.archivedBy.name',
          isSortable: true,
        },
      );
    }

    columns.push(
      {
        label: this.intl.t('settings.data.tags-table.people'),
        valuePath: 'tag.stats.userCount',
        type: 'number',
        isSortable: true,
      },
      {
        label: this.intl.t('settings.data.tags-table.companies'),
        valuePath: 'tag.stats.companyCount',
        type: 'number',
        isSortable: true,
      },
      {
        label: this.intl.t('settings.data.tags-table.coversation'),
        valuePath: 'tag.stats.conversationCount',
        type: 'number',
        isSortable: true,
        tooltip: this.intl.t('settings.data.tags-table.number-of-use'),
      },
      {
        label: this.intl.t('settings.data.tags-table.messages'),
        valuePath: 'tag.stats.messageCount',
        type: 'number',
        isSortable: true,
      },
      {
        label: this.intl.t('settings.data.tags-table.articles'),
        valuePath: 'tag.stats.articleCount',
        type: 'number',
        isSortable: true,
      },
      {
        label: this.intl.t('settings.data.tags-table.answers'),
        valuePath: 'tag.stats.answerCount',
        type: 'number',
        isSortable: true,
      },
    );

    return columns;
  }

  get rowBeingEdited() {
    return this.tagList.rows.findBy('editing', true);
  }

  setRowBeingEdited(row) {
    this.clearRowBeingEdited();
    row.set('editing', true);
  }

  showOrganizeConversationsArticle() {
    window.Intercom('showArticle', 7126365); // https://www.intercom.com/help/en/articles/7126365-when-should-i-use-conversation-topics-attributes-and-tags?location=conversation
  }

  showAcademyLessonArticle() {
    window.Intercom('showArticle', 4922057); // https://www.intercom.com/help/en/articles/4922057-learn-about-conversation-topics-in-intercom-academy
  }

  clearRowBeingEdited() {
    this.tagList.rows.setEach('editing', false);
  }

  @action
  selectLiveTab() {
    this.currentTab = 'live';
  }

  @action
  selectArchivedTab() {
    this.currentTab = 'archived';
  }

  @task({ drop: true })
  *saveTag(row) {
    let tag = row.tag;
    let tagName = tag.name.trim();

    if (!tagName) {
      this.notificationsService.notifyError(
        this.intl.t('settings.data.tags-table.tag-without-name'),
      );
    } else if (
      this.tagList.rows.some((row) => {
        return (
          row.tag !== tag &&
          row.tag.name.localeCompare(tagName, undefined, { sensitivity: 'accent' }) === 0
        );
      })
    ) {
      this.notificationsService.notifyError(
        this.intl.t('settings.data.tags-table.name-already-taken'),
      );
    } else {
      try {
        yield tag.save();
        this.app.tags.addObject(tag);
        this.app.updateLocalCache(this.modelDataCacheService);
        this.clearRowBeingEdited();
      } catch (error) {
        captureException(error);
        this.notificationsService.notifyError(
          this.intl.t('settings.data.tags-table.failed-to-save'),
        );
      }
    }
  }

  @action
  async onSort(valuePath) {
    let canContinue = await this.abortEditing.perform();

    if (!canContinue) {
      return;
    }
    this.tagList.updateSort(valuePath);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'sorted',
      object: valuePath,
    });
  }

  @action
  onLoadMore() {
    this.tagList.loadMoreRows();
  }

  @task
  *abortEditing() {
    if (!this.rowBeingEdited) {
      return true;
    }

    let tag = this.rowBeingEdited.tag;

    let canAbandonWithoutChecking = !tag.hasDirtyAttributes;
    let userWantsToContinue;
    if (!canAbandonWithoutChecking) {
      userWantsToContinue = yield this.intercomConfirmService.confirm(
        this.confirmationModalContent,
      );
    }
    let shouldAbandonChanges = canAbandonWithoutChecking || !userWantsToContinue;

    if (shouldAbandonChanges) {
      if (tag.isNew) {
        this.tagList.remove(this.rowBeingEdited);
        tag.deleteRecord();
      } else {
        tag.rollbackAttributes();
      }

      this.clearRowBeingEdited();
      return true;
    } else {
      let tagId = this.rowBeingEdited.tag.id ? this.rowBeingEdited.tag.id : '';
      let input = document.getElementById(`tag-name-editor-${tagId}`);
      input.focus();
      return false;
    }
  }

  @action
  tagDeletionFinished(result) {
    if (result?.success) {
      this.tagList.remove(this.rowBeingDeleted);
    }
    this.rowBeingDeleted = null;
    this.selectLiveTab();
  }

  @action
  tagArchiveFinished(result) {
    if (result?.success) {
      this.liveTagList.remove(this.rowBeingArchived);
      if (result.action === 'archived') {
        this.archivedTagList.add(this.rowBeingArchived);
      }
    }
    this.rowBeingArchived = null;
  }

  @action
  async createTag() {
    if (!(await this.abortEditing.perform())) {
      return;
    }

    await this.switchTab('live');

    let newTagStats = this.store.createRecord('tag-stats');
    let newTag = this.store.createRecord('tag', {
      created_at: Date.now(),
      createdBy: this.app.get('currentAdmin'),
      stats: newTagStats,
    });

    let newRow = EmberObject.create({
      tag: newTag,
    });

    this.tagList.addToTop(newRow);
    this.setRowBeingEdited(newRow);
    return newRow;
  }

  @action
  async editTag(row) {
    let canEditThisRow = await this.abortEditing.perform();

    if (!canEditThisRow) {
      return;
    }

    this.setRowBeingEdited(row);
  }

  @action
  requestDeletion(row) {
    this.rowBeingDeleted = row;
  }

  @action
  requestArchive(row) {
    this.rowBeingArchived = row;
  }

  get liveTagsListLabel() {
    return `${this.intl.t('settings.ticket-data.attribute-tabs.live')} (${this.liveTagList.rows.length})`;
  }

  get archivedTagsListLabel() {
    return `${this.intl.t('settings.ticket-data.attribute-tabs.archived')} (${this.archivedTagList.rows.length})`;
  }

  get label() {
    if (this.currentTab === 'live') {
      return this.liveTagsListLabel;
    }
    return this.archivedTagsListLabel;
  }

  @task({ drop: true })
  *restoreTag(row) {
    try {
      let tag = row.tag;
      tag.archived = false;
      yield tag.save();

      this.archivedTagList.remove(row);
      this.liveTagList.add(row);

      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.data.tags-table.restored', { name: tag.name }),
      );
      this.app.updateLocalCache(this.modelDataCacheService);

      this.intercomEventService.trackAnalyticsEvent({
        action: 'restored',
        object: tag,
      });
      this.selectLiveTab();
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyError(
        this.intl.t('settings.data.tags-table.restore-request-failed'),
      );
    }
  }

  @action
  async focusSearchInput(row) {
    return this.abortEditing.perform();
  }

  @task({ restartable: true })
  *debouncedSearch(event) {
    let query = event.target.value;
    yield timeout(ENV.APP._200MS);
    this.tagList.filterBy(query);
  }

  @action
  transitionToMessages(row) {
    let selectedObjectTypes = [
      ...this.outboundHomeService.contentToDisplay,
      objectTypes.series,
    ].map((c) => objectNames[c]);
    this.router.transitionTo('apps.app.outbound.all', {
      queryParams: {
        tagIds: [row.tag.id],
        tagOperator: IN_OPERATOR,
        selectedObjectTypes,
      },
    });
  }

  @action
  transitionToArticles(row) {
    this.router.transitionTo('apps.app.articles.articles.list.all', {
      queryParams: {
        tag_ids: [row.tag.id],
      },
    });
  }

  @action
  transitionToAnswers(row) {
    this.router.transitionTo('apps.app.automation.resolution-bot.answers', {
      queryParams: {
        status: 'all',
        tag_ids: [row.tag.id],
      },
    });
  }

  @task({ drop: true })
  *loadStats() {
    yield this.store.findAll('tag', { include: 'stats', reload: true });
  }

  @action
  async switchTab(tab) {
    if (!(await this.abortEditing.perform())) {
      return;
    }
    this.currentTab = tab;
  }
}
