/* import __COLOCATED_TEMPLATE__ from './share-options.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { type AccessOption } from 'embercom/components/reporting/custom/report/share';
import { action } from '@ember/object';
import { indexBy } from 'underscore';
import { tracked } from '@glimmer/tracking';
import { AccessType } from 'embercom/services/report-access-service';
import { dropTask } from 'ember-concurrency-decorators';
import type Admin from 'embercom/models/admin';
import { taskFor } from 'ember-concurrency-ts';
import type RouterService from '@ember/routing/router-service';

interface Args {
  isWorkspace: boolean;
  subtle: boolean;
  selectedValue: AccessType | null;
  onSelectItem: (value: AccessOption) => void;
  removeAccess?: any;
  admin?: Admin;
  canRemoveAccess: boolean;
  hasConflict: boolean;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

interface SelectedValue {
  text: string;
  description: string;
  accessType: AccessType;
}

export default class ReportingCustomReportShareOptions extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked hoverIndex = 0;

  mappings = indexBy(this.accessOptions, 'accessType');

  get selectedValue() {
    if (this.args.selectedValue) {
      return this.args.selectedValue;
    }
    return this.args.isWorkspace ? null : AccessType.EDIT;
  }

  get baseOptions(): AccessOption[] {
    return [
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.full-access'),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.full-access-description',
        ),
        accessType: AccessType.EDIT,
      },
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.explore-only'),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.explore-only-description',
        ),
        accessType: AccessType.VIEW,
      },
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.view-only'),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.view-only-description',
        ),
        accessType: AccessType.RESTRICTED_VIEW,
      },
    ];
  }

  @action
  mapAccessTypeToText(accessType: AccessType | null) {
    if (accessType) {
      return this.mappings[accessType].text;
    }
    return this.intl.t('reporting.custom-reports.report.share-modal.no-access');
  }

  get accessOptions() {
    if (this.args.isWorkspace) {
      return this.workspaceAccessOptions;
    }
    return this.teammateAccessOptions;
  }

  get teammateAccessOptions() {
    return this.baseOptions.map((option) => {
      return {
        ...option,
        isSelected: option.accessType === this.selectedValue,
      };
    });
  }

  get workspaceAccessOptions() {
    return [
      ...this.baseOptions,
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.no-access'),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.no-access-description',
        ),
        accessType: null,
      },
    ].map((option) => {
      return {
        ...option,
        isSelected: option.accessType === this.selectedValue,
      };
    });
  }

  @action
  onSelectItem(selectedValue: SelectedValue) {
    this.args.onSelectItem(selectedValue);
  }

  @action
  onShow() {
    this.hoverIndex = 0;
  }

  @action
  onHover(index: number) {
    this.hoverIndex = index;
  }

  @dropTask
  *removeAccess(admin?: Admin) {
    if (admin && this.args.removeAccess) {
      taskFor(this.args.removeAccess).perform(admin);
      yield;
    }
  }

  @action
  conflictAction() {
    if (this.args.admin) {
      this.router.transitionTo(
        'apps.app.settings.workspace.teammates.teammate.permissions',
        this.args.admin.id,
      );
    }
  }

  get conflictText() {
    let accessType = this.selectedValue;

    if (!this.args.admin && !accessType) {
      return undefined;
    }

    let adminPermission = this.args.admin!.currentAppPermissions;

    if (
      [AccessType.VIEW, AccessType.RESTRICTED_VIEW].includes(accessType!) &&
      !adminPermission.can_access_reporting
    ) {
      return this.intl.t('reporting.custom-reports.report.share-modal.restricted-view-permission', {
        htmlSafe: true,
      });
    } else {
      return this.intl.t('reporting.custom-reports.report.share-modal.restricted-edit-permission', {
        htmlSafe: true,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ShareOptions': typeof ReportingCustomReportShareOptions;
    'reporting/custom/report/share-options': typeof ReportingCustomReportShareOptions;
  }
}
