/* import __COLOCATED_TEMPLATE__ from './duplicate-ticket-type-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';

export default class extends Component {
  @service appService;
  @service notificationsService;
  @service intl;
  @service router;

  get ticketType() {
    return this.args.ticketTypeToDuplicate;
  }

  get modalTitle() {
    return this.intl.t('settings.ticket-data.duplicate-ticket-type.modal.title', {
      ticketTypeName: this.ticketType.name,
    });
  }

  get modalDescription() {
    return this.intl.t('settings.ticket-data.duplicate-ticket-type.modal.description', {
      ticketTypeName: this.ticketType.name,
    });
  }

  @task({ drop: true })
  *duplicateTicketTypeTask() {
    yield this.duplicateTicketType(this.ticketType);
  }

  async duplicateTicketType(ticketType) {
    try {
      let json = await post(
        `/ember/inbox/ticket_types/${ticketType.id}/duplicate/?app_id=${this.appService.app.id}`,
        {
          app_id: this.appService.app.id,
        },
      );
      this.args.onModalClose();
      let newTicketTypeId = json[0].id;
      let routeName = this.args.newSettings
        ? 'apps.app.settings.helpdesk.tickets.ticket-types.detail'
        : 'apps.app.settings.ticket-data.detail';
      this.router.transitionTo(routeName, newTicketTypeId);
    } catch (error) {
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.duplicate-ticket-type.duplicate-name'),
        );
      } else if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.duplicate-ticket-type.failure', {
            ticketTypeName: ticketType.name,
          }),
        );
      }
    }
  }
}
