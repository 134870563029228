/* import __COLOCATED_TEMPLATE__ from './email-address-create-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class EmailAddressCreateButton extends Component {
  @service intercomEventService;
  @tracked showAddCustomSenderModal = false;

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_settings_page',
      });
    }
  }
}
