/* import __COLOCATED_TEMPLATE__ from './invite-list-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import { filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import TeammateListBaseComponent from 'embercom/components/settings/teams/teammate-list-base-component';

export default TeammateListBaseComponent.extend({
  intl: service(),
  appService: service(),
  invites: null,
  invitesWithInboxAccess: filterBy('invites', 'hasInboxAccess', true),
  columns: computed(
    'intl.locale',
    'app.{inboxIsActive,requiresInboxSeatAccess,isOnFree,adminsWithInboxAccess.[],canUseSeatOverages,customer.currentlyOnEarlyStage,isSalesforceContracted}',
    'freeSeatCount',
    'invitesWithInboxAccess.[]',
    'pricePerSeat',
    function () {
      let columns = [];
      columns.push(
        this.createColumn(
          this.intl.t('settings.teammates.invite.invite-list.name'),
          true,
          'table-cells/invite-list/name-cell',
        ),
      );
      columns.push(this.createColumn('', false, 'table-cells/invite-list/edit-cell'));
      columns.push(
        this.createColumn(
          this.intl.t('settings.teammates.invite.invite-list.status'),
          true,
          'table-cells/invite-list/status-cell',
        ),
      );

      if (this.get('app.inboxIsActive') && this.get('app.requiresInboxSeatAccess')) {
        let inboxSeatColumnHeader = `<span data-test-inbox-seat-header>${this.intl.t(
          'settings.teammates.invite.invite-list.inbox-seat',
          {
            seatCount: this.invitesWithInboxAccess.length,
          },
        )}</span>`;
        if (this.app.isSalesforceContracted && this.app.canUseSeatOverages) {
          columns.push(
            this.createColumn(
              sanitizeHtml(inboxSeatColumnHeader),
              true,
              'table-cells/invite-list/inbox-seat-cell',
              {
                component: 'table-cells/teammate-list/inbox-seat-column-tooltip-with-opener',
                data: {
                  showPendingInviteInclusionText: true,
                  contractedInboxSeatCount: this.freeSeatCount,
                  inboxSeatCount:
                    this.app.adminsWithInboxAccess.length + this.invitesWithInboxAccess.length,
                },
              },
            ),
          );
        } else {
          let toolTipText = this.get('app.isOnFree')
            ? this.intl.t('settings.teammates.invite.invite-list.free-plan-seats')
            : this.intl.t('settings.teammates.invite.invite-list.paid-plan-seats', {
                pricePerSeat: this.pricePerSeat,
                freeSeatCount: this.freeSeatCount,
              });
          if (this.get('app.customer.currentlyOnEarlyStage')) {
            toolTipText = '';
          }
          columns.push(
            this.createColumn(
              sanitizeHtml(inboxSeatColumnHeader),
              true,
              'table-cells/invite-list/inbox-seat-cell',
              toolTipText,
            ),
          );
        }
      }

      if (this.get('app.inboxIsActive')) {
        columns.push(
          this.createColumn(
            this.intl.t('settings.teammates.invite.invite-list.teams'),
            true,
            'table-cells/invite-list/teams-cell',
          ),
        );
      }

      columns.push(this.createColumn('', false, 'table-cells/invite-list/resend-cell-wrapper'));
      columns.push(this.createColumn('', false, 'table-cells/invite-list/copy-link-cell-wrapper'));
      columns.push(this.createColumn('', false, 'table-cells/invite-list/revoke-cell-wrapper'));
      return columns;
    },
  ),
});
