/* import __COLOCATED_TEMPLATE__ from './highcharts-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { type Signature } from './highcharts-wrapper';

export default class HighchartsChart extends Component<Signature> {
  @service detectIntercomradesDomainService: $TSFixMe;
  @service declare router: Router;

  get isDebuggingAllowed() {
    return (
      this.detectIntercomradesDomainService.isIntercomradesDomain &&
      this.router?.currentRoute?.queryParams?.debug
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::HighchartsChart': typeof HighchartsChart;
    'reporting/flexible/highcharts-chart': typeof HighchartsChart;
  }
}
