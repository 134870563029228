/* import __COLOCATED_TEMPLATE__ from './edit-nav-folder.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  item: {
    name: string;
    updateFolder: () => void;
    pinned: boolean;
  };
}
interface Signature {
  Args: Arguments;
}
const EditNavFolder = templateOnlyComponent<Signature>();
export default EditNavFolder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::EditNavFolder': typeof EditNavFolder;
    'reporting/nav-submenu-components/edit-nav-folder': typeof EditNavFolder;
  }
}
