/* RESPONSIBLE TEAM: team-ai-insights */

function and(...filters: any) {
  return {
    type: 'and',
    filters: filters.flatMap((f: any) => (f.type === 'and' ? f.filters : f)),
  };
}

const agentTypes = ['human', 'ai-agent', 'workflow'];
type AgentType = (typeof agentTypes)[number];

const filters = {
  everInvolved(agentTypes: AgentType | AgentType[]) {
    return {
      type: 'category',
      data: {
        property: 'agent_types_involved',
        attribute: 'conversation.agent_types_involved',
        values: Array.isArray(agentTypes) ? agentTypes : [agentTypes],
      },
    };
  },

  notEverInvolved(agentTypes: AgentType | AgentType[]) {
    return {
      ...this.everInvolved(agentTypes),
      type: 'not_in_category',
    };
  },

  onlyInvolved(agentType: AgentType) {
    let otherAgentTypes = agentTypes.filter((t) => t !== agentType);
    return and(this.everInvolved(agentType), this.notEverInvolved(otherAgentTypes));
  },

  get teammateWasEverInvolved() {
    return this.everInvolved('human');
  },

  get teammateWasNeverInvolved() {
    return this.notEverInvolved('human');
  },

  get teammateWasOnlyInvolved() {
    return this.onlyInvolved('human');
  },

  get finWasEverInvolved() {
    return this.everInvolved('ai-agent');
  },

  get finWasNeverInvolved() {
    return this.notEverInvolved('ai-agent');
  },

  get chatbotWasEverInvolved() {
    return this.everInvolved('workflow');
  },

  get chatbotWasOnlyInvolved() {
    return this.onlyInvolved('workflow');
  },

  get noReply() {
    return {
      type: 'not_exists',
      data: {
        property: 'agent_types_involved',
        attribute: 'conversation.agent_types_involved',
      },
    };
  },

  get finResolved() {
    return {
      type: 'category',
      data: {
        property: 'conversation_custom_fields#ai_chatbot_resolution_state',
        attribute: 'conversation_custom_fields#ai_chatbot_resolution_state',
        values: ['soft_resolution', 'hard_resolution'],
      },
    };
  },

  get finNotResolved() {
    return {
      ...this.finResolved,
      type: 'not_in_category',
    };
  },

  get closed() {
    return {
      type: 'category',
      data: {
        property: 'conversation_state',
        attribute: 'conversation.conversation_state',
        values: ['closed'],
      },
    };
  },
};

const oldFilters = {
  get handledByFin() {
    return {
      type: 'category',
      data: {
        property: 'fin.participated',
        attribute: 'conversation.fin.participated',
        values: [true],
      },
    };
  },
  get handledByFinAndClosed() {
    return and(this.handledByFin, this.closed);
  },
  get handledByTeammateAndClosed() {
    return and(this.handledByTeammate, this.closed);
  },
  get handledByFinAndClosedWithoutTeammate() {
    return and(this.handledByFin, this.notHandledByTeammate, this.closed);
  },
  get handledByFinAndTeammate() {
    return and(this.handledByFin, this.handledByTeammate);
  },
  get handledByFinAndTeammateAndClosed() {
    return and(this.handledByFinAndTeammate, this.closed);
  },
  get notHandledByFin() {
    return {
      type: 'category',
      data: {
        property: 'fin.participated',
        attribute: 'conversation.fin.participated',
        values: [false],
      },
    };
  },
  get notHandledByTeammate() {
    return {
      type: 'not_exists',
      data: {
        property: 'first_reply_teammate_id',
        attribute: 'conversation.first_reply_teammate_id',
      },
    };
  },
  get handledByChatbot() {
    return and(this.notHandledByFin, this.notHandledByTeammate);
  },
  get handledByChatbotAndClosed() {
    return and(this.handledByChatbot, this.closed);
  },
  get handledByTeammate() {
    return {
      type: 'exists',
      data: {
        property: 'first_reply_teammate_id',
        attribute: 'conversation.first_reply_teammate_id',
      },
    };
  },
  get handledByTeammateAndNotFin() {
    return and(this.handledByTeammate, this.notHandledByFin);
  },
  get handledByTeammateAndNotFinAndClosed() {
    return and(this.handledByTeammateAndNotFin, this.closed);
  },
  get closed() {
    return {
      type: 'category',
      data: {
        property: 'conversation_state',
        attribute: 'conversation.conversation_state',
        values: ['closed'],
      },
    };
  },
};

export function wrap(filters: any) {
  // because of assumptions made elsewhere in the code, it's easier to
  // just make sure there's always a top level logical filter
  if (filters && filters.type !== 'and' && filters.type !== 'or') {
    return {
      type: 'and',
      filters: [filters],
    };
  }
  return filters;
}

export function funnelSeries(filters?: any, label?: string) {
  return {
    label,
    metric_id: 'v1.new_conversations',
    filters: wrap(filters),
  };
}

function config(name: string, filters?: any) {
  return { name, series: funnelSeries(filters, name) };
}

interface Metrics {
  conversations_total: number;
  noReplyAndNotClosed: number;

  fin: number;
  finAndNotResolved: number;
  finAndResolved: number;
  finAndTeammate: number;
  finAndTeammateAndClosed: number;

  chatbot: number;
  chatbotOnlyAndClosed: number;
  chatbotAndTeammate: number;
  chatbotAndTeammateAndClosed: number;

  teammate: number;
  teammateAndClosed: number;

  noReplyAndClosed: number;
}

export const ConversationFunnelConfig = {
  get filters() {
    return filters;
  },

  get oldFilters() {
    return oldFilters;
  },

  get chartSeriesFilters() {
    return [
      config('conversations_total'),

      // Fin flows

      config('fin', this.filters.finWasEverInvolved),
      config(
        'finAndNotResolved',
        and(
          this.filters.finWasEverInvolved,
          this.filters.teammateWasNeverInvolved,
          this.filters.finNotResolved,
        ),
      ),
      config(
        'finAndResolved',
        and(
          this.filters.finWasEverInvolved,
          this.filters.teammateWasNeverInvolved,
          this.filters.finResolved,
        ),
      ),
      config(
        'finAndTeammate',
        and(this.filters.finWasEverInvolved, this.filters.teammateWasEverInvolved),
      ),
      config(
        'finAndTeammateAndClosed',
        and(
          this.filters.finWasEverInvolved,
          this.filters.teammateWasEverInvolved,
          this.filters.closed,
        ),
      ),

      // Chatbot flows

      config('chatbot', and(this.filters.finWasNeverInvolved, this.filters.chatbotWasEverInvolved)),
      config('chatbotOnlyAndClosed', and(this.filters.chatbotWasOnlyInvolved, this.filters.closed)),
      config(
        'chatbotAndTeammate',
        and(
          this.filters.finWasNeverInvolved,
          this.filters.chatbotWasEverInvolved,
          this.filters.teammateWasEverInvolved,
        ),
      ),
      config(
        'chatbotAndTeammateAndClosed',
        and(
          this.filters.finWasNeverInvolved,
          this.filters.chatbotWasEverInvolved,
          this.filters.teammateWasEverInvolved,
          this.filters.closed,
        ),
      ),

      // Teammate-only flows

      config('teammate', this.filters.teammateWasOnlyInvolved),
      config('teammateAndClosed', and(this.filters.teammateWasOnlyInvolved, this.filters.closed)),

      // No reply
      config('noReplyAndClosed', and(this.filters.noReply, this.filters.closed)),
    ];
  },
  get chartSeries() {
    return this.chartSeriesFilters.map((config) => config.series);
  },

  getMetricsFromChartDataRequest(chartData: any) {
    let result = {} as Metrics;
    for (let i = 0; i < chartData.length; i++) {
      let seriesName = this.chartSeriesFilters[i].name as keyof Metrics;
      let seriesData = chartData[i].data[0];
      result[seriesName] = seriesData;
    }
    // add some calculated / aliased metrics
    result.noReplyAndNotClosed =
      result.conversations_total -
      result.fin -
      result.chatbot -
      result.teammate -
      result.noReplyAndClosed;
    return result;
  },
};
