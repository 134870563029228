/* import __COLOCATED_TEMPLATE__ from './legacy-chart-builder.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { isEmpty, isPresent } from '@ember/utils';
import {
  SHOW_TIME_COMPARISON,
  STACKED,
  SHOW_DATA_LABELS,
  TARGET,
  SHOW_RELATIVE_VALUES,
  SHOW_SUMMARY_ROW,
  SEGMENTABLE_VISUALIZATION_TYPES,
  VIZ_TYPE_TO_ICON_MAPPING,
} from 'embercom/models/reporting/custom/visualization-options';
import { modifier } from 'ember-modifier';
import { setDefaultSizesOnChart } from 'embercom/lib/reporting/custom/visualization-type-grid-sizes';

export default class LegacyChartBuilder extends Component {
  @service intercomEventService;
  @service router;
  @service intercomConfirmService;
  @service notificationsService;
  @service store;
  @service appService;
  @service permissionsService;
  @service intl;
  @service reportingMetrics;
  @service chartBuilderFilterService;
  @service reportingUnderlyingDataService;
  @service reportingChartService;
  @service customReportsService;

  @tracked showSideDrawer = false;
  @tracked showUnderlyingData = false;
  @tracked showExportModal = false;
  @tracked selection = [];
  @tracked previousSelection = [];
  @tracked activeVisualizationTab = 'chart';
  @tracked visualizationOptions = this.startingVisualizationOptions;
  @tracked drillInChartSeries = null;
  visualizationIcons = VIZ_TYPE_TO_ICON_MAPPING;

  get startingVisualizationOptions() {
    return isPresent(this.args.chartState.visualizationOptions)
      ? this.args.chartState.visualizationOptions
      : this.store.createFragment('reporting/custom/visualization-options');
  }

  get showDateOverwritten() {
    return this.args.report.showDateOverwritten(this.args.chartState.dateRange);
  }

  get app() {
    return this.appService.app;
  }

  get conversationAttributeDescriptors() {
    return this.store.peekAll('conversation-attributes/descriptor');
  }

  get renderableChart() {
    let report = {
      settings: this.args.settings,
      timezone: this.timezone,
    };
    return new RenderableChart(this.args.chartState, report, getOwner(this));
  }

  get disableTimeComparison() {
    if (this.renderableChart.isCounter) {
      return false;
    }
    return this.args.chartState.viewBy !== 'time' || isPresent(this.args.chartState.segmentBy);
  }

  get chartRange() {
    return this.args.chartState.getRangeWithTimeZone(this.timezone);
  }

  get reportTimezone() {
    if (isPresent(this.args.report?.timezone)) {
      return this.args.report.timezone;
    }
    return undefined;
  }

  get timezone() {
    return this.reportTimezone || this.app.timezone;
  }

  get sharedAnalyticsData() {
    return {
      object: 'custom_chart',
      custom_chart_id: this.args.chartState.id,
      custom_chart_name: this.args.chartState.title || 'untitled',
      custom_report_id: this.args.report.id,
      custom_report_name: this.args.report.title || 'untitled',
      currentMetricId: this.args.chartState.chartSeries.firstObject.metric.id,
    };
  }

  get editedChartAnalyticsData() {
    let chartChangedAttributes = this.args.chartState.changedAttributes();
    let dateRangeChangedAttributes = this.args.chartState.dateRange.changedAttributes();
    return {
      title_changed: 'title' in chartChangedAttributes,
      description_changed: 'description' in chartChangedAttributes,
      visualisation_changed: 'visualizationType' in chartChangedAttributes,
      grouping_changed: 'viewBy' in chartChangedAttributes,
      date_range_date_changed:
        'selection' in dateRangeChangedAttributes ||
        'start' in dateRangeChangedAttributes ||
        'end' in dateRangeChangedAttributes,
      filters_changed: 'filters' in chartChangedAttributes,
      stacked_changed: 'stacked' in chartChangedAttributes,
    };
  }

  get showStackedToggle() {
    return this.args.chartState.isStackable;
  }

  get showSegmentBySelector() {
    return SEGMENTABLE_VISUALIZATION_TYPES.includes(this.renderableChart.visualizationType);
  }

  get showDataLabelToggle() {
    return this.args.chartState.canShowDataLabels;
  }

  get filters() {
    // TODO update this to work with per-series filters
    return this.args.chartState.chartSeries.firstObject.filters;
  }

  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }

  get showDataLabelsValue() {
    return this.args.chartState.chartSeries.firstObject.showDataLabels;
  }

  get visualizationOptionValues() {
    return {
      [SHOW_TIME_COMPARISON]: this.visualizationOptions.showTimeComparison,
      [STACKED]: this.args.chartState.stacked,
      [SHOW_DATA_LABELS]: this.showDataLabelsValue,
      [TARGET]: this.visualizationOptions.target,
      [SHOW_RELATIVE_VALUES]: this.visualizationOptions.showRelativeValues,
      [SHOW_SUMMARY_ROW]: this.visualizationOptions.showSummaryRow,
    };
  }

  resetColors = modifier((_, [v]) => {
    this.reportingChartService.resetColors();
  });

  @action
  trackAnalyticsEvent(data) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      ...data,
    });
  }

  @action
  cancel() {
    if (this.args.report.isNew) {
      this.router.transitionTo(this.args.route, { queryParams: { cancelling: true } });
    } else {
      this.router.transitionTo(this.args.route, this.args.report.id, {
        queryParams: { cancelling: true },
      });
    }
  }

  @task({ drop: true }) *saveChart() {
    yield this.performSaveChart.perform();
    this.intercomEventService.trackEvent('saved-custom-report', {
      new_metrics: true,
    });
    if (this.appService.app.canSeeR2Beta) {
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.chart-added-to-report'),
      );
      this.router.transitionTo('apps.app.reports.custom-reports.report.show', this.args.report.id, {
        queryParams: { cr2AddingToChart: true },
      });
    } else {
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.chart-saved'),
      );
      this.router.transitionTo('apps.app.reports.custom-reports.report.show', this.args.report.id);
    }
  }

  get editMode() {
    return !this.args.chartState?.isNew;
  }

  @task({ drop: true }) *performSaveChart() {
    if (this.app.canSeeR2Beta) {
      this.addChartToReport();
    } else {
      yield this.saveChartOnChartBuilder.perform();
    }
  }

  addChartToReport() {
    if (this.editMode) {
      this.trackAnalyticsEvent({
        action: 'edited_chart_on_report',
        object: 'cr2_custom_chart',
      });

      if (Object.keys(this.args.chartState.changedAttributes()).includes('visualizationType')) {
        setDefaultSizesOnChart(this.args.chartState);
        this.args.chartState.updateChartPosition(null, null);
      }

      this.args.report.saveChart(this.args.chartState);
    } else {
      this.trackAnalyticsEvent({
        action: 'added_chart_to_report',
        object: 'cr2_custom_chart',
      });
      this.args.report.saveChart(this.args.chartState);
      setDefaultSizesOnChart(this.args.chartState);
    }
  }

  @task({ drop: true }) *saveChartOnChartBuilder() {
    let isFromTemplateReport = this.args.report.isIntercomOwnedReport;
    if (this.editMode) {
      this.trackAnalyticsEvent({
        action: 'edited',
        from_template: isFromTemplateReport,
        new_metrics: true,
      });
      yield this.args.report.saveChart(this.args.chartState);
    } else {
      yield this.args.report.save();
      yield this.args.report.saveChart(this.args.chartState);
      this.trackAnalyticsEvent({
        action: 'saved',
        from_template: isFromTemplateReport,
        new_metrics: true,
      });
    }
  }

  @task({ drop: true }) *deleteChart() {
    let confirmationModelContent = {
      title: this.intl.t('reporting.custom-reports.chart.more-button.confirmation-model.title'),
      body: this.intl.t('reporting.custom-reports.chart.more-button.confirmation-model.body'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t(
        'reporting.custom-reports.chart.more-button.confirmation-model.confirm-button-text',
      ),
    };
    let confirm = yield this.intercomConfirmService.confirm(confirmationModelContent);
    if (!confirm) {
      return;
    }
    this.trackAnalyticsEvent({
      action: 'deleted',
    });

    if (this.appService.app.canSeeR2Beta) {
      yield this.args.report.removeChart(this.args.chartState, { save: false });
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.more-button.chart-deleted'),
      );
      this.router.transitionTo(this.args.route, this.args.report.id, {
        queryParams: { cr2AddingToChart: true },
      });
    } else {
      yield this.args.report.removeChart(this.args.chartState, { save: true });
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.more-button.chart-deleted'),
      );
      this.router.transitionTo(this.args.route, this.args.report.id);
    }
  }

  @task({ drop: true }) *duplicateChart() {
    let confirmationGuardModalContent = {
      title: this.intl.t(
        'reporting.custom-reports.chart.more-button.confirmation-guard-model.title',
      ),
      primaryButtonType: 'primary',
      confirmButtonText: this.intl.t(
        'reporting.custom-reports.chart.more-button.confirmation-guard-model.confirm-button-text',
      ),
      cancelButtonText: this.intl.t(
        'reporting.custom-reports.chart.more-button.confirmation-guard-model.cancel-button-text',
      ),
      body: this.intl.t('reporting.custom-reports.chart.more-button.confirmation-guard-model.body'),
      distinguishCancelFromClose: true,
    };
    if (this.args.chartState.hasDirtyAttributes) {
      let confirmed = yield this.intercomConfirmService.confirm(confirmationGuardModalContent);
      if (confirmed === true) {
        yield this.performSaveChart.perform();
      } else {
        this.args.chartState.rollbackAttributes();
      }
    }
    let duplicatedChart = yield this.postDuplicateChart.perform();
    this.trackAnalyticsEvent({
      action: 'duplicated',
    });
    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.custom-reports.chart.more-button.chart-duplicated'),
    );
    this.router.transitionTo(
      'apps.app.reports.custom-reports.report.show.chart.edit',
      duplicatedChart.id,
    );
  }

  @task({ drop: true }) *postDuplicateChart() {
    let result;
    try {
      result = yield this.args.chartState.duplicateChart({
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        id: this.args.chartState.id,
        ...(isEmpty(this.args.chartState.title) && {
          default_title: this.renderableChart.description,
        }),
      });
      let duplicatedChart = yield this.store.find('reporting/custom/chart', result.id);
      yield this.args.report.saveChart(duplicatedChart);
    } catch (_e) {
      this.notificationsService.notifyError(
        this.intl.t('reporting.custom-reports.chart.more-button.chart-error'),
      );
    }
    return result;
  }

  @action
  updateViewBy(value) {
    this.trackAnalyticsEvent({
      action: 'edited_break_down_by',
      group: value,
    });
    this.args.chartState.updateViewBy(value);
  }

  @action
  updateSegmentBy(value) {
    this.trackAnalyticsEvent({
      action: 'edited_group_by',
      group: value,
    });
    this.args.chartState.updateSegmentBy(value);
  }

  @action
  updateStacked() {
    this.trackAnalyticsEvent({ action: 'edited_stacked' });
    this.args.chartState.toggleStacked();
  }

  @action
  toggleShowDataLabels() {
    this.trackAnalyticsEvent({
      action: 'edited_show_data_labels',
    });
    this.args.chartState.toggleShowDataLabels();
  }

  @action
  toggleTimeComparison() {
    this.trackAnalyticsEvent({
      action: 'edited_time_comparison',
    });
    this.visualizationOptions.showTimeComparison = !this.visualizationOptions.showTimeComparison;
    this.args.chartState.visualizationOptions = this.visualizationOptions;
  }

  @action
  toggleRelativeValues() {
    this.trackAnalyticsEvent({
      action: 'edited_show_relative_values',
    });
    this.visualizationOptions.showRelativeValues = !this.visualizationOptions.showRelativeValues;
    this.args.chartState.visualizationOptions = this.visualizationOptions;
    if (!this.args.chartState.stacked) {
      this.args.chartState.toggleStacked();
    }
  }

  @action
  toggleSummaryRow() {
    this.trackAnalyticsEvent({ action: 'edited_show_summary_row' });
    this.visualizationOptions.showSummaryRow = !this.visualizationOptions.showSummaryRow;
    this.args.chartState.visualizationOptions = this.visualizationOptions;
  }

  @action
  updateSegmentByDisplayOther(value) {
    this.trackAnalyticsEvent({
      action: 'edited_group_by_display_other',
      value,
    });
    this.args.chartState.segmentByDisplayOther = value;
  }

  @action
  updateVisualization(value) {
    this.trackAnalyticsEvent({
      action: 'edited_vizualization',
      visualization: value,
    });
    this.args.chartState.updateMetricVisualization(value);
  }

  @action
  updateActiveVisualizationTab(value) {
    this.activeVisualizationTab = value;
  }

  @action
  toggleVisualizationOption(option) {
    if (option === STACKED) {
      this.updateStacked();
    } else if (option === SHOW_DATA_LABELS) {
      this.toggleShowDataLabels();
    } else if (option === SHOW_TIME_COMPARISON) {
      this.toggleTimeComparison();
    } else if (option === SHOW_RELATIVE_VALUES) {
      this.toggleRelativeValues();
    } else if (option === SHOW_SUMMARY_ROW) {
      this.toggleSummaryRow();
    } else {
      throw new Error(`Unknown visualization option passed to toggle: ${option}`);
    }
  }

  @action
  setTarget(target) {
    this.trackAnalyticsEvent({
      action: 'edited_target',
    });
    this.visualizationOptions.target = target;
    this.args.chartState.visualizationOptions = this.visualizationOptions;
  }

  @action
  onFiltersChanged(index, attribute, values, operator) {
    let { report, chartState } = this.args;
    this.trackAnalyticsEvent({
      action: 'filtered',
      filter_name: attribute.id,
    });
    // TODO - refactor to take in the chartSeries being modified, and not just the first one
    let chartSeries = chartState.chartSeries.firstObject;
    this.chartBuilderFilterService.changeFilter(report, chartState, chartSeries, {
      index,
      attribute,
      values,
      operator,
    });
  }

  @action
  onFiltersCleared() {
    let { report, chartState } = this.args;
    // This is legacy code that works only for a single metric
    let chartSeries = chartState.chartSeries.firstObject;
    this.chartBuilderFilterService.clearFilter(report, chartState, chartSeries);
  }

  @action
  updateViewByTimeInterval(value) {
    this.trackAnalyticsEvent({
      action: 'edited_break_down_by_time_interval',
      break_down_by_time_interval: value,
    });
    this.args.chartState.viewByTimeInterval = value;
  }

  @action
  updateSegmentByTimeInterval(value) {
    this.trackAnalyticsEvent({
      action: 'edited_segment_by_time_interval',
      segment_by_time_interval: value,
    });
    this.args.chartState.segmentByTimeInterval = value;
  }

  @action
  updateViewByDisplayLimit(value) {
    this.trackAnalyticsEvent({
      action: 'edited_break_down_by_display_limit',
      break_down_by_display_limit: value,
    });
    this.args.chartState.viewByDisplayLimit = value;
  }

  @action
  updateSegmentByDisplayLimit(value) {
    this.trackAnalyticsEvent({
      action: 'edited_group_by_display_limit',
      group_by_display_limit: value,
    });
    this.args.chartState.segmentByDisplayLimit = value;
  }

  @action
  openSideDrawer() {
    this.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversations_list',
      currentFilters: this.args.chartState.chartSeries.firstObject.filters,
    });
    this.showSideDrawer = true;
  }

  @action
  applyLogicalFilterOperator(operator) {
    this.args.chartState.chartSeries.firstObject.applyLogicalFilterOperator(operator);
  }

  @action
  openUnderlyingDataModal() {
    if (this.permissionsService.currentAdminCan('can_reporting__drillin__access')) {
      this.trackAnalyticsEvent({
        action: 'opened',
        object: 'underlying_data_modal',
        place: 'chart_builder',
        chart_metric_id: this.args.chartState.chartSeries.firstObject.metric.id,
      });
      this.setDefaultColumns();
      // TODO make this work with multiple series
      this.drillInChartSeries = this.args.chartState.chartSeries.firstObject;
      this.showUnderlyingData = true;
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__drillin__access',
      );
    }
  }

  @action
  openExportModal() {
    this.showExportModal = true;
    this.trackAnalyticsEvent({
      action: 'opened',
      object: 'export_options_modal',
      place: 'chart_builder',
    });
  }

  @action
  onExportModalCloseEvent() {
    this.showExportModal = false;
    this.trackAnalyticsEvent({
      action: 'closed',
      object: 'export_options_modal',
      place: 'chart_builder',
    });
  }

  @action
  onUnderlyingDataCloseEvent() {
    this.showUnderlyingData = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: 'underlying_data_modal',
      place: 'chart_builder',
    });
  }

  get knownValuesEndpointSources() {
    let metrics = this.args.chartState.chartSeries.map((series) => series.metric);
    return metrics.map((metric) => metric.firstSource);
  }

  get metricType() {
    if (isEmpty(this.args.chartState.chartSeries)) {
      return;
    }
    return this.args.chartState.chartSeries.firstObject.metric?.type;
  }

  get columns() {
    return this.selection;
  }

  get metric() {
    return this.args.chartState.chartSeries.firstObject.metric;
  }

  get defaultColumns() {
    return this.reportingUnderlyingDataService.getDefaultColumns(
      this.args.chartState.chartSeries.firstObject,
      this.renderableChart,
    );
  }

  get suggestedProperties() {
    // Breakdowns don't yet use attribute ids, so we pass properties for now
    let attributeIds = this.reportingUnderlyingDataService.getSuggestedAttributeIds(this.metric);
    return attributeIds.map(
      (attributeId) => this.reportingMetrics.getAttributeById(attributeId).field,
    );
  }

  @action
  setDefaultColumns() {
    let defaultSelection = [];
    let localStorageSelection;
    if (this.args.chartState.id) {
      localStorageSelection = localStorage.getItem(
        `reporting.columnAttributesSelected_${this.args.chartState.id}`,
      );
    }
    if (localStorageSelection) {
      defaultSelection = localStorageSelection.split(',');
    } else {
      defaultSelection = this.defaultColumns;
    }
    this.selection = defaultSelection.uniq().compact();
    this.previousSelection = this.selection;
  }

  @action
  resetColumns() {
    this.selection = this.defaultColumns;
    if (this.args.chartState.id) {
      localStorage.setItem(
        `reporting.columnAttributesSelected_${this.args.chartState.id}`,
        this.selection.join(','),
      );
    }
    this.previousSelection = this.selection;
  }

  @action
  setColumns(chartSeries, columns) {
    this.selection = this.reportingUnderlyingDataService.getOrderedAttributeIds(
      chartSeries.metric,
      columns,
      this.previousSelection,
    );

    if (this.args.chartState.id) {
      localStorage.setItem(
        `reporting.columnAttributesSelected_${this.args.chartState.id}`,
        this.selection.join(','),
      );
    }

    this.previousSelection = this.selection;
  }

  get saveTextWithCanSeeR2Beta() {
    if (this.args.report?.availableCharts.find((chart) => chart.id === this.args.chartState.id)) {
      return this.intl.t('reporting.custom-reports.chart.update-chart');
    } else {
      return this.intl.t('reporting.custom-reports.chart.add-chart');
    }
  }

  get saveText() {
    if (this.app.canSeeR2Beta) {
      return this.saveTextWithCanSeeR2Beta;
    } else {
      return this.intl.t('reporting.custom-reports.chart.save-close');
    }
  }
}
