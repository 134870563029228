/* import __COLOCATED_TEMPLATE__ from './tickets-report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { filterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import PALETTE from '@intercom/pulse/lib/palette';

const DEFAULT_TICKET_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: null,
  metric_property: null,
  metric_function: null,
  date_range: {
    property: 'last_resolved_at',
    selection: 'past_12_weeks',
    start: null,
    end: null,
  },
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10000,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

export default class TicketsReport extends Component {
  @service store;
  @service intl;
  @service appService;
  @service reportingMetrics;

  constructor() {
    super(...arguments);

    // The charts are defined in this component, so we need to let the parent component know whether any of the charts are using deprecated metrics
    this.args.setHasDeprecatedMetrics(this.reportHasDeprecatedMetrics);
  }

  get reportDefinition() {
    return {
      id: `tickets_report`,
      title: null,
      description: null,
      charts: this.charts,
      date_range: null,
      filters: {},
    };
  }

  get filterableProperties() {
    return filterableProperties(this.appService.app).filter((property) => property !== 'topic');
  }
  get stickyFilterTypes() {
    return [{ id: 'time' }, { id: 'conversation.ticket_type_id' }];
  }

  // Check if at least one of the charts uses a deprecated metric
  get reportHasDeprecatedMetrics() {
    return this.charts.any((chart) => {
      let metricIds = chart.chart_series.map((chart_series) => chart_series.metric_id);
      return metricIds.any((metricId) => this.reportingMetrics.getMetricById(metricId).deprecated);
    });
  }

  get charts() {
    return [
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'median_time_to_resolve',
        title: this.intl.t('reporting.tickets.median-time-to-resolve-title'),
        tooltip: this.intl.t('reporting.tickets.median-time-to-resolve-tooltip'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'v1.ticket_time_to_resolve',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'median_time_in_submitted',
        title: this.intl.t('reporting.tickets.median-time-in-submitted-title'),
        tooltip: this.intl.t('reporting.tickets.median-time-in-submitted-tooltip'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'v1.ticket_time_in_submitted',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'median_time_in_progress',
        title: this.intl.t('reporting.tickets.median-time-in-progress-title'),
        tooltip: this.intl.t('reporting.tickets.median-time-in-progress-tooltip'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'v1.ticket_time_in_progress',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'median_time_in_waiting_on_customer',
        title: this.intl.t('reporting.tickets.median-time-in-waiting-on-customer-title'),
        tooltip: this.intl.t('reporting.tickets.median-time-in-waiting-on-customer-tooltip'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'v1.ticket_time_in_waiting_on_customer',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'median_time_to_resolve_by_week',
        title: this.intl.t('reporting.tickets.median-time-to-resolve-by-time-title'),
        tooltip: this.intl.t('reporting.tickets.median-time-to-resolve-by-time-tooltip'),
        visualization_type: VISUALIZATION_TYPES.BAR,
        view_by: 'time',
        width_type: WIDTH_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'v1.ticket_time_to_resolve',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'median_time_to_resolve_by_assigned_team',
        title: this.intl.t('reporting.tickets.median-time-to-resolve-by-assigned-team-title'),
        tooltip: this.intl.t('reporting.tickets.median-time-to-resolve-by-assigned-team-tooltip'),
        visualization_type: VISUALIZATION_TYPES.TABLE,
        view_by: 'team_assignee_id',
        width_type: WIDTH_TYPES.THREE_COLUMN,
        chart_series: [
          {
            metric_id: 'v1.ticket_time_to_resolve',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'median_time_to_resolve_by_assigned_teammate',
        title: this.intl.t('reporting.tickets.median-time-to-resolve-by-assigned-teammate-title'),
        tooltip: this.intl.t(
          'reporting.tickets.median-time-to-resolve-by-assigned-teammate-tooltip',
        ),
        visualization_type: VISUALIZATION_TYPES.TABLE,
        view_by: 'admin_assignee_id',
        width_type: WIDTH_TYPES.THREE_COLUMN,
        chart_series: [
          {
            metric_id: 'v1.ticket_time_to_resolve',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'new_tickets_count',
        title: this.intl.t('reporting.tickets.new-tickets-count-title'),
        tooltip: this.intl.t('reporting.tickets.new-tickets-count-tooltip'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'v1.new_tickets',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'resolved_tickets_count',
        title: this.intl.t('reporting.tickets.resolved-tickets-count-title'),
        tooltip: this.intl.t('reporting.tickets.resolved-tickets-count-tooltip'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'v1.resolved_tickets',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'new_vs_resolved_column_chart',
        title: this.intl.t('reporting.tickets.new-vs-resolved-tickets-bar-title'),
        tooltip: this.intl.t('reporting.tickets.new-vs-resolved-tickets-bar-tooltip'),
        visualization_type: VISUALIZATION_TYPES.BAR,
        view_by: 'time',
        view_by_time_interval: 'week',
        width_type: WIDTH_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'v1.new_tickets',
            color: PALETTE['vis-mint-50'],
          },
          {
            metric_id: 'v1.resolved_tickets',
            color: PALETTE['gray-light'],
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'tickets_volume_by_assigned_team',
        title: this.intl.t('reporting.tickets.ticket-volume-by-assigned-team-title'),
        tooltip: this.intl.t('reporting.tickets.ticket-volume-by-assigned-team-tooltip'),
        visualization_type: VISUALIZATION_TYPES.TABLE,
        view_by: 'team_assignee_id',
        width_type: WIDTH_TYPES.THREE_COLUMN,
        chart_series: [
          {
            metric_id: 'v1.new_tickets',
          },
          {
            metric_id: 'v1.resolved_tickets',
          },
        ],
      },
      {
        ...DEFAULT_TICKET_CHART_DEFINITION,
        id: 'tickets_volume_by_assigned_teammate',
        title: this.intl.t('reporting.tickets.ticket-volume-by-assigned-teammate-title'),
        tooltip: this.intl.t('reporting.tickets.ticket-volume-by-assigned-teammate-tooltip'),
        visualization_type: VISUALIZATION_TYPES.TABLE,
        view_by: 'admin_assignee_id',
        width_type: WIDTH_TYPES.THREE_COLUMN,
        chart_series: [
          {
            metric_id: 'v1.new_tickets',
          },
          {
            metric_id: 'v1.resolved_tickets',
          },
        ],
      },
    ];
  }
}
