/* import __COLOCATED_TEMPLATE__ from './ticket-type-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { AVAILABLE_EMOJI } from 'embercom/lib/available-emoji';
import shuffle from '@intercom/pulse/lib/shuffle';
import { action } from '@ember/object';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { TicketCategory } from '../../../objects/inbox/ticket';
import type { TicketType } from '../../../objects/inbox/ticket';
import type Store from '@ember-data/store';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type GuideLibraryService from 'embercom/services/guide-library-service';

const DEFAULT_INTERNAL = false;
const DEFAULT_AI_ASSIST_DISABLED = false;

interface Args {
  app: $TSFixMe;
  category: TicketCategory;
  onSideDrawerClose: () => void;
  onTicketCreated: (ticketType: TicketType) => void;
  newSettings?: boolean;
}

export default class TicketTypeSideDrawer extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare appService: any;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked name = '';
  @tracked description = '';
  @tracked emoji = this._randomEmoji();
  @tracked internal = this.defaultInternal;

  @tracked disableAiAssist = DEFAULT_AI_ASSIST_DISABLED;

  @tracked category = this.args.category || this.defaultCategory;

  get defaultCategory() {
    return TicketCategory.Request;
  }

  get defaultInternal() {
    if (this.category === TicketCategory.Tracker) {
      return true;
    }

    if (this.category === TicketCategory.Task) {
      return true;
    }

    return DEFAULT_INTERNAL;
  }

  get ticketCategories() {
    return [
      {
        items: [
          {
            text: this.intl.t('settings.ticket-data.ticket-type-configuration.category.request'),
            icon: 'ticket',
            value: TicketCategory.Request,
            description: this.intl.t('settings.ticket-data.ticket-type-configuration.info.request'),
          },
          {
            text: this.intl.t('settings.ticket-data.ticket-type-configuration.category.task'),
            icon: 'back-office',
            value: TicketCategory.Task,
            description: this.intl.t('settings.ticket-data.ticket-type-configuration.info.task'),
          },
          {
            text: this.intl.t('settings.ticket-data.ticket-type-configuration.category.tracker'),
            icon: 'tracker',
            value: TicketCategory.Tracker,
            description: this.intl.t('settings.ticket-data.ticket-type-configuration.info.tracker'),
          },
        ],
      },
    ];
  }

  get isValid() {
    return this.name.trim() !== '';
  }

  @action selectEmoji(emojiIdentifier: string) {
    let unicodeEmoji = intermoji.unicodeFromIdentifier(emojiIdentifier);
    let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
    this.emoji = twemojiCompatibleEmoji;
  }

  @action onCategorySelectionChange(value: TicketCategory) {
    this.category = value;
    if (this.category === TicketCategory.Request) {
      this.internal = false;
      return;
    }

    if (this.category === TicketCategory.Tracker) {
      this.internal = true;
      return;
    }

    // If we are creating a new ticket type and the category is task, we default to internal
    if (this.category === TicketCategory.Task) {
      this.internal = true;
      return;
    } else {
      this.internal = false;
      return;
    }
  }

  @action
  saveTicketType() {
    taskFor(this.createTicketType).perform();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'create-ticket-type',
      object: `create-ticket-type-cta`,
      section: 'settings.helpdesk.tickets.side-drawer',
      place: 'settings.helpdesk.tickets',
    });
  }

  get visibilitySettingsDisabled() {
    return this.category === TicketCategory.Request || this.category === TicketCategory.Tracker;
  }

  get visibilitySettingsDisabledReason() {
    let baseKey = 'settings.ticket-data.ticket-type-modal.visibility-settings-disabled-reason';
    return `${baseKey}.${this.category}`;
  }

  get hasVisibilitySettings() {
    return this.category === TicketCategory.Task;
  }

  @task({ drop: true })
  *createTicketType() {
    let ticketType = this.store.createRecord('inbox/ticket-type', {
      name: this.name,
      description: this.description,
      emoji: this.emoji,
      internal: this.internal,
      category: this.category,
      disableAiAssist: this.disableAiAssist,
    });

    try {
      yield ticketType.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-data.ticket-type-modal.successfully-created'),
      );

      if (this.args.onTicketCreated) {
        this.args.onTicketCreated(ticketType);
      }

      let routeName = this.args.newSettings
        ? 'apps.app.settings.helpdesk.tickets.ticket-types.detail'
        : 'apps.app.settings.ticket-data.detail';
      yield this.router.transitionTo(routeName, ticketType.id).finally(async () => {
        if (this.guideLibraryService.canUseGuideLibraryService) {
          await this.guideLibraryService.markStepCompleted('display_only_setup_your_tickets');
        }
      });
    } catch (error) {
      this.store.unloadRecord(ticketType);
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.ticket-type-modal.ticket-type-name-duplicate'),
        );
      } else if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.ticket-type-modal.unknown-error'),
        );
      }
    }
  }

  _randomEmoji() {
    return shuffle(AVAILABLE_EMOJI)[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::TicketTypeSideDrawer': typeof TicketTypeSideDrawer;
    'settings/ticket-types/ticket-type-side-drawer': typeof TicketTypeSideDrawer;
  }
}
