/* import __COLOCATED_TEMPLATE__ from './answer-bot-common-answers.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { allSettled } from 'rsvp';
import { FIRST_USER_CONVERSATION_PART_CREATED_AT } from 'embercom/lib/reporting/flexible/constants';

const COMMON_ANSWERS_COUNT = 5;

const commonAnswerParams = function (app) {
  return {
    valueUnit: 'answers',
    debugName: 'answer_bot_common_answers',
    signalConfig: {
      name: 'default_query',
      document_type: 'resolution_bot_content_sent',
      range_field: FIRST_USER_CONVERSATION_PART_CREATED_AT,
      aggregation_type: 'cardinality',
      aggregation_field: 'conversation_id',
      keys_are_dates: false,
    },
    aggregations: [{ grouping: 'content_id', interval: 1 }],
    filters: { content_type: 'answer' },
  };
};

export default Component.extend({
  reportingService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  store: service(),
  intl: service(),
  commonAnswersContext: readOnly('fetchSignalAndModel.lastSuccessful.value'),

  didReceiveAttrs() {
    this._super(...arguments);
    this.fetchSignalAndModel.perform();
  },

  fetchAnswers: task(function* (ids) {
    let models = yield allSettled(
      ids.map((id) => {
        return this.store.findRecord('custom-answers/custom-answer', id, {
          backgroundReload: false,
        });
      }),
    );

    return models.map((result) => (result.state === 'fulfilled' ? result.value : null));
  }).restartable(),

  fetchSignal: task(function* () {
    let queryConfig = Object.assign({ range: this.range }, commonAnswerParams(this.app));
    return yield this.reportingService.fetchStackedSignal(queryConfig);
  }).restartable(),

  fetchSignalAndModel: task(function* () {
    let commonAnswers = yield this.fetchSignal.perform();

    let modelKeys = commonAnswers.context.slice(0, COMMON_ANSWERS_COUNT).map((c) => c.key);
    let models = yield this.fetchAnswers.perform(modelKeys);
    return commonAnswers.context.slice(0, COMMON_ANSWERS_COUNT).map((bucket, index) => ({
      ...bucket,
      id: models[index] ? models[index].id : null,
      label: models[index]
        ? models[index].title
        : this.intl.t('components.reporting.reports.answer-bot-common-answer.answer-deleted'),
      linkRoute: models[index] ? 'apps.app.automation.resolution-bot.answers.answer.show' : null,
    }));
  }).restartable(),
});
