/* import __COLOCATED_TEMPLATE__ from './conversation-access-editor.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { generateSafeEmoji } from 'embercom/lib/emoji';
import TruncateString from 'embercom/lib/truncate-string';
import { trackedReset } from 'tracked-toolbox';
import { CONVERSATION_SECTION_ID } from 'embercom/lib/settings/seats/constants';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import type Paywall from 'embercom/components/paywall';
import type Team from 'embercom/models/team';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  roleOrPermissionObject: any;
  onChange: () => void;
  disableConversationAccessControls: boolean;
  hasLimitedInboxAccess: boolean;
  permissionsEditorWithSeats: boolean;
  noSeatSelected: boolean;
  onPricing5: boolean;
  openSections: { [key: string]: boolean };
  onOpenSectionChange: (key: string) => void;
}

interface AccessOptionItem {
  text: string;
  value: string;
  isDisabled: boolean;
  isRcaFlexOption: boolean;
  componentAttrs: { selectedValue: string; buttonDisabled: boolean };
  tooltipContent: string;
  selectLabel?: string;
}

export default class ConversationAccessEditor extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked selectedAssigneeBlockedList: string[] = [];
  @tracked paywall?: Paywall;
  @tracked accordionState: string | null = CONVERSATION_SECTION_ID;
  @trackedReset('args.openSections') conversationAccessEditorId = this.sectionState;

  initialAssigneeBlockedList = this.assigneeBlockedList || [];
  lowTierAvailableAccessOptions = ['all', 'only_them'];
  lowTierUnavailableAccessOptions = ['only_them_or_their_teams', 'all_except'];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.selectedAssigneeBlockedList = this.initialAssigneeBlockedList;
  }

  get app() {
    return this.appService.app;
  }

  get accessType() {
    if (this.paywall?.isActive && this.args.roleOrPermissionObject.accessType) {
      return 'all';
    } else if (this.args.hasLimitedInboxAccess) {
      return 'only_mentioned_or_linked';
    } else if (this.overrideWithRole) {
      return this.preselectedRole.accessType;
    } else {
      return this.args.roleOrPermissionObject.accessType;
    }
  }

  get assigneeBlockedList(): string[] {
    if (this.overrideWithRole) {
      return this.preselectedRole.assigneeBlockedList || [];
    } else {
      return this.args.roleOrPermissionObject.assigneeBlockedList || [];
    }
  }

  get preselectedRole() {
    return this.args.roleOrPermissionObject.role;
  }

  get overrideWithRole() {
    return !!(this.preselectedRole && this.args.disableConversationAccessControls);
  }

  get noTeamsForApp() {
    return this.app.assignableTeams.length === 0;
  }

  get accessOptionItems(): AccessOptionItem[] {
    let disableableComponentAttrs = {
      selectedValue: this.accessType,
      buttonDisabled: this.noTeamsForApp,
    };
    let componentAttrs = {
      selectedValue: this.accessType,
      buttonDisabled: false,
    };
    let noTeamsTooltip = `<a href="${this.router.urlFor('apps.app.settings.teams')}">${this.intl.t(
      'components.settings.teammates.conversation-access-editor.no-teams',
    )}</a>`;
    let noCoreSeatTooltip = this.args.onPricing5
      ? this.intl.t(
          'components.settings.teammates.conversation-access-editor.no-core-pricing5-seat',
        )
      : this.intl.t('components.settings.teammates.conversation-access-editor.no-core-seat');
    let noLimitedSeatTooltip = this.args.onPricing5
      ? this.intl.t('components.settings.teammates.conversation-access-editor.no-lite-seat')
      : this.intl.t(
          'components.settings.teammates.conversation-access-editor.no-collaborator-seat',
        );

    let optionItems = [
      {
        text: this.intl.t(
          'components.settings.teammates.conversation-access-editor.all-conversations',
        ),
        value: 'all',
        isDisabled: this.disableAllOption,
        isRcaFlexOption: false,
        componentAttrs,
        tooltipContent: noCoreSeatTooltip,
      },
      {
        text: this.intl.t(
          'components.settings.teammates.conversation-access-editor.self-conversations',
        ),
        value: 'only_them',
        isDisabled: this.disableOnlyThemOption,
        isRcaFlexOption: false,
        componentAttrs,
        tooltipContent: noCoreSeatTooltip,
      },
      {
        text: this.intl.t(
          'components.settings.teammates.conversation-access-editor.teams-conversations',
        ),
        value: 'only_them_or_their_teams',
        isDisabled: this.disableOnlyThemOrTheirTeamsOption,
        isRcaFlexOption: true,
        componentAttrs: disableableComponentAttrs,
        tooltipContent: this.args.hasLimitedInboxAccess ? noCoreSeatTooltip : noTeamsTooltip,
      },
      {
        text: this.intl.t(
          'components.settings.teammates.conversation-access-editor.all-except-conversations',
        ),
        value: 'all_except',
        isDisabled: this.disableAllExceptOption,
        isRcaFlexOption: true,
        componentAttrs: disableableComponentAttrs,
        tooltipContent: this.args.hasLimitedInboxAccess ? noCoreSeatTooltip : noTeamsTooltip,
      },
    ];

    if (this.args.permissionsEditorWithSeats) {
      optionItems.push({
        text: this.intl.t(
          'components.settings.teammates.conversation-access-editor.only-mentioned-or-linked',
        ),
        value: 'only_mentioned_or_linked',
        isDisabled: this.disableMentionedOrLinkedOption,
        isRcaFlexOption: false,
        componentAttrs: disableableComponentAttrs,
        tooltipContent: noLimitedSeatTooltip,
      });
    }

    return optionItems;
  }

  get disabledTooltipContent() {
    if (this.args.noSeatSelected) {
      if (this.app.onPricing5) {
        return this.intl.t(
          'settings.teammates.permissions.permissions-editor-with-seats-selector.assign-one-pricing5-seat',
          { htmlSafe: true },
        );
      } else {
        return this.intl.t(
          'settings.teammates.permissions.permissions-editor-with-seats-selector.assign-one-seat',
          { htmlSafe: true },
        );
      }
    }

    return this.intl.t(
      'components.settings.teammates.conversation-access-editor.change-conversation-access',
      { htmlSafe: true },
    );
  }

  get disableAllOption() {
    return this.args.hasLimitedInboxAccess;
  }

  get disableOnlyThemOption() {
    return this.args.hasLimitedInboxAccess;
  }
  get disableOnlyThemOrTheirTeamsOption() {
    return this.noTeamsForApp || this.args.hasLimitedInboxAccess;
  }

  get disableAllExceptOption() {
    return this.noTeamsForApp || this.args.hasLimitedInboxAccess;
  }

  get disableMentionedOrLinkedOption() {
    return !this.args.hasLimitedInboxAccess;
  }

  get availableAccessOptions() {
    return this.accessOptionItems;
  }

  get currentlySelectedItemLabel() {
    return this.accessOptionItems.findBy('value', this.accessType)?.text;
  }

  get sortedTeams(): Team[] {
    return this.app.assignableTeams.sortBy('name');
  }

  get teamMultiselectLabel() {
    if (this.excludedTeams.length > 0) {
      return this.stringifiedTeamNames(this.excludedTeams);
    } else {
      return this.intl.t('components.settings.teammates.conversation-access-editor.select-teams');
    }
  }

  get teamnamesInAssigneeBlockList() {
    let ids = this.overrideWithRole ? this.assigneeBlockedList : this.selectedAssigneeBlockedList;
    let teams = this.filterTeams(ids);
    return teams.map((team: any) => TruncateString(team.name, 15, true)).join(', ');
  }

  filterTeams(ids: string[]) {
    return this.sortedTeams.filter(({ id }: { id: string }) => ids.includes(id));
  }

  stringifiedTeamNames(teamsToStringify: any[]) {
    return teamsToStringify.mapBy('name').join(', ');
  }

  get excludedTeams() {
    return this.filterTeams(this.selectedAssigneeBlockedList);
  }

  get teamSelectItems() {
    return this.sortedTeams.map(
      ({ name, id, avatar_emoji }: { name: string; id: string; avatar_emoji: string }) => ({
        text: name,
        value: id,
        isSelected: this.selectedAssigneeBlockedList.includes(id),
        component: 'settings/teammates/conversation-access-team-item',
        componentAttrs: { teamAvatar: generateSafeEmoji(avatar_emoji) },
      }),
    );
  }

  @action setExcludedTeams(teamsToExclude: string[]) {
    this.selectedAssigneeBlockedList = teamsToExclude;
    this.args.roleOrPermissionObject.assigneeBlockedList = this.selectedAssigneeBlockedList;
    this.setOption('all_except');
  }

  @action resetExcludedTeamSelection() {
    this.selectedAssigneeBlockedList = this.initialAssigneeBlockedList;
  }

  @action setOption(value: string) {
    this.args.roleOrPermissionObject.accessType = value;
    if (value !== 'all_except') {
      this.resetExcludedTeamSelection();
    }
  }

  @action closeDropdown(dropdown: any) {
    dropdown.hideDropdownOverlay();
  }

  get icon(): InterfaceIconName {
    return 'messages-space';
  }

  get sectionKey() {
    return CONVERSATION_SECTION_ID;
  }

  get sectionState() {
    return this.args.openSections
      ? this.args.openSections[CONVERSATION_SECTION_ID] && CONVERSATION_SECTION_ID
      : this.accordionState;
  }

  get getStampText() {
    switch (this.accessType) {
      case 'all':
        return this.intl.t(
          'components.settings.teammates.conversation-access-editor.all-conversations',
        );
      case 'only_mentioned_or_linked':
        return this.intl.t(
          'components.settings.teammates.conversation-access-editor.stamp.only-mentioned-or-linked',
        );
      case 'all-except-conversations':
        return this.intl.t(
          'components.settings.teammates.conversation-access-editor.stamp.all-except-conversations',
        );
      case 'only_them':
        return this.intl.t(
          'components.settings.teammates.conversation-access-editor.stamp.only-them',
        );
      case 'only_them_or_their_teams':
        return this.intl.t(
          'components.settings.teammates.conversation-access-editor.stamp.only-them-or-their-teams',
        );
      case 'all_except':
        return this.intl.t(
          'components.settings.teammates.conversation-access-editor.stamp.all-except',
        );
      default:
        return this.intl.t('components.settings.teammates.conversation-access-editor.stamp.custom');
    }
  }

  get getStampColor(): 'sky' {
    return 'sky';
  }

  @action
  onOpenSectionChange() {
    this.accordionState = this.accordionState ? null : CONVERSATION_SECTION_ID;
    if (this.args.onOpenSectionChange) {
      this.args.onOpenSectionChange(CONVERSATION_SECTION_ID);
    } else {
      this.conversationAccessEditorId = this.sectionState;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::ConversationAccessEditor': typeof ConversationAccessEditor;
    'settings/teammates/conversation-access-editor': typeof ConversationAccessEditor;
  }
}
