/* import __COLOCATED_TEMPLATE__ from './sources.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { inject as service } from '@ember/service';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    sources: Array<ContentImportSource>;
  };
}
export default class Sources extends Component<Signature> {
  @service declare appService: any;
  @service declare router: RouterService;

  get app() {
    return this.appService.app;
  }

  @action async redirectToExternalSources() {
    if (this.app.canUseKnowledgeHub) {
      this.router.transitionTo('apps.app.knowledge-hub.overview');
    } else {
      this.router.transitionTo('apps.app.fin-ai-agent.setup', {
        queryParams: { openSectionId: 'external-content' },
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Audience::Contents::Sources': typeof Sources;
  }
}
