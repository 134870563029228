/* import __COLOCATED_TEMPLATE__ from './custom-dropdown-option-item.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';

interface Item {
  text: string;
  description: string;
  isSelected: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}
interface Args {
  item: Item;
}

const ReportingCustomReportCustomDropdownOptionItem = templateOnlyComponent<Signature>();
export default ReportingCustomReportCustomDropdownOptionItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::CustomDropdownOptionItem': typeof ReportingCustomReportCustomDropdownOptionItem;
    'reporting/custom/report/custom-dropdown-option-item': typeof ReportingCustomReportCustomDropdownOptionItem;
  }
}
