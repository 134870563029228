/* import __COLOCATED_TEMPLATE__ from './chart-report-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import { REPORT_TEMPLATE_TO_ICON_MAPPING } from 'embercom/services/reporting-templates';

interface Args {
  selected: any;
  analyticsObject: string;
  setSelected: (templateId: string) => void;
}

export default class ChartReportFilter extends Component<Args> {
  @service intercomEventService: any;
  @service intl: any;
  @service appService: any;
  @service declare reportingTemplates: ReportingTemplates;

  get label() {
    return this.selectionItems.find((obj) => obj.value === this.args.selected)?.text;
  }

  get selectionItems() {
    let reportTemplates = this.reportingTemplates.reportTemplates;
    return [
      {
        text: this.intl.t('reporting.custom-reports.chart.sidebar-filters.reports.any'),
        value: 'any',
      },
      ...reportTemplates.map((value) => {
        return {
          text: value.title,
          value: value.templateId,
          icon: REPORT_TEMPLATE_TO_ICON_MAPPING[value.templateId],
        };
      }),
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::ChartReportFilter': typeof ChartReportFilter;
  }
}
