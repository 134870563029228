/* import __COLOCATED_TEMPLATE__ from './buttons.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Buttons extends Component {
  @service appService;
  @service router;
  @service notificationsService;
  @service intercomEventService;

  get app() {
    return this.appService.app;
  }

  get indexRoute() {
    return this.args.newSettings
      ? 'apps.app.settings.proactive-support.subscriptions.index'
      : 'apps.app.outbound.settings.subscription-types.index';
  }

  @action
  async saveAndClose() {
    try {
      let isNewSubscription = !this.args.subscriptionType.id;

      await this.args.subscriptionType.save();

      this.intercomEventService.trackAnalyticsEvent({
        action: isNewSubscription ? 'created' : 'updated',
        object: 'subscription_type',
        subscription_type_id: this.args.subscriptionType.id,
      });

      this.showSuccessConfirmation(isNewSubscription);

      return this.router.transitionTo(this.indexRoute);
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
    }
  }

  showSuccessConfirmation(isNewSubscription) {
    let defaultSubscriptionName = this.args.subscriptionType.customTranslations.filter(
      (translation) => {
        return translation.locale === this.args.translationSettings.defaultTranslationLocaleId;
      },
    )[0].name;

    let message;
    if (isNewSubscription) {
      message = `Created Subscription ${defaultSubscriptionName}`;
    } else {
      message = `Updated Subscription ${defaultSubscriptionName}`;
    }
    this.notificationsService.notifyConfirmation(message);
  }

  @action
  cancel() {
    this.args.subscriptionType.rollbackAttributes();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'create_subscription_type',
      context: 'cancel_button',
    });
    return this.router.transitionTo(this.indexRoute);
  }
}
