/* import __COLOCATED_TEMPLATE__ from './chart-template-side-panel-card.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
// @ts-ignore
import { copy } from 'ember-copy';
import { isPresent } from '@ember/utils';
import Range from 'embercom/models/reporting/range';
import type { ChartTemplate } from 'embercom/services/reporting-templates';
import type IntlService from 'ember-intl/services/intl';
import { seriesNameForMetric } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { setDefaultSizesOnChart } from 'embercom/lib/reporting/custom/visualization-type-grid-sizes';
import { type Aggregation } from 'embercom/objects/reporting/unified/metrics/types';
import type ReportingMetrics from 'embercom/services/reporting-metrics';

interface Args {
  chartTemplate: ChartTemplate;
  idx: number;
  report: Report;
}

export default class ChartTemplateSidePanelCard extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare reportingMetrics: ReportingMetrics;
  @tracked addedToReport = false;
  @tracked selectedAggregation: Aggregation = 'median';

  @action
  addChart() {
    this.args.report.addChartFromTemplate(this.chartDefinition);
    this.addedToReport = true;
  }

  get chartDefinition() {
    let chartDefinition = copy(this.args.chartTemplate, true);
    chartDefinition.dateRange = isPresent(this.args.report.dateRange)
      ? copy(this.args.report.dateRange)
      : Range.createFromPreset('past_4_weeks', this.args.report.reportTimezone);
    if (chartDefinition.supportsCustomAggregations) {
      if (chartDefinition.chartSeries.length === 1) {
        chartDefinition.chartSeries.firstObject.aggregation = this.selectedAggregation;
        let templateTitle = chartDefinition.title;
        chartDefinition.title = seriesNameForMetric(templateTitle, this.selectedAggregation, true);
      } else {
        chartDefinition.chartSeries.forEach((series: any) => {
          if (this.reportingMetrics.metricSupportsCustomAggregations(series.metricId)) {
            series.aggregation = this.selectedAggregation;
          }
        });
      }
    }
    setDefaultSizesOnChart(chartDefinition);

    return chartDefinition;
  }

  get aggregations() {
    return [
      { text: this.intl.t('reporting.aggregations.median'), value: 'median' },
      { text: this.intl.t('reporting.aggregations.mean'), value: 'mean' },
    ];
  }

  @action
  updateAggregation(aggregation: Aggregation) {
    this.selectedAggregation = aggregation;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ChartTemplateSidePanelCard': typeof ChartTemplateSidePanelCard;
    'reporting/custom/report/chart-template-side-panel-card': typeof ChartTemplateSidePanelCard;
  }
}
