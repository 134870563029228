/* import __COLOCATED_TEMPLATE__ from './multimetric-picker.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import type Chart from 'embercom/models/reporting/custom/chart';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type MutableArray from '@ember/array/mutable';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { action } from '@ember/object';
import type Range from 'embercom/models/reporting/range';
import { ADD_METRICS } from 'embercom/models/reporting/custom/visualization-options';
import { isPresent } from '@ember/utils';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import { tracked } from '@glimmer/tracking';
import { type LogicalFilterOperator } from 'embercom/components/reporting/custom/filters';

interface Arguments {
  chart: Chart;
  resetColumns: (chartSeries: ChartSeries) => void;
  sharedAnalyticsData: () => void;
  renderableChart: RenderableChart;
  onFiltersChanged: (
    index: number,
    attribute: any,
    filterValues: any,
    operator: any,
    chartSeries: ChartSeries | null,
  ) => void;
  onFiltersCleared: (chartSeries: ChartSeries | null) => void;
  conversationAttributeDescriptors: MutableArray<ConversationAttributeDescriptor>;
  range: Range | null;
  timezone: string | null;
}
interface Signature {
  Args: Arguments;
}

const MAXIMUM_NUMBER_OF_METRICS = 20;

export default class MultimetricPicker extends Component<Signature> {
  @service declare appService: any;

  @tracked openSectionId = 'metrics';
  @tracked chartAdded = false;

  get chart() {
    return this.args.chart;
  }
  get renderForMultimetric() {
    return this.appService.app.canSeeR2MultimetricsNewUi && this.chart.supportsFeature(ADD_METRICS);
  }

  get disableAddMetric() {
    return (
      this.chart.chartSeries.length === MAXIMUM_NUMBER_OF_METRICS || isPresent(this.chart.segmentBy)
    );
  }

  get canReorderMultiMetrics() {
    return this.chart.chartSeries.length > 1;
  }

  filterableProperties(chartSeries: ChartSeries) {
    return chartSeries.metric.supportedFiltersExcludeTimeAttribute;
  }

  chartSeriesMetricSource(chartSeries: ChartSeries) {
    return [chartSeries.metric.firstSource];
  }

  @action
  addChartSeries() {
    this.args.chart.addChartSeries();
    // We use this to trigger scrolling to the end of the mertric picker
    this.chartAdded = true;
  }

  @action
  reorderChartSeries(chartSeries: ChartSeries) {
    this.chart.chartSeries = chartSeries;
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action
  applyLogicalFilterOperator(chartSeries: ChartSeries, operator: LogicalFilterOperator) {
    chartSeries.applyLogicalFilterOperator(operator);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::MultimetricPicker': typeof MultimetricPicker;
    'reporting/custom/chart-builder/multimetric-picker': typeof MultimetricPicker;
  }
}
