/* import __COLOCATED_TEMPLATE__ from './pick-workflow-connector-action.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class PickWorkflowConnectorAction extends Component {
  @service appService;
  @service router;

  @tracked app = this.appService.app;
  @tracked workflowConnectorActions = this.args.actionEditorContext.workflowConnectorActions || [];

  get selectItems() {
    if (isPresent(this.workflowConnectorActions)) {
      return this.workflowConnectorActions
        .reject(
          (action) =>
            action.id !== this.args.action.actionData.workflow_connector_action_id &&
            this.usedWorkflowConnectorActionIds.includes(action.get('id')),
        )
        .map((action) => {
          return {
            text: action.get('name'),
            value: action.get('id'),
            description: action.get('description'),
          };
        });
    }

    return [];
  }

  get usedWorkflowConnectorActionIds() {
    if (isPresent(this.args.allRuleActions)) {
      return this.args.allRuleActions
        .filter(
          (action) =>
            action !== this.args.action &&
            action.type === 'workflow-connector-action' &&
            isPresent(action.get('actionData.workflow_connector_action_id')),
        )
        .map((action) => action.actionData.workflow_connector_action_id);
    }

    return [];
  }

  get selectedValue() {
    if (isPresent(this.workflowConnectorActions)) {
      return this.args.action.actionData.workflow_connector_action_id;
    }
  }

  get selectedWorkflowConnectorAction() {
    let workflowConnectorActionId = this.args.action.actionData.workflow_connector_action_id;
    if (workflowConnectorActionId) {
      return this.workflowConnectorActions.findBy('id', workflowConnectorActionId);
    }
  }

  get filterBlockDetail() {
    let action = this.selectedWorkflowConnectorAction;
    if (action) {
      return action.name;
    }
  }

  get filterBlockDetailTooltip() {
    let action = this.selectedWorkflowConnectorAction;
    if (action) {
      return action.description;
    }
  }

  @action
  updateWorkflowConnectorAction(workflowConnectorActionId) {
    this.args.action.set('actionData', { workflow_connector_action_id: workflowConnectorActionId });

    let onChange = this.onChange;
    if (onChange) {
      onChange();
    }
  }

  @action
  openNewWorkflowConnectorAction() {
    let uri = this.router.urlFor('apps.app.settings.workflow-connector-actions.index');
    safeWindowOpen(uri);
  }
}
