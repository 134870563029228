/* import __COLOCATED_TEMPLATE__ from './default-sender-address.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';

const USE_ADMIN_APP_EMAIL = 'use-admin-app-email';
const USE_INTERCOM_MAIL = 'use-intercom-email-address';
const MAP_TO_INBOUND_ADDRESS = 'map-to-inbound-email-address';

export default class DefaultSenderAddress extends Component {
  @service appService;
  @service intercomEventService;
  @service notificationsService;
  @service permissionsService;
  @service intl;

  @tracked isSavingDefaultSender = false;
  @tracked showAddCustomSenderModal = false;
  @tracked showConfirmSaveModal = false;

  @tracked selectedCustomEmailAddressId = this.app.admin_reply_default_address_id;

  @tracked selectedOutboundDefaultSender = this.initialOuboundDefaultSenderSelection;
  @tracked tempOutboundDefaultSender = this.initialOuboundDefaultSenderSelection;
  @tracked selectedInboundDefaultSender = this.adminReplyDefaultAddressIdExists
    ? MAP_TO_INBOUND_ADDRESS
    : '';

  get initialOuboundDefaultSenderSelection() {
    if (this.app.hasDefaultSenderAddress && this.app.use_admin_app_email) {
      return USE_ADMIN_APP_EMAIL;
    } else {
      return USE_INTERCOM_MAIL;
    }
  }

  get app() {
    return this.appService.app;
  }

  get adminReplyDefaultAddressIdExists() {
    return (
      this.app.admin_reply_default_address_id && !isEmpty(this.app.admin_reply_default_address_id)
    );
  }

  get saveButtonDisabled() {
    return (
      this.isEnablingAdminAppEmailWithoutDKIM ||
      this.isEnablingMapToInboundEmailWithoutChangeDefaultAddress
    );
  }

  get isEnablingAdminAppEmailWithoutDKIM() {
    return this.useAdminAppEmailSelected && this.hasNoVerifiedAdminEmailDomains;
  }

  get customEmailTooltipDisabled() {
    return !this.hasNoVerifiedAdminEmailDomains;
  }

  get isEnablingMapToInboundEmailWithoutChangeDefaultAddress() {
    return (
      this.mapToInboundEmailSelected &&
      (!this.selectedCustomEmailAddressId ||
        this.app.admin_reply_default_address_id === this.selectedCustomEmailAddressId)
    );
  }

  get hasNoVerifiedAdminEmailDomains() {
    return this.verifiedAdminEmailDomains.length === 0;
  }

  get hasNoCustomEmailAddresses() {
    return this.app.customEmailAddresses.length === 0;
  }

  get customEmailSenderDomain() {
    let domain = 'company.com';
    if (!this.hasNoVerifiedAdminEmailDomains) {
      domain = this.verifiedAdminEmailDomains[0].domain;
    }
    return domain;
  }

  get verifiedAdminEmailDomains() {
    let verifiedCustomBounceDomains = this.args.customBounceSettings.filter(
      (settings) => settings.validRecordExists,
    );
    return this.app.adminEmailDKIMSettings.filter(
      (dkimSetting) =>
        dkimSetting.validRecordExists &&
        verifiedCustomBounceDomains.find(
          (customDomain) => customDomain.rootDomain === dkimSetting.domain,
        ),
    );
  }

  @action
  async saveDefaultEmailSenderSettings() {
    await this.permissionsService.checkHierarchyPermissionWithFeatureFlag(
      'can_settings__default_sender__manage',
    );

    if (!this.permissionsService.currentAdminCan('can_settings__default_sender__manage')) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_settings__default_sender__manage',
      );
    } else {
      this.showConfirmSaveModal = true;
    }
  }

  @action
  async confirmSaveDefaultEmailSenderSettings() {
    this.showConfirmSaveModal = false;
    this.isSavingDefaultSender = true;
    this.selectedOutboundDefaultSender = this.tempOutboundDefaultSender;

    if (this.intercomMailSelected && this.mapToInboundEmailSelected) {
      this.toggleMapToInboundEmailSelection();
      this.selectedCustomEmailAddressId = '';
    }

    try {
      let isEnablingAdminAppEmail = this.useAdminAppEmailSelected;
      let selectedCustomEmailAddressId = this.mapToInboundEmailSelected
        ? this.selectedCustomEmailAddressId
        : '';
      await this.app.updateDefaultEmailSenderSetting(
        isEnablingAdminAppEmail,
        selectedCustomEmailAddressId,
      );

      this.isSavingDefaultSender = false;
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.settings.senders.default-sender-address.save-confirmation'),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'default_sender_address',
        place: 'email',
        section: 'settings',
        inbound_default_sender_value: this.selectedInboundDefaultSender,
        outbound_default_sender_value: this.selectedOutboundDefaultSender,
      });
    } catch (error) {
      this.isSavingDefaultSender = false;
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('components.settings.senders.default-sender-address.save-error'),
      });
    }
  }

  get useAdminAppEmailSelected() {
    return this.selectedOutboundDefaultSender === USE_ADMIN_APP_EMAIL;
  }

  get mapToInboundEmailSelected() {
    return this.selectedInboundDefaultSender === MAP_TO_INBOUND_ADDRESS;
  }

  get intercomMailSelected() {
    return this.selectedOutboundDefaultSender === USE_INTERCOM_MAIL;
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_settings_page',
      });
    }
  }

  @action
  selectCustomEmailAddress(id) {
    this.selectedCustomEmailAddressId = id;
  }

  @action toggleAndSaveMapToInboundEmailSelection() {
    this.toggleMapToInboundEmailSelection();
    if (!this.mapToInboundEmailSelected) {
      this.saveDefaultEmailSenderSettings();
    }
  }

  @action toggleMapToInboundEmailSelection() {
    if (this.mapToInboundEmailSelected) {
      this.selectedInboundDefaultSender = '';
    } else {
      this.selectedInboundDefaultSender = MAP_TO_INBOUND_ADDRESS;
    }
  }

  @action updateOutboundDefaultSender(selectedDefaultSender) {
    if (this.selectedOutboundDefaultSender !== selectedDefaultSender) {
      this.tempOutboundDefaultSender = selectedDefaultSender;
      this.saveDefaultEmailSenderSettings();
    }
  }

  get selectedValue() {
    let selectedCustomEmailAddress = this.customEmailAddresses.find(
      (item) => item.value === this.app.admin_reply_default_address_id,
    );
    return selectedCustomEmailAddress?.value;
  }

  get customEmailAddresses() {
    return this._mapCustomEmailAddressesToDropdownItems(this.app.get('customEmailAddresses'));
  }

  _mapCustomEmailAddressesToDropdownItems(addresses) {
    return addresses.map((address) => {
      return {
        text: address.email,
        value: address.id,
        component: 'content-editor/panels/content/assignee-sender-item',
        isDisabled: !address.verified,
        componentAttrs: {
          model: address,
          modelDisplayName: address.name,
          modelDisplayEmail: address.email,
          isUnverified: !address.verified,
          isUnauthenticated: !address.customBounceValid,
        },
      };
    });
  }
}
