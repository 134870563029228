/* import __COLOCATED_TEMPLATE__ from './linechart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import SerieschartBuilder from 'embercom/lib/reporting/flexible/serieschart-builder';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import PALETTE from '@intercom/pulse/lib/palette';
import { SORTING_LABEL_NAME } from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';
import { mapXAxisLabel } from 'embercom/lib/reporting/flexible/label-formatter';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';
import {
  calculatePercentageDataResponse,
  shouldConvertNullsToZeros,
  shouldAllowZeroValues,
  getFirstSeriesBucketKeys,
} from 'embercom/lib/reporting/flexible/data-response-helpers';
import { chunk } from 'underscore';
import { isEmpty, isPresent } from '@ember/utils';
import {
  mapHumanReadableLabelsToRawKey,
  getChartSeriesName,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import HighchartsDataBuilder from 'embercom/lib/reporting/flexible/highcharts-data-builder';
import { getOwner } from '@ember/application';

export default class Linechart extends Component {
  @service appService;
  @service intercomEventService;

  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
    renderableChart: this.args.renderableChart,
  }));

  get app() {
    return this.appService.app;
  }

  get legend() {
    if (this.args.viewConfig.legend && !this.args.renderableChart?.showTimeComparison) {
      return this.args.viewConfig.legend;
    }

    return {
      comparison_columnchart: () => {
        return `${moment(this.args.range.startMoment).format(
          dateAndTimeFormats.dateWithoutYear,
        )} - ${moment(this.args.range.endMoment).format(dateAndTimeFormats.dateWithoutYear)}`;
      },
      comparison_columnchart_previous: () => {
        return `Previous ${this.args.range.inDays} days`;
      },
    };
  }

  get isTimeComparison() {
    return (
      this.args.viewConfig.grouping?.isComparison || this.args.renderableChart?.showTimeComparison
    );
  }

  mapToSameAxis(dataResponses) {
    if (!dataResponses) {
      return dataResponses;
    }
    if (dataResponses.length === 1) {
      dataResponses[0].groups[0].names = [...dataResponses[0].groups[0].values];
      return dataResponses;
    }

    let currentPeriodResponses = this.isTimeComparison
      ? dataResponses.slice(dataResponses.length / 2)
      : dataResponses;
    let previousPeriodResponses = this.isTimeComparison
      ? dataResponses.slice(0, dataResponses.length / 2)
      : [];
    currentPeriodResponses.forEach((response) => {
      response.groups[0].names = [...response.groups[0].values];
    });

    if (
      this.args.viewConfig.formatUnit.unit === units.percent &&
      isEmpty(this.args.viewConfig.metrics)
    ) {
      return chunk(dataResponses, 2).map(([numeratorData, denominatorData]) => {
        return calculatePercentageDataResponse({
          numeratorData,
          denominatorData,
        });
      });
    }

    // we care only about a single nesting level of groups
    // we also rely on the presence of the group. Add validation in the future
    previousPeriodResponses.forEach((response, index) => {
      response.groups[0].names = response.groups[0].values;
      response.groups[0].values = [...currentPeriodResponses[index].groups[0].values];
    });

    return [...previousPeriodResponses, ...currentPeriodResponses];
  }

  convertNullsToZeros(responseWithNulls, aggregation, metric) {
    if (!shouldConvertNullsToZeros(aggregation, metric)) {
      return responseWithNulls;
    }

    return responseWithNulls.map((point) => [point[0], point[1] || 0, point[2]]);
  }

  @cached
  get dataWithNulls() {
    let groupingTransformation = this.args.viewConfig.grouping?.dataTransformation;
    try {
      let mappedResponse = this.mapToSameAxis(this.dataResource.rawChartData);
      let firstSeriesBucketKeys = getFirstSeriesBucketKeys(
        this.args.renderableChart,
        this.dataResource.rawChartData,
      );
      return mappedResponse.map((response) => {
        if (groupingTransformation) {
          response = groupingTransformation(response);
        }

        return this.buildVisualisationData(response, firstSeriesBucketKeys);
      });
    } catch {
      this.dataResource.notifyError();
      return [];
    }
  }

  get rawChartData() {
    return this.dataResource?.rawChartData || [];
  }

  get isSegmented() {
    // @ts-ignore
    let response = !isEmpty(this.rawChartData) ? this.rawChartData.firstObject : undefined;
    return response && !isEmpty(response.groups) && response.groups[0]?.values[0]?.groups;
  }

  @cached
  get chartData() {
    if (this.isSegmented) {
      return this.chartDataForSegmentedResponse;
    }
    // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
    // eslint-disable-next-line
    this.dataModified = {};
    return this.dataWithNulls.map((response, index) => {
      let aggregation = this.args.dataConfig.series[index].type;
      let metric = this.args.viewConfig.metrics?.[index];
      let data = this.convertNullsToZeros(response, aggregation, metric);
      data = this.sortIfNeeded(data);
      // For multi-metric charts with percentage or ratio metrics, we need to
      // use the main metric id, and not the metric id in the rawChartData
      let seriesName = metric?.id || this.dataResource.rawChartData[index].name;

      let legendLabel;

      let seriesIsPreviousPeriod = false;

      if (this.args.renderableChart?.showTimeComparison) {
        seriesIsPreviousPeriod = this.dataResource.rawChartData[index].name.includes('previous');

        let metricNameLabel = '';
        if (this.app.canSeeR2MultimetricsNewUi && this.args.renderableChart?.isMultimetric) {
          let numberOfCurrentSeries = this.args.renderableChart.chartSeries.length;
          let seriesIndex = seriesIsPreviousPeriod ? index : index - numberOfCurrentSeries;
          metricNameLabel = getChartSeriesName(seriesIndex, this.args.renderableChart);
        }
        let previousLabel =
          this.legend['comparison_columnchart_previous'] &&
          this.legend['comparison_columnchart_previous'].call(seriesName);
        let comparisonLabel =
          this.legend['comparison_columnchart'] &&
          this.legend['comparison_columnchart'].call(seriesName);

        if (seriesIsPreviousPeriod) {
          legendLabel = metricNameLabel ? `${metricNameLabel} - ${previousLabel}` : previousLabel;
        } else {
          legendLabel = metricNameLabel
            ? `${metricNameLabel} - ${comparisonLabel}`
            : comparisonLabel;
        }
      } else if (this.app.canSeeR2MultimetricsNewUi && this.args.renderableChart?.isMultimetric) {
        legendLabel = getChartSeriesName(index, this.args.renderableChart);
      } else {
        legendLabel = this.legend[seriesName] && this.legend[seriesName].call();
      }

      let options = {
        name: seriesName,
        data,
        legendLabel,
        tooltipDisplayUnit: this.args.viewConfig.columnChart?.series?.tooltips[seriesName],
        showInLegend: this.dataWithNulls.length > 1,
        dataModified: this.dataModified,
        isComparison:
          this.args.viewConfig.grouping?.isComparison ||
          this.args.renderableChart?.showTimeComparison,
        marker: {
          symbol: 'circle',
        },
        rawToLabelMapping: mapHumanReadableLabelsToRawKey(
          this.dataResource.rawChartData[0],
          this.args.dataConfig,
          this.args.viewConfig,
        ),
        isPreviousPeriod: seriesIsPreviousPeriod,
      };

      return options;
    });
  }

  buildVisualisationData(dataResponse, keys = []) {
    // we assume there's only one group for a simple barchart
    let group = dataResponse.groups[0];
    let aggregation = group.aggregations[0];

    return this.args.renderableChart?.isMultimetric
      ? this.buildMultimetricVisualisationData(group, aggregation, keys)
      : this.buildVisualizationDataFromResponseGroups(group, aggregation, keys);
  }

  buildVisualizationDataFromResponseGroups(group, aggregation, keys) {
    return group.values
      .map((gv, index) => {
        if (isPresent(keys) && !keys.includes(gv)) {
          return undefined;
        }
        return this.getTransformedPoint(group, aggregation, gv, index);
      })
      .compact();
  }

  buildMultimetricVisualisationData(group, aggregation, keys) {
    if (this.args.renderableChart.isBrokenDownByTime || !isPresent(keys)) {
      // the buckets are correctly ordered by timeline and all responses have the same buckets keys when the chart is viewing by Time
      // there is no need to process the responses using the keys from the first response.
      return this.buildVisualizationDataFromResponseGroups(group, aggregation, keys);
    }
    return keys
      .map((key) => {
        if (!group.values.includes(key)) {
          return undefined;
        }
        let index = group.values.indexOf(key);
        return this.getTransformedPoint(group, aggregation, key, index);
      })
      .compact();
  }

  getTransformedPoint(group, aggregation, key, index) {
    let x = key;
    let y =
      aggregation.values[index] !== 0 || shouldAllowZeroValues ? aggregation.values[index] : null;

    if (this.args.dataConfig.xAxis.type === 'nominal') {
      let mappedX = mapXAxisLabel(this.args.dataConfig, this.args.viewConfig, x);
      return [mappedX.toString(), y, mappedX];
    } else {
      let name = group?.names?.get(index);
      return [x, y, name];
    }
  }

  get aggregationFunction() {
    return this.args.dataConfig?.series?.firstObject?.type;
  }

  get shouldAllowZeroValues() {
    return shouldAllowZeroValues(this.args.viewConfig.metrics?.[0], this.aggregationFunction);
  }

  sortIfNeeded(visualisationData) {
    //sort by label name
    if (this.args.dataConfig.xAxis.data.sorting === SORTING_LABEL_NAME) {
      visualisationData.sort(function (pointA, pointB) {
        return pointA[2].localeCompare(pointB[2]);
      });
    }

    return visualisationData;
  }

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.isSegmented
        ? { ...this.args.viewConfig, mergeTooltipsForCoincidentPoints: true }
        : this.args.viewConfig,
      dataConfig: this.args.dataConfig,
      chartType: 'line',
      app: this.app,
      chartHeight: this.args.chartHeight,
      isMultimetric: this.args.renderableChart?.isMultimetric || false,
      isTimeComparison: this.args.renderableChart?.showTimeComparison || false,
    };

    if (this.args.viewConfig.seriesColors) {
      options['seriesColors'] = this.args.viewConfig.seriesColors;
    } else if (this.chartData.length > 1 && !this.isSegmented && !options.isMultimetric) {
      options['seriesColors'] = [PALETTE['vis-azure-80'], PALETTE['vis-azure-50']];
    }

    let builder = new SerieschartBuilder(options);
    return builder.buildTheme();
  }

  get chartDataForSegmentedResponse() {
    try {
      return new HighchartsDataBuilder(
        getOwner(this),
        this.args.dataConfig,
        this.args.viewConfig,
      ).forStackedChartResponse(this.rawChartData);
    } catch (e) {
      console.error(e);
      this.dataResource?.notifyError();
      return [];
    }
  }

  get hasData() {
    if (this.isSegmented) {
      return this.chartData.some((singleSeries) => {
        return singleSeries.data.some((dataArray) => dataArray[1]);
      });
    } else if (this.shouldAllowZeroValues) {
      return this.dataWithNulls
        .flat()
        .some((dataArray) => dataArray[1] !== null && dataArray[1] !== undefined);
    } else {
      return this.dataWithNulls.flat().some((dataArray) => dataArray[1]);
    }
  }

  get showEmptyState() {
    return this.args.isPaywalled || !this.hasData;
  }
}
