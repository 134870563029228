/* import __COLOCATED_TEMPLATE__ from './buttons.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

const SUBSCRIPTION_TYPE_LIMIT = 30;

export default class Buttons extends Component {
  @service appService;
  @service router;
  @service intercomEventService;
  @tracked isOpen = false;

  get limit() {
    return SUBSCRIPTION_TYPE_LIMIT;
  }

  get app() {
    return this.appService.app;
  }

  @action
  routeToNewSubscriptionPage() {
    let routeName = this.args.newSettings
      ? 'apps.app.settings.proactive-support.subscriptions.new'
      : 'apps.app.outbound.settings.subscription-types.new';
    return this.router.transitionTo(routeName);
  }

  get hasReachedLimit() {
    return this.args.subscriptionTypes.length >= SUBSCRIPTION_TYPE_LIMIT;
  }

  get previewUnsubscribeLink() {
    return `https://${this.app.email_sender_domain}/subscriptions/unsubscribe?app_id=${this.app.id}&sample=true`;
  }

  get contentTypeItems() {
    return [
      {
        items: [
          {
            text: 'Email',
            onSelectItem: () => {
              this.intercomEventService.trackAnalyticsEvent({
                action: 'clicked',
                section: 'outbound_subscriptions',
                link_to: this.previewUnsubscribeLink,
                object: 'email_unsubscribe_link',
              });
              return safeWindowOpen(this.previewUnsubscribeLink);
            },
          },
          {
            text: 'SMS',
            onSelectItem: () => {
              let link = `${this.previewUnsubscribeLink}&content_type=${objectTypes.sms}`;
              this.intercomEventService.trackAnalyticsEvent({
                action: 'clicked',
                link_to: link,
                section: 'outbound_subsriptions',
                object: 'sms_unsubscribe_link',
              });

              return safeWindowOpen(link);
            },
          },
        ],
      },
    ];
  }
}
