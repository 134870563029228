/* import __COLOCATED_TEMPLATE__ from './template-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import type Chart from 'embercom/models/reporting/custom/chart';
import type Report from 'embercom/models/reporting/custom/report';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';
import { isPresent } from '@ember/utils';

interface Args {
  closeModal: () => void;
}

export default class TemplateModal extends Component<Args> {
  @service declare router: $TSFixMe;
  @service declare store: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare reportingTemplates: ReportingTemplates;
  @service declare reportingFolderManagerService: ReportingFolderManagerService;

  @tracked report: Report;
  @tracked templateKey: string | null;
  @tracked width;
  @tracked searchTerm = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.report = this.store.createRecord('reporting/custom/report');
    this.templateKey = null;
    this.width = this.widthForState;
  }

  get filteredTemplates() {
    return this.reportingTemplates.searchReports(this.searchTerm);
  }

  get widthForState() {
    return this.templateKey ? 'vw95' : 'xl';
  }

  @action
  close() {
    if (!this.templateKey) {
      // close the modal if we're not previewing a template
      this.args.closeModal();
      return;
    }

    // otherwise, reset the state & go back to the template list
    this.templateKey = null;
    this.width = this.widthForState;
    this.report.charts.toArray().forEach((chart: Chart) => {
      chart.unloadRecord();
    });
    this.report.unloadRecord(); // unload and recreate the report so that it's not cached locally
    this.report = this.store.createRecord('reporting/custom/report');
  }

  @action
  async saveReportTemplate() {
    this.trackAnalyticsEvent('template_report_saved');
    this.reportingFolderManagerService.resetActiveFolder();

    let newReport = await this.report.save({ adapterOptions: { canSeeR2Beta: true } });
    this.router.transitionTo('apps.app.reports.custom-reports.report.show', newReport.id, {
      queryParams: {
        fromReportCreation: true,
      },
    });
    this.args.closeModal();
  }

  @action
  useTemplateReport(templateKey: string) {
    this.templateKey = templateKey;
    this.width = this.widthForState;
    this.trackAnalyticsEvent('template_report_previewed');
  }

  trackAnalyticsEvent(action: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'custom_report_2',
      section: 'reports',
      template_key: this.templateKey,
    });
  }

  get templateFeatureKey() {
    if (!isPresent(this.templateKey)) {
      return;
    }
    // @ts-ignore we check null state above
    return this.reportingTemplates.reportTemplatesById[this.templateKey].featureKey;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::TemplateModal': typeof TemplateModal;
    'reporting/custom/report/template-modal': typeof TemplateModal;
  }
}
