/* import __COLOCATED_TEMPLATE__ from './draggable-chart-chrome.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
// eslint-disable-next-line @intercom/intercom/max-file-length
import Component from '@glimmer/component';
import RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { humanizeFilters } from 'embercom/lib/reporting/custom/filter-helpers';
import Run from 'embercom/lib/run';
import { WIDTH_TYPES } from 'embercom/models/reporting/custom/chart';
import { getOwner } from '@ember/application';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { isEmpty, isPresent } from '@ember/utils';
import { TEAM_OPTIONS } from 'embercom/lib/reporting/custom/data-config-builder-helpers';

const WIDTH_TYPE_TO_CSS_CLASS_MAPPING = {
  [WIDTH_TYPES.FULL]: 'w-full',
  [WIDTH_TYPES.TWO_COLUMN]: 'w-2/6',
  [WIDTH_TYPES.THREE_COLUMN]: 'w-3/6',
  [WIDTH_TYPES.FOUR_COLUMN]: 'w-4/6',
  [WIDTH_TYPES.ONE_QUARTER]: 'w-3/12',
};

const SUPPORTED_PLATFORM_REPORTS = [
  'team_inbox_performance_report',
  'teammate_performance_report',
  'calls_report',
  'tickets_report',
  'fin_report',
  'copilot_report',
  'overview_report',
];

export default class DraggableChartChrome extends Component {
  @service store;
  @service realTimeEventService;
  @service appService;
  @service permissionsService;
  @service intl;
  @service chartBuilderFilterService;
  @service reportingUnderlyingDataService;
  @service customReportsService;

  @tracked isHovered = false;
  @tracked hideChart = false;
  @tracked isDragged = false;
  @tracked topics = [];
  @tracked actionDropdownIsOpened = false;
  @tracked showSideDrawer = false;
  @tracked showUnderlyingData = false;
  @tracked selection = [];
  @tracked previousSelection = [];
  @tracked showAggregationEditor = false;
  @tracked showExportModal = false;
  @tracked staticActionDropdownIsOpened = false;
  @tracked drillInChartSeries = null;

  constructor() {
    super(...arguments);
    this.loadTopics.perform();
    this.realTimeEventService.on('ConversationTopicPropagatedStatusChanged', this, (event) => {
      this.handleTopicPropagationStatusChange(event.topicId);
    });
  }

  handleTopicPropagationStatusChange(topicId) {
    let wasPropagating = this.hasPropagatingTopic;
    this.topics.forEach(async (topic) => {
      if (topic.id === topicId) {
        await topic.reload();
        if (wasPropagating === true && !this.hasPropagatingTopic) {
          this.args.updateShowPropagationFinishedBanner();
        }
      }
    });
  }

  @action
  openActionDropdown() {
    this.actionDropdownIsOpened = true;
    this.args.trackAnalyticsEvent({
      action: 'opened_action_dropdown',
      object: 'custom_chart',
    });
  }

  @action
  closeActionDropdown() {
    this.actionDropdownIsOpened = false;
  }

  @task({ restartable: true }) *loadTopics() {
    if (isPresent(this.topicFilters)) {
      this.topics = yield this.store.findAll('conversational-insights/conversation-topic', {
        reload: false,
      });
    }
  }

  @action
  openStaticActionDropdown() {
    this.staticActionDropdownIsOpened = true;
    this.args.trackAnalyticsEvent({
      action: 'opened_action_dropdown',
      object: 'static_report',
    });
  }

  get renderableChart() {
    return new RenderableChart(this.args.chartState, this.args.reportState, getOwner(this));
  }

  get availableTeammates() {
    return this.appService.app.humanAdmins;
  }

  get hasFilters() {
    return (
      this.teammateFilter ||
      this.teamFilter ||
      this.memberOfFilter ||
      this.hasReportFiltersExceptTeammateOrTeam ||
      this.hasChartFilters
    );
  }

  get hasReportFiltersExceptTeammateOrTeam() {
    return isPresent(this.humanizedReportFilters);
  }

  get reportFiltersExceptTeammateOrTeam() {
    let filters = this.args.reportState.filters?.filters?.reject(
      (filter) => filter.data.property === 'teammate' || filter.data.property === 'team',
    );

    return { ...this.args.reportState.filters, filters };
  }

  get hasChartFilters() {
    return this.args.chartState.chartSeries.toArray().some((chartSeries) => {
      return chartSeries.filters.filters.length > 0;
    });
  }

  get teammateFilter() {
    return this.args.reportState.filters?.filters?.find((filter) => {
      return filter.data.property === 'teammate';
    });
  }

  get teamFilter() {
    return this.args.reportState.filters?.filters?.find((filter) => {
      return filter.data.property === 'team';
    });
  }

  get memberOfFilter() {
    return this.args.reportState.filters?.filters?.find((filter) => {
      return TEAM_OPTIONS.includes(filter.type);
    });
  }

  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }

  get shouldShowActions() {
    // If the chart is paywalled & we're not in edit mode, there are no actions available
    if (this.isPaywalledCustomChart && !this.args.editMode) {
      return false;
    }

    return this.isHovered || this.actionDropdownIsOpened;
  }

  get shouldShowStaticActions() {
    return this.isHovered || this.staticActionDropdownIsOpened;
  }

  get shouldDisplayChartFilters() {
    if (this.appService.app.canSeeR2MultimetricsNewUi) {
      return false;
    }

    return !this.args.reportState.isStatic || this.args.reportState.isTemplate;
  }

  get titleHovered() {
    return (this.isHovered || this.actionDropdownIsOpened) && !this.args.reportState.isStatic;
  }

  get chartWidthClass() {
    let { isFullWidth, widthType } = this.args.chartState;
    let classFromWidthType = WIDTH_TYPE_TO_CSS_CLASS_MAPPING[widthType];
    let classFromFullWidth = isFullWidth ? WIDTH_TYPE_TO_CSS_CLASS_MAPPING[WIDTH_TYPES.FULL] : null;

    return classFromWidthType || classFromFullWidth || 'w-1/2';
  }

  get isPaywalledCustomChart() {
    return this.args.paywall?.isActive && isEmpty(this.args.chartState.templateId);
  }

  get hidePaywallIcon() {
    return this.args.chartState.isSmall || this.args.chartState.isXSmall;
  }

  findFilter(property) {
    return this.args.reportState.filters?.filters?.find((filter) => {
      return filter.data.property === property;
    });
  }

  @action
  editChart(chartId) {
    this.args.editChart(chartId);
  }

  @action
  deleteChart(chartId) {
    this.args.deleteChart(chartId);
  }

  get shouldDisplayTooltip() {
    return this.hasFilters;
  }

  get humanizedChartFilters() {
    // TODO update this to work with multiple series
    let filters = this.args.chartState.chartSeries.firstObject.filters;
    return this.intl.formatList(humanizeFilters(filters, this.renderableChart), {
      type: 'unit',
    });
  }

  get humanizedReportFilters() {
    return this.intl.formatList(
      humanizeFilters(this.reportFiltersExceptTeammateOrTeam, this.renderableChart),
      {
        type: 'unit',
      },
    );
  }

  get humanizedTeammateFilter() {
    let names = this.teammateFilter?.data?.values?.map((teammateId) => {
      if (teammateId === REPORTING_FILTER_SELECT_ALL) {
        return this.intl.t('reporting.custom-reports.any-teammate');
      }

      return (
        this.availableTeammates.find((teammate) => teammate.id.toString() === teammateId)
          ?.display_as_assignee || this.intl.t('reporting.custom-reports.former-teammate')
      );
    });

    let formattedNames = this.intl.formatList(names, { type: 'unit' });

    return this.intl.t('reporting.custom-reports.report-level-teammate-tooltip', {
      teammateNames: formattedNames,
      filterName: this.renderableChart.displayNameForTeammateProperty,
    });
  }

  get humanizeTeammateMemberOfFilter() {
    let names = this.memberOfFilter?.data?.values?.map((teamId) => {
      let teams = [...this.appService.app.teams, this.appService.app.unassignedAdmin];

      return (
        teams.find((team) => team.id.toString() === teamId)?.display_as_assignee ||
        this.intl.t('reporting.custom-reports.former-team')
      );
    });

    let formattedNames = this.intl.formatList(names, { type: 'unit' });

    return this.intl.t('reporting.custom-reports.report-level-teammate-tooltip', {
      teammateNames: formattedNames,
      filterName: this.renderableChart.displayNameForTeammateProperty,
    });
  }

  get humanizedTeamFilter() {
    let names = this.teamFilter?.data?.values?.map((teamId) => {
      if (teamId === REPORTING_FILTER_SELECT_ALL) {
        return this.intl.t('reporting.custom-reports.any-team');
      }

      let teams = [...this.appService.app.teams, this.appService.app.unassignedAdmin];

      return (
        teams.find((team) => team.id.toString() === teamId)?.display_as_assignee ||
        this.intl.t('reporting.custom-reports.former-team')
      );
    });

    let formattedNames = this.intl.formatList(names, { type: 'unit' });

    let displayName = this.renderableChart.displayNameForTeamProperty;
    if (!displayName) {
      return undefined;
    }

    return this.intl.t('reporting.custom-reports.report-level-team-tooltip', {
      teamNames: formattedNames,
      filterName: this.renderableChart.displayNameForTeamProperty,
    });
  }

  get showTeamOrTeammateFilter() {
    return this.teamFilter || this.teammateFilter || this.memberOfFilter;
  }

  get humanizedDynamicFilter() {
    if (this.memberOfFilter) {
      return this.humanizeTeammateMemberOfFilter;
    }
    if (this.teammateFilter) {
      return this.humanizedTeammateFilter;
    }
    if (this.teamFilter) {
      return this.humanizedTeamFilter;
    }

    return undefined;
  }

  get hasPropagatingTopic() {
    if (isPresent(this.topicFilters)) {
      if (this.loadTopics.isRunning) {
        return true;
      }
      let topicIds = this.topicFilters.flatMap((topicFilter) => topicFilter.data.values);
      if (topicIds.includes('any')) {
        return false;
      }
      return topicIds.some((selectedTopicId) => {
        let topic = this.topics.find((topic) => topic.id === selectedTopicId);
        if (topic) {
          return this.range?.comparisonStartMoment.isBefore(topic.fullyPropagatedFrom);
        } else {
          return false;
        }
      });
    }
    return false;
  }

  get topicFilters() {
    return this.args.chartState.chartSeries
      .toArray()
      .flatMap((chartSeries) => chartSeries.filters.filters)
      .filter((filter) => filter.data.property === 'topic');
  }

  get range() {
    return this.args.reportState.dateRange || this.args.chartState.range;
  }

  get dateRangePrefix() {
    return this.args.reportState.dateRange
      ? this.intl.t('reporting.custom-reports.report-date-range')
      : this.intl.t('reporting.custom-reports.chart-date-range');
  }

  get isDraggedOver() {
    return this.args.draggable && this.args.dragging && this.args.isDraggedOver;
  }

  @action
  onDragStart() {
    this.args.beforeDragStarts();
    this.isDragged = true;
    Run.later(this, () => {
      this.hideChart = true;
    });
  }

  @action
  onDragLeave(event) {
    event.stopPropagation();
  }

  @action
  onDragEnd() {
    Run.later(this, () => {
      this.isDragged = false;
      this.hideChart = false;
    });
  }

  @action
  openSideDrawer() {
    // TODO update this to work with multiple series
    let filters = this.args.chartState.chartSeries.firstObject.filters;
    this.args.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversations_list',
      currentFilters: filters,
    });
    this.showSideDrawer = true;
  }

  defaultColumns(chartSeries) {
    return this.reportingUnderlyingDataService.getDefaultColumns(chartSeries, this.renderableChart);
  }

  @action
  openUnderlyingDataModal(selectedChartSeries) {
    let chartSeries = selectedChartSeries ?? this.args.chartState.chartSeries.firstObject;
    if (this.permissionsService.currentAdminCan('can_reporting__drillin__access')) {
      this.args.trackAnalyticsEvent({
        action: 'opened',
        object: 'underlying_data_modal',
        chart_metric_id: chartSeries.metric.id,
      });
      this.setDefaultColumns(chartSeries);
      this.drillInChartSeries = chartSeries;
      this.showUnderlyingData = true;
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__drillin__access',
      );
    }
  }

  get isMultiMetricChart() {
    return this.renderableChart.chartSeries.length > 1;
  }

  get canViewCustomReports() {
    if (this.appService.app.canShareReportsInternally) {
      return this.permissionsService.currentAdminCan('can_access_reporting');
    }
    return this.permissionsService.currentAdminCan('can_reporting__custom_reports__read');
  }

  get showActionButtonInStaticReport() {
    return (
      (this.isAbleToUseDataTable && this.canUpdateAggregation) ||
      (this.isAbleToUseDataTable && this.appService.app.canSeeDrillInCsvExportModal)
    );
  }

  get isSupportedPlatformReport() {
    return SUPPORTED_PLATFORM_REPORTS.includes(this.args.report.id);
  }

  get isAbleToUseDataTable() {
    if (this.isMultiMetricChart || this.renderableChart.visualizationType === 'bespoke') {
      return false;
    }

    return this.args.report && this.isSupportedPlatformReport;
  }

  get reportPlace() {
    if (this.args.report) {
      if (isNaN(Number(this.args.report.id))) {
        return this.args.report.id;
      }
      return 'custom_report';
    }
  }

  get knownValuesEndpointSources() {
    let metrics = this.args.chartState.chartSeries.map((series) => series.metric);
    return metrics.map((metric) => metric.firstSource);
  }

  get conversationAttributeDescriptors() {
    return this.store.peekAll('conversation-attributes/descriptor');
  }

  get chartRange() {
    return this.appService.app.canSeeTimezoneFilterInCustomReport
      ? this.args.chartState.getRangeWithTimeZone(this.timezone)
      : this.args.chartState.range;
  }

  get reportTimezone() {
    if (
      this.appService.app.canSeeTimezoneFilterInCustomReport &&
      isPresent(this.args.report?.timezone)
    ) {
      return this.args.report.timezone;
    }
    return undefined;
  }

  get timezone() {
    return this.reportTimezone || this.appService.app.timezone;
  }

  get reportTeamOfficeHoursWarning() {
    if (this.args.reportState.dateRange) {
      return (
        this.args.chartState.chartSeries.firstObject?.metric.metricVariantType ===
          'team_office_hours' &&
        this.args.reportState.dateRange.startMoment.isBefore('2023-07-05T00:00:00Z')
      );
    } else {
      return this.args.chartState.usesOfficeHoursVariantMetric;
    }
  }

  get columns() {
    return this.selection;
  }

  get metric() {
    return this.args.chartState.chartSeries.firstObject.metric;
  }

  @action
  setDefaultColumns(chartSeries) {
    let defaultSelection = [];
    let cachedSelection = [];

    if (this.args.chartState.id) {
      cachedSelection = this.reportingUnderlyingDataService.loadSelectedColumnFromCache(
        this.args.chartState.id,
        chartSeries,
      );
    }

    if (isPresent(cachedSelection)) {
      defaultSelection = cachedSelection;
    } else {
      defaultSelection = this.defaultColumns(chartSeries);
    }
    this.selection = defaultSelection.uniq().compact();
    this.previousSelection = this.selection;
  }

  @action
  setColumns(chartSeries, columns) {
    this.selection = this.reportingUnderlyingDataService.getOrderedAttributeIds(
      chartSeries.metric,
      columns,
      this.previousSelection,
    );

    if (this.args.chartState.id) {
      this.reportingUnderlyingDataService.setColumnSelectionInCache(
        this.args.chartState.id,
        chartSeries,
        this.selection,
      );
    }

    this.previousSelection = this.selection;
  }

  @action
  onCloseUnderlyingDataModal() {
    this.showUnderlyingData = false;
    this.args.trackAnalyticsEvent({
      action: 'closed',
      object: 'underlying_data_modal',
    });
  }

  /********************************
   * Aggregation Modal
   ********************************/
  get canUpdateAggregation() {
    if (isEmpty(this.renderableChart.chartSeries)) {
      return false;
    }
    let series = this.renderableChart.chartSeries.toArray();
    return (
      this.renderableChart.shouldRenderChrome &&
      !this.renderableChart.isTable &&
      series.some((series) => series.aggregation !== undefined)
    );
  }

  @action
  openAggregationEditor() {
    this.showAggregationEditor = true;
  }

  @action
  closeAggregationEditor() {
    this.showAggregationEditor = false;
  }

  @action
  openExportModal() {
    this.showExportModal = true;
    this.args.trackAnalyticsEvent({
      action: 'opened',
      object: 'export_options_modal',
      place: this.reportPlace,
    });
  }

  @action
  onExportModalCloseEvent() {
    this.showExportModal = false;
    this.args.trackAnalyticsEvent({
      action: 'closed',
      object: 'export_options_modal',
      place: this.reportPlace,
    });
  }
}
