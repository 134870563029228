/* import __COLOCATED_TEMPLATE__ from './chart-template-side-panel.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  report: Report;
  addChart: (buttonPosition: string) => Promise<void>;
}

export default class ChartTemplateSidePanel extends Component<Args> {
  @service declare reportingTemplates: ReportingTemplates;

  @tracked searchTerm = '';

  get filteredTemplates() {
    return this.reportingTemplates.searchCharts(this.searchTerm);
  }

  @action
  async createNewChart() {
    await this.args.addChart('chart-template-side-panel');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ChartTemplateSidePanel': typeof ChartTemplateSidePanel;
    'reporting/custom/report/chart-template-side-drawer': typeof ChartTemplateSidePanel;
  }
}
