/* import __COLOCATED_TEMPLATE__ from './custom-state-dropdown-option.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { type SystemStateIconDetails } from 'embercom/services/ticket-state-service';
import type IntlService from 'ember-intl/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type Router } from '@ember/routing';
import type Session from 'embercom/services/session';

interface CustomStateDropdownOptionArgs {
  item?: TicketCustomStateOption;
  hideDropdownOverlay: () => void;
}

interface TicketCustomStateOption {
  component: string;
  state?: TicketCustomState;
  icon?: InterfaceIconName;
  iconColor?: string;
  onSelectItem: () => void;
  isCreateNew: boolean;
  isEmptyState?: boolean;
  systemStatesIcons?: SystemStateIconDetails[];
}

export default class CustomStateDropdownOption extends Component<CustomStateDropdownOptionArgs> {
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare session: Session;

  get icon(): InterfaceIconName {
    return (this.args.item?.icon || '') as InterfaceIconName;
  }

  get iconColor(): string {
    return this.args.item?.iconColor || '';
  }

  @action onCreateNew() {
    this.args.item?.onSelectItem();
    this.args.hideDropdownOverlay();
  }

  @action routeToStateSettings() {
    if (this.session.workspace.isFeatureEnabled('team-activation-channels-new-ia-settings-hub')) {
      this.router.transitionTo('apps.app.settings.helpdesk.tickets', {
        queryParams: { tab: 'ticket-states' },
      });
    } else {
      this.router.transitionTo('apps.app.settings.ticket-states');
    }
  }

  @action
  clientLabel() {
    let item = this.args.item?.state;
    return `${this.intl.t('settings.ticket-data.ticket-type-configuration.customer-sees')} "${
      item?.userLabel
    }"`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::CustomStateDropdownOption': typeof CustomStateDropdownOption;
    'settings/ticket-types/custom-state-dropdown-option': typeof CustomStateDropdownOption;
  }
}
