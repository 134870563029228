/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { HTTP_METHODS } from 'embercom/lib/custom-authentication/request-helper';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import resolveObjectPath from 'embercom/lib/resolve-object-path';

export default class Editor extends Component {
  @service notificationsService;
  @service intl;
  @service store;
  @service appService;
  @service router;

  @tracked customAuthenticationToken = this.args.customAuthenticationToken;
  @tracked selectedPreviewTab = 'headers';
  @tracked latestTestRequestParams;
  @tracked testResponse;
  @tracked bannerText;
  @tracked bannerType;
  @tracked displayableResponseBody;
  @tracked mostRecentTestPassed;

  get getIndexRoute() {
    return this.appService.app.canSeeNewIASettingsHub
      ? 'apps.app.settings.app-settings.authentication.index'
      : 'apps.app.settings.custom-authentication-tokens.index';
  }

  @task
  *saveChanges() {
    let editTokenRoute = this.appService.app.canSeeNewIASettingsHub
      ? 'apps.app.settings.app-settings.authentication.edit'
      : 'apps.app.settings.custom-authentication-tokens.edit';
    try {
      let wasNew = this.customAuthenticationToken.isNew;
      yield this.customAuthenticationToken.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.custom-authentication-tokens.new.save-success'),
      );
      if (wasNew) {
        this.router.transitionTo(editTokenRoute, this.customAuthenticationToken.id);
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('settings.custom-authentication-tokens.new.save-error'),
      );
    }
  }

  @task
  *testRequest() {
    let params = {
      app_id: this.appService.app.id,
      refresh_token_configuration: this.customAuthenticationToken.refreshTokenConfiguration,
    };

    try {
      this._setLatestTestRequestParams();
      this.mostRecentTestPassed = false; // to invalidate previous result
      this.testResponse = yield post('/ember/custom_authentication/tokens/test', params);
      this.displayableResponseBody = JSON.stringify(this.responseBody, null, 2);
      this._setBannerParams();
    } catch (exception) {
      this.displayableResponseBody = null;
      this._setBannerOnException(exception);
    }
  }

  _setLatestTestRequestParams() {
    this.latestTestRequestParams = this.store.createRecord('custom-authentication/token', {
      httpMethod: this.customAuthenticationToken.refreshTokenConfiguration.httpMethod,
      url: this.customAuthenticationToken.refreshTokenConfiguration.url,
      body: this.customAuthenticationToken.refreshTokenConfiguration.body,
      headers: this.customAuthenticationToken.refreshTokenConfiguration.headers,
    });
  }

  _setBannerParams() {
    this.bannerText = '';
    if (this.testResponse.refresh_response.success) {
      this.mostRecentTestPassed = true;
      this.bannerType = 'confirmation';
      this.bannerText += `${this.testResponse.refresh_response.status} ${this.testResponse.refresh_response.return_code.toUpperCase()}`;
    } else {
      this.mostRecentTestPassed = false;
      this.bannerType = 'error';
      this.bannerText +=
        this.testResponse.refresh_response.human_readable_error ||
        this.intl.t('settings.custom-authentication-tokens.new.response.error');
    }
  }

  _setBannerOnException(exception) {
    this.bannerType = 'error';
    this.bannerText = exception.errorThrown;
  }

  get responseBody() {
    return this.testResponse?.refresh_response.body;
  }

  get isBodyAvailable() {
    if (!this.customAuthenticationToken.refreshTokenConfiguration) {
      return false;
    }
    return ['post', 'put'].includes(
      this.customAuthenticationToken.refreshTokenConfiguration.httpMethod,
    );
  }

  get isSaveDisabled() {
    return !this._tokenIsValid() || !this._sufficientlyTested();
  }

  _tokenIsValid() {
    return this.customAuthenticationToken.isNew
      ? this.customAuthenticationToken.isValidForCreate
      : this.customAuthenticationToken.isValidForUpdate;
  }

  _sufficientlyTested() {
    if (this.customAuthenticationToken.tokenType !== 'http_request') {
      return true;
    }

    if (!this.customAuthenticationToken.isNew && this._requestParamsUnchanged()) {
      return true;
    }

    if (this.latestTestRequestParams === undefined) {
      return false;
    }

    if (this.mostRecentTestPassed === false) {
      return false;
    }

    return this._testSinceLastEdit();
  }

  _requestParamsUnchanged() {
    return !Object.keys(this.customAuthenticationToken.changedAttributes()).includes(
      'refreshTokenConfiguration',
    );
  }

  _testSinceLastEdit() {
    return this._configsMatch(
      this.customAuthenticationToken.refreshTokenConfiguration,
      this.latestTestRequestParams,
    );
  }

  _configsMatch(currentConfig, testedConfig) {
    return (
      currentConfig.httpMethod === testedConfig.httpMethod &&
      currentConfig.url === testedConfig.url &&
      currentConfig.body === testedConfig.body &&
      currentConfig.headers === testedConfig.headers
    );
  }

  get supportedTypes() {
    return [
      { text: this.intl.t('settings.custom-authentication-tokens.new.type.text'), value: 'text' },
      {
        text: this.intl.t('settings.custom-authentication-tokens.new.type.http-request'),
        value: 'http_request',
      },
    ];
  }

  get supportedHttpMethods() {
    return HTTP_METHODS.filter((method) => method.value !== 'delete');
  }

  get responsePaths() {
    let items = this.testResponse ? this.pathItemsFromResponse : [];
    if (this.customAuthenticationToken.refreshTokenConfiguration.tokenResponsePath) {
      items.pushObject(this.tokenPathItem);
    }
    return [
      {
        items: items.uniqBy('value'),
      },
    ];
  }

  get tokenPathItem() {
    return {
      value: this.customAuthenticationToken.refreshTokenConfiguration.tokenResponsePath,
      text: this.customAuthenticationToken.refreshTokenConfiguration.tokenResponsePath,
    };
  }

  get pathItemsFromResponse() {
    return this.testResponse.possible_response_paths.map((path) => {
      return {
        value: path,
        text: path,
      };
    });
  }

  get urlValidation() {
    return this.customAuthenticationToken.refreshTokenConfiguration.validations.attrs.url;
  }

  @action
  setPathAndTokenValue(path) {
    this.customAuthenticationToken.refreshTokenConfiguration.tokenResponsePath = path;
    this.customAuthenticationToken.tokenValue = resolveObjectPath(this.responseBody, path);
  }

  @action
  setRefreshConfigHttpMethod(httpMethod) {
    this.customAuthenticationToken.refreshTokenConfiguration.httpMethod = httpMethod;
    if (!this.isBodyAvailable) {
      this.selectedPreviewTab = 'headers';
    }
  }

  @action
  setTokenType(tokenType) {
    this.customAuthenticationToken.tokenType = tokenType;
    if (tokenType === 'http_request') {
      this.customAuthenticationToken.refreshTokenConfiguration = this.store.createFragment(
        'custom-authentication/refresh-token-configuration',
      );
    } else if (tokenType === 'text') {
      this.customAuthenticationToken.refreshTokenConfiguration = null;
    }
  }

  @action
  changePreviewTab(selectedTab) {
    this.selectedPreviewTab = selectedTab;
  }
}
