/* import __COLOCATED_TEMPLATE__ from './index-page.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class Switch extends Component {
  @service store;
  @service router;
  @service appService;

  constructor() {
    super(...arguments);
    this.redirect.perform();
  }

  @task({ restartable: true })
  *redirect() {
    this.settings = yield this.store.findRecord('switch/settings', this.appService.app.id);
    let hasPhoneNumberOrApiToken = this.settings.phoneNumber || this.settings.apiToken;

    if (hasPhoneNumberOrApiToken) {
      this.redirectToMonitor();
    } else {
      this.redirectToSetup();
    }
  }

  redirectToSetup() {
    this.router.transitionTo('apps.app.settings.switch.setup');
  }

  redirectToMonitor() {
    this.router.transitionTo('apps.app.settings.switch.monitor');
  }
}
