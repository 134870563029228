/* import __COLOCATED_TEMPLATE__ from './spambot-contacts.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class SpambotContacts extends Component {
  @service appService;
  @service store;
  @service permissionsService;

  get app() {
    return this.appService.app;
  }

  get currentAdminCanManageMessageSettings() {
    return this.app.currentAdmin.currentAppPermissions.can_manage_messages_settings;
  }

  get spambotContacts() {
    return this.store.peekAll('spambot-contact');
  }

  get teammates() {
    if (this.spambotContacts.length > 0) {
      let spambotContactsEmails = this.spambotContacts.map(
        (spambotContact) => spambotContact.emailAddress,
      );

      return this.app.admins.filter((admin) => spambotContactsEmails.includes(admin.email));
    }
    return [];
  }

  @action addSpambotContact(teammate) {
    let spambotContact = this.spambotContacts.find(
      (contact) => contact.emailAddress === teammate.email,
    );

    if (!spambotContact) {
      let newSpambotContact = this.store.createRecord('spambot-contact', {
        emailAddress: teammate.email,
      });

      newSpambotContact.save();
    }
  }

  @action deleteSpambotContact(deletedTeammate) {
    let spambotContact = this.spambotContacts.find(
      (contact) => contact.emailAddress === deletedTeammate.email,
    );

    spambotContact.destroyRecord();
  }
}
