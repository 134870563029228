/* import __COLOCATED_TEMPLATE__ from './fin-copilot-filter.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { difference, isEqual, without } from 'underscore';
import { inject as service } from '@ember/service';
import {
  NO_FILTER,
  COPILOT_FILTER_TYPE,
  COPILOT_FILTER_OBJECT,
} from 'embercom/lib/settings/filters/constants';
import {
  LIMITED_USAGE,
  NO_ACCESS,
  UNLIMITED_USAGE,
} from 'embercom/lib/settings/copilot-access-editor/constants';

export default class FinCopilotFilter extends Component {
  @service appService;
  @service intl;

  @tracked selectedFilter = this.args.filterValues;

  get filterItems() {
    return [
      {
        items: [
          {
            text: this.intl.t('settings.filters.fin-copilot-filter.any'),
            value: NO_FILTER,
            isSelected: this.selectedFilter.includes(NO_FILTER),
          },
          {
            text: this.intl.t('settings.filters.fin-copilot-filter.unlimited'),
            value: UNLIMITED_USAGE,
            isSelected: this.selectedFilter.includes(UNLIMITED_USAGE),
          },
          {
            text: this.intl.t('settings.filters.fin-copilot-filter.included'),
            value: LIMITED_USAGE,
            isSelected: this.selectedFilter.includes(LIMITED_USAGE),
          },
          {
            text: this.intl.t('settings.filters.fin-copilot-filter.off'),
            value: NO_ACCESS,
            isSelected: this.selectedFilter.includes(NO_ACCESS),
          },
        ],
      },
    ];
  }

  get currentLabel() {
    if (isEqual(this.selectedFilter, [NO_FILTER])) {
      return this.intl.t('settings.filters.fin-copilot-filter.any');
    } else if (isEqual(this.selectedFilter, [NO_ACCESS])) {
      return this.intl.t('settings.filters.fin-copilot-filter.off');
    } else {
      let selectedStatus = this.selectedFilter.map((filterValue) => {
        return this.intl.t(
          `settings.filters.fin-copilot-filter.${COPILOT_FILTER_OBJECT[filterValue]}`,
        );
      });
      return selectedStatus.join(', ');
    }
  }

  @action
  onChangeFilterValue(value) {
    let addedFilterValue = difference(value, this.selectedFilter);

    if (isEqual(addedFilterValue, [NO_FILTER]) || isEqual(value, [])) {
      this.selectedFilter = [NO_FILTER];
    } else if (isEqual(addedFilterValue, [NO_ACCESS])) {
      this.selectedFilter = [NO_ACCESS];
    } else {
      this.selectedFilter = without(value, NO_FILTER, NO_ACCESS);
    }
  }

  @action
  clearFilters() {
    if (isEqual(this.args.filterValues, [NO_FILTER])) {
      this.selectedFilter = this.args.filterValues;
    }
  }

  @action
  updateFilter() {
    this.args.updateFilterValues(COPILOT_FILTER_TYPE, this.selectedFilter);
  }
}
