/* import __COLOCATED_TEMPLATE__ from './classify-conversation-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLElement;
  Args: {
    action: any;
    onChange?: () => void;
    isDisabled?: boolean;
    onDelete?: () => void;
  };
  Blocks: {
    default: [args: { opener: unknown; label?: string }];
  };
}

export default class ClassifyConversationAttribute extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare attributeService: any;
  @service declare intl: any;

  get attributes() {
    return this.attributeService.conversationAttributeDescriptors.toArray() as Array<{
      identifier: string;
      name: string;
      dataType: string;
      isClassificationAttribute: boolean;
    }>;
  }

  get classifiableAttributes() {
    return this.attributes
      .filter((attribute) => attribute.isClassificationAttribute)
      .filter((attribute) => attribute.dataType === 'list')
      .map((attribute) => ({
        value: attribute.identifier,
        text: attribute.name,
      }));
  }

  get conversationDataAttribute() {
    return this.attributes.find(
      (attribute: any) => attribute.identifier === this.attributeIdentifier,
    );
  }

  get attributeIdentifier() {
    return this.args.action.get('actionData.attribute_identifier');
  }

  get label() {
    if (this.conversationDataAttribute) {
      return this.intl.t(
        'settings.inbox-rules.index.actions.classify-conversation-attribute.name',
        {
          name: this.conversationDataAttribute.name,
        },
      );
    } else {
      return this.intl.t(
        'settings.inbox-rules.index.actions.classify-conversation-attribute.placeholder',
      );
    }
  }

  @action
  updateAttribute(identifier: string) {
    this.args.action.actionData = { attribute_identifier: identifier };

    let onChange = this.args.onChange;
    if (onChange) {
      onChange();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::ClassifyConversationAttribute': typeof ClassifyConversationAttribute;
  }
}
