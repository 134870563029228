/* import __COLOCATED_TEMPLATE__ from './static-report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
// @ts-ignore
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Range from 'embercom/models/reporting/range';
import { isPresent } from '@ember/utils';
import { flatten, groupBy } from 'underscore';
import {
  CUSTOM_COMPANY_ATTRIBUTE,
  CUSTOM_USER_ATTRIBUTE,
} from 'embercom/objects/reporting/unified/datasets/types';
import { modifier } from 'ember-modifier';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type { MetricPropertyDefinition } from 'embercom/objects/reporting/unified/properties/types';
import type {
  TopLevelFilter,
  LogicalFilterOperator,
} from 'embercom/components/reporting/custom/filters';
import type FilterMigrationService from 'embercom/services/filter-migration-service';
import type { StickyFilter } from 'embercom/components/reporting/custom/chart-builder/filter-bar/filter-bar';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type ReportingService from 'embercom/services/reporting-service';
import { captureException } from 'embercom/lib/sentry';

interface Signature {
  Args: {
    reportDefinition: {
      id: string;
      charts: { id: string }[];
      filters: TopLevelFilter;
      date_range?: any;
    };
    datasetId: string;
    settings?: any;
    renderTimezones?: boolean;
    colorChartsByPoint?: boolean;
    persistFilters: boolean;
    filterableProperties: string[];
    dashboard?: string;
    customFilterArgs?: Record<string, any>;
    conversationAttributeDescriptors: ConversationAttributeDescriptor[];
    loadKnownValuesSources?: string[];
    stickyFilterTypes?: StickyFilter[];
  };
  Blocks: {
    default: any;
  };
}

export default class StaticReport extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare reportingService: ReportingService;
  @service declare intercomEventService: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare filterMigrationService: FilterMigrationService;

  reportId = this.args.reportDefinition['id'];

  constructor(owner: any, args: Signature['Args']) {
    super(owner, args);
    if (!this.args.reportDefinition) {
      throw Error('Missing value for @reportDefinition');
    }

    if (!this.args.datasetId) {
      throw Error('Missing value for @datasetId');
    }

    let reportDefinition = this.sanitizedReportDefinition;
    let persistedFilters = this.getFiltersFromLocalStorageAndMigrate();
    if (persistedFilters) {
      reportDefinition.filters = persistedFilters;
    }

    this.store.pushPayload({
      'reporting/custom/report': reportDefinition,
    });
    if (this.args.renderTimezones) {
      this.report.timezone = this.defaultTimezone;
    }
  }

  onFiltersChange = modifier(
    (_, [filters]) => {
      if (this.args.persistFilters) {
        this.saveFiltersToLocalStorage(filters as TopLevelFilter);
      }
    },
    { eager: false },
  );

  get sanitizedReportDefinition() {
    let sanitizedReportDefinition = copy(this.args.reportDefinition, true);
    // Sanitize chart ids so we don't accidentally overwrite other custom charts in the store
    sanitizedReportDefinition['id'] = this.reportId;
    sanitizedReportDefinition['charts'].forEach((chart: any, index: number) => {
      chart['id'] = `chart-${this.reportId}-${index}`;
    });
    return sanitizedReportDefinition;
  }

  willDestroy() {
    super.willDestroy();
    if (this.report) {
      // Remove all chart records
      this.report.charts.toArray().forEach((chart: any) => {
        chart.unloadRecord();
      });
      // Remove the report record
      this.report.unloadRecord();
    }
  }

  get report() {
    return this.store.peekRecord('reporting/custom/report', this.reportId);
  }

  get reportState() {
    return {
      settings: this.args.settings,
      dateRange: this.report.range || this.range,
      filters: this.report.filters,
      isStatic: true,
      colorChartsByPoint: this.args.colorChartsByPoint,
      timezone: this.timezone,
      id: this.reportId,
    };
  }

  get filtersWithDateRange() {
    return this.report.filters;
  }

  get filterableMetricProperties(): MetricPropertyDefinition[] {
    if (this.args.datasetId === 'no_dataset') {
      let groupedCDAs = groupBy(
        this.reportingMetrics.getFilterableDatasetCdaAttributesFor('conversation'),
        (filter) => filter.group!,
      );

      return flatten([
        this.reportingMetrics.getPropertiesByIds(this.args.filterableProperties),
        groupedCDAs[CUSTOM_COMPANY_ATTRIBUTE],
        groupedCDAs[CUSTOM_USER_ATTRIBUTE],
      ]).compact();
    } else {
      return flatten([
        this.reportingMetrics.getPropertiesByIds(this.args.filterableProperties),
        this.reportingMetrics.getFilterableDatasetCdaAttributesFor(this.args.datasetId),
      ]);
    }
  }

  get timezone() {
    if (this.args.renderTimezones) {
      return localStorage.getItem(`${this.reportId}_timezone`) || this.report?.reportTimezone;
    }
    return this.appService.app.timezone;
  }

  get defaultTimezone() {
    return localStorage.getItem(`${this.reportId}_timezone`) || this.appService.app.timezone;
  }

  @action
  updateFilter(
    index: any,
    attribute: { id: string; field: string },
    filterValues: any[],
    operator: string,
  ) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      place: 'reporting',
      object: 'static_report',
      static_report_name: this.reportId,
      filter_name: attribute.id,
    });

    if (attribute.id === 'time') {
      this.report.applyDateRange(filterValues);
      this.reportingService.updateRange(filterValues);
      return;
    }

    this.report.applyFilter(index, attribute, filterValues, operator);
  }

  @action
  clearFilters() {
    this.report.dateRange = null;
    this.report.filters = {};
  }

  @action
  applyLogicalFilterOperator(operator: LogicalFilterOperator) {
    this.report.applyLogicalFilterOperator(operator);
  }

  get range() {
    let reportingRange = this.reportingService.range as Range | null;
    if (this.args.renderTimezones && isPresent(reportingRange) && reportingRange !== null) {
      if (reportingRange.selectedRange === 'custom') {
        return Range.createFromParams(reportingRange.start, reportingRange.end, this.timezone);
      }
      return Range.createFromPreset(reportingRange.selectedRange, this.timezone);
    }
    return reportingRange;
  }

  get oldKeyForFilterStorage() {
    return `filters_for_${this.reportId}`;
  }

  get keyForFilterStorage() {
    return `reporting:${this.appService.app.id}:filters:${this.reportId}`;
  }

  getFiltersFromLocalStorage() {
    let key = this.keyForFilterStorage;
    let value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
  }

  getFiltersFromLocalStorageAndMigrate() {
    try {
      let filters = this.getFiltersFromLocalStorage();
      if (!filters) {
        filters = this.getOldFiltersFromLocalStorageAndMigrate();
      }
      return filters;
    } catch (error) {
      console.error(error);
      captureException(error);
    }
  }

  getOldFiltersFromLocalStorageAndMigrate() {
    let key = this.oldKeyForFilterStorage;
    let value = localStorage.getItem(key);
    if (value) {
      let filters = JSON.parse(value);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'migrated_filters',
        reportId: this.reportId,
      });
      return this.filterMigrationService.migrateUnsupportedFilters(
        filters,
        this.filterableMetricProperties,
      );
    } else {
      return null;
    }
  }

  saveFiltersToLocalStorage(filters: TopLevelFilter) {
    let key = this.keyForFilterStorage;
    if (filters) {
      localStorage.setItem(key, JSON.stringify(filters));
    } else {
      localStorage.removeItem(key);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::StaticReport': typeof StaticReport;
    'reporting/custom/report/static-report': typeof StaticReport;
  }
}
