/* import __COLOCATED_TEMPLATE__ from './share.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type NativeArray from '@ember/array/-private/native-array';
import { A } from '@ember/array';
import { first, indexBy, isEmpty } from 'underscore';
import type Admin from 'embercom/models/admin';
import { action } from '@ember/object';
import type Report from 'embercom/models/reporting/custom/report';
import type ReportAccessService from 'embercom/services/report-access-service';
import { TrackedObject } from 'tracked-built-ins';
import { dropTask, task } from 'ember-concurrency-decorators';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import { taskFor } from 'ember-concurrency-ts';
import { AccessType } from 'embercom/services/report-access-service';
import { cached } from 'tracked-toolbox';

interface Args {
  report: Report;
  disabled?: boolean;
}

const SEARCH_LIMIT = 5;
const WORKSPACE_ID = '0';

export interface AccessControl {
  admin: Admin;
  accessType: AccessType | null;
}

export interface AccessOption {
  text: string;
  description: string;
  accessType: AccessType | null;
}

interface SelectedValue {
  text: string;
  description: string;
  accessType: AccessType | null;
}
export default class ReportingCustomReportShare extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare reportAccessService: ReportAccessService;

  @tracked showModal = false;
  @tracked selectedTeammates: NativeArray<Admin> = A([]);
  @tracked userInput = '';
  @tracked accessControlList: AccessControl[] = A([]);

  @tracked declare selectedValue: string;
  @tracked selectedAccessType: AccessType | null = AccessType.EDIT;

  indexedAvailableAdmins = indexBy(this.availableTeammates, 'id');

  makeAccessControlOwner(reportOwner: Admin): AccessControl {
    return this.makeLocalAccessControl(reportOwner, AccessType.EDIT);
  }

  makeWorkspaceAccessControl(accessType: AccessType | null): AccessControl {
    let admin: Admin = new TrackedObject({
      id: WORKSPACE_ID,
      name: this.appService.app.name,
      isNotHuman: true,
      avatarData: {
        color: this.appService.app.base_color,
        isCustomer: false,
        isCompany: true,
        isTeam: false,
      },
    }) as Admin;
    return this.makeLocalAccessControl(admin, accessType);
  }

  makeLocalAccessControl(admin: Admin, accessType: AccessType | null): AccessControl {
    return new TrackedObject({
      admin,
      accessType,
    });
  }

  @action
  permissionHasConflict(admin: Admin, accessType: AccessType | null) {
    if (admin.isNotHuman) {
      return false;
    }
    let adminPermission = admin.currentAppPermissions;

    if (
      accessType &&
      [AccessType.VIEW, AccessType.RESTRICTED_VIEW].includes(accessType) &&
      !adminPermission.can_access_reporting
    ) {
      return true;
    } else if (accessType === AccessType.EDIT && !adminPermission.can_reporting__reports__update) {
      return true;
    }
    return false;
  }

  @action
  isCreator(admin: Admin) {
    return admin.id === this.args.report.createdById && admin.isHuman;
  }

  get tableData() {
    return this.accessControlList.map((value, index) => {
      return { ...value, index };
    });
  }

  get selectedTeammatesId() {
    return new Set(this.selectedTeammates.map((teammate) => teammate.id));
  }

  get invitedAdminIds() {
    return new Set(this.accessControlList.map((accessControl) => accessControl.admin.id));
  }

  @cached
  get currentAdmin() {
    return this.appService.app.currentAdmin;
  }

  get availableTeammates() {
    let selectedTeammatesId = this.selectedTeammatesId;
    let invitedAdminIds = this.invitedAdminIds;
    return this.store
      .peekAll('admin')
      .filter((admin) => admin.isHuman)
      .reject(
        (admin) =>
          selectedTeammatesId.has(admin.id) || admin.is_me || invitedAdminIds.has(admin.id),
      ); // we don't want to share with ourselve, admin selected for sharing or someone we already shared with :-)
  }

  get suggestedTeammates() {
    if (isEmpty(this.userInput)) {
      return this.availableTeammates.slice(0, SEARCH_LIMIT);
    }
    return this.availableTeammates
      .filter((admin: Admin) => this.compareWithoutAccents(admin.name, this.userInput))
      .slice(0, SEARCH_LIMIT);
  }

  private removeAccents(name: string) {
    // normalize("NFD"): This breaks the accented characters into their base characters and the diacritical marks.
    // .replace(/[\u0300-\u036f]/g, ""): This removes the diacritical marks by targeting the Unicode range for diacritics.
    return name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private compareWithoutAccents(value: string, target: string) {
    let cleanedValue = value.toLowerCase();
    let cleanedTarget = target.toLowerCase();
    return this.removeAccents(cleanedValue).includes(this.removeAccents(cleanedTarget));
  }

  get firstSuggestTeammate() {
    return this.selectedTeammates[0];
  }

  @action
  selectTeammate(teammate?: Admin) {
    // avoid adding empty teammates
    if (teammate) {
      this.selectedTeammates.addObject(teammate);
    }
  }

  get hasSuggestedTeammates() {
    return this.suggestedTeammates.length > 0;
  }

  @action
  clearUserInput() {
    this.userInput = '';
  }

  @action
  runSearch(e: InputEvent & { target: HTMLInputElement }) {
    this.userInput = e.target.value;
  }

  get columns() {
    return [
      {
        label: this.intl.t('reporting.custom-reports.report.share-modal.who-has-access'),
        valuePath: 'admin.name',
        type: 'avatar-with-text',
        isSortable: false,
      },
      {
        label: this.intl.t('reporting.custom-reports.report.share-modal.access'),
        valuePath: 'accessType',
        isSortable: false,
      },
    ];
  }

  @action
  closeModalAndReset() {
    this.clearUserInput();
    this.selectedTeammates.clear();
    this.showModal = false;
    this.selectedAccessType = AccessType.EDIT;
  }

  get placeHolder() {
    return isEmpty(this.selectedTeammates)
      ? this.intl.t('reporting.custom-reports.report.share-modal.invite-others-by-name')
      : undefined;
  }

  @action
  handleBackspace() {
    if (isEmpty(this.userInput)) {
      this.selectedTeammates = this.selectedTeammates.slice(0, this.selectedTeammates.length - 1);
    }
  }

  @action
  handleInput(
    popover: { hide: () => void; show: () => void },
    e: InputEvent & { target: HTMLInputElement },
  ) {
    this.userInput = e.target.value;
    if (isEmpty(this.userInput)) {
      popover.hide();
    } else {
      popover.show();
    }
  }

  @action
  handleEnter(popover: { hide: () => void; show: () => void }) {
    let selectedTeamate = first(this.suggestedTeammates);
    if (selectedTeamate) {
      this.selectTeammate(selectedTeamate);
      this.clearUserInput();
      popover.hide();
    }
  }

  @action
  handleDelete(selectedTeammate: Admin) {
    this.selectedTeammates = this.selectedTeammates.reject(
      (teammate) => teammate.id === selectedTeammate.id,
    );
  }

  @action
  changeAccess(row: AccessControl & { index: number }, selectedValue: SelectedValue) {
    let controlToChange = this.accessControlList[row.index];
    controlToChange.accessType = selectedValue.accessType;

    if (row.admin.isHuman && selectedValue.accessType) {
      taskFor(this.reportAccessService.updateTeammateAccessType).perform(
        this.args.report,
        row.admin.id,
        selectedValue.accessType,
      );
    } else {
      taskFor(this.reportAccessService.updateOrCreateWorkSpaceAccess).perform(
        this.args.report,
        selectedValue.accessType,
      );
    }
  }

  @action
  sendInvites() {
    let invitedTeammates = this.selectedTeammates;

    this.accessControlList.addObjects(
      invitedTeammates.map((teammate) =>
        this.makeLocalAccessControl(teammate, this.selectedAccessType),
      ),
    );

    this.reportAccessService.sendInvites(
      this.args.report.id,
      this.selectedAccessType ?? AccessType.VIEW,
      invitedTeammates.map((admin) => admin.id),
    );

    this.selectedTeammates.clear();
  }

  @action
  setShareAccessType(value: AccessOption) {
    this.selectedAccessType = value.accessType;
  }

  @task({ drop: true })
  *loadAccessControlList() {
    let reportOwner: Admin | null = this.args.report.isIntercomOwnedReport
      ? null
      : this.store.peekRecord('admin', this.args.report.createdById);

    let accessControlList: ReportAccessControlList[] = yield taskFor(
      this.reportAccessService.fetchReportAccess,
    ).perform(this.args.report.id);

    let controls = accessControlList
      .map((control: ReportAccessControlList) => {
        if (control.adminId && control.adminId !== reportOwner?.id) {
          return new TrackedObject({
            admin: this.indexedAvailableAdmins[control.adminId] ?? this.currentAdmin,
            accessType: control.accessType,
          });
        } else {
          return null;
        }
      })
      .compact();

    let workspaceAccessControl = accessControlList.find((control) => control.adminId === null);
    let sortedControls = controls.sort((a, b) => a.admin.name.localeCompare(b.admin.name));

    if (reportOwner) {
      this.accessControlList = [
        this.makeWorkspaceAccessControl(workspaceAccessControl?.accessType ?? null),
        this.makeAccessControlOwner(reportOwner),
        ...sortedControls,
      ];
    } else {
      this.accessControlList = [
        this.makeWorkspaceAccessControl(workspaceAccessControl?.accessType ?? null),
        ...sortedControls,
      ];
    }
  }

  loadAccessControlTask = taskFor(this.loadAccessControlList);

  get disableSuggestion() {
    // we don't want to suggested teammates when we are loading invited teammates or when app has no teammates
    return !this.hasSuggestedTeammates || this.loadAccessControlTask.isRunning;
  }

  @action
  handleModalOpen() {
    this.showModal = true;
    this.loadAccessControlTask.perform();
  }

  @dropTask
  *removeAccess(admin: Admin) {
    let option = this.accessControlList.find((control) => control.admin.id === admin.id);

    if (option) {
      this.accessControlList.removeObject(option);
    }

    let accessControlList: ReportAccessControlList[] = yield taskFor(
      this.reportAccessService.fetchReportAccess,
    ).perform(this.args.report.id); // we need to refresh this list

    let accessOption = accessControlList.find((reportAccess) => reportAccess.adminId === admin.id);

    if (accessOption) {
      yield this.reportAccessService.removeAccess(accessOption);
      yield;
    }
  }

  @action
  getAdminName(selectedAdmin: Admin) {
    if (this.isCreator(selectedAdmin) && this.currentAdmin.id === selectedAdmin.id) {
      // display "YOU" only when logged-in user is creator
      return this.intl.t('reporting.custom-reports.report.share-modal.you');
    } else {
      return selectedAdmin.name;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::Share': typeof ReportingCustomReportShare;
    'reporting/custom/report/share': typeof ReportingCustomReportShare;
  }
}
