/* import __COLOCATED_TEMPLATE__ from './teammate-list.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { cached } from 'tracked-toolbox';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { difference, every, isEqual, isEmpty, omit } from 'underscore';
import {
  NO_FILTER,
  WITHOUT_FILTER_VALUE,
  STATUS_FILTER_TYPE,
  SEAT_FILTER_TYPE,
  ROLE_FILTER_TYPE,
  TEAM_FILTER_TYPE,
  LAST_ACTIVE_FILTER_TYPE,
  COPILOT_FILTER_TYPE,
} from 'embercom/lib/settings/filters/constants';
import TeammatesCsvExporter from 'embercom/lib/teammates/csv-exporter';
import { A } from '@ember/array';
import { COPILOT_SEAT_TYPE as COPILOT } from 'embercom/lib/settings/seats/constants';

const ROW_PAGE_SIZE = 5;
const BOTTOM_SCROLL_PADDING = 200;
const APPROX_ROW_HEIGHT = 65.0;

export default class TeammateList extends Component {
  @service router;
  @service permissionsService;
  @service appService;
  @service intercomEventService;
  @service store;
  @service csv;
  @service intl;
  @service customerService;
  @service copilotUsageService;

  @tracked searchTerm = '';
  @tracked debouncedSearchTerm = '';
  @tracked seatsUsed = {};
  @tracked selectedFilters = this.startingFilter;
  @tracked maxTeammateRowsToRender = this.initialRowsToRender;
  @tracked selectedActiveDateFilterType = 'for';

  @tracked sortState = { valuePath: 'name', direction: 'asc' };
  @tracked selectedTeammateIds = A();

  scrollThrottleTime = ENV.APP._50MS;
  lastScrollOffset = 0;
  isSecondaryWorkspace = this.customerService.customer.isAnnualContractSecondarySubscription;

  constructor() {
    super(...arguments);
    this.loadSeatUsage.perform();
    if (this.copilotIsEnabled) {
      this.copilotUsageService.refreshCopilotUsageData();
    }
  }

  get initialRowsToRender() {
    let scrollContainerHeight = document.querySelector(this.args.scrollSelector).offsetHeight;
    return Math.ceil(scrollContainerHeight / APPROX_ROW_HEIGHT) + ROW_PAGE_SIZE;
  }

  get workspaceHasRoles() {
    return this.appService.app.hasRoles;
  }

  get teammateListColumns() {
    let roleLabel = this.workspaceHasRoles
      ? this.intl.t('settings.teammate-list.role')
      : this.intl.t('settings.teammate-list.permission');

    let seatLabel = this.appService.app.onPricing5
      ? this.intl.t('settings.teammate-list.seat')
      : this.intl.t('settings.teammate-list.seats');

    let baseColumns = [
      {
        label: this.intl.t('settings.teammate-list.name'),
        valuePath: 'name',
        width: '30%',
        type: 'avatar-with-text',
        isSortable: true,
      },
      { label: this.intl.t('settings.teammate-list.status'), valuePath: 'status', type: 'stamp' },
      {
        label: seatLabel,
        valuePath: 'seats',
        hasTooltipBlock: true,
      },
      { label: roleLabel, valuePath: 'role' },
      { label: this.intl.t('settings.teammate-list.teams'), valuePath: 'teams' },
      {
        label: this.intl.t('settings.teammate-list.two-f-a'),
        valuePath: '2fa',
        tooltip: this.intl.t('settings.teammate-list.two-factor-authentication'),
      },
    ];

    if (this.appService.app.canSeeLastSeenDatesOnTeammateList) {
      baseColumns.splice(5, 0, {
        label: this.intl.t('settings.teammate-list.last-active'),
        valuePath: 'last_active',
        isSortable: true,
      });
    }

    if (this.appService.app.canUseFinAiCopilotAddon) {
      baseColumns.splice(3, 0, {
        label: this.intl.t('settings.teammate-list.fin-ai-copilot'),
        valuePath: 'copilot',
        isSortable: false,
        hasTooltipBlock: true,
        isTooltipInteractive: true,
      });
    }

    baseColumns.splice(baseColumns.length, 0, {
      label: '',
      valuePath: 'sendRecoveryCodes',
      canExpandToFit: false,
    });

    return baseColumns;
  }

  @cached
  get teammateToTeamMap() {
    return this.appService.app.teamsWithoutAppTeam.reduce((acc, team) => {
      team.member_ids.forEach((memberId) => {
        acc[memberId] = acc[memberId] || [];
        acc[memberId].push(team);
      });
      return acc;
    }, {});
  }

  get copilotIsEnabled() {
    return this.appService.app.canUseFinAiCopilotAddon && this.appService.app.hasCopilotEnabled;
  }

  get startingFilter() {
    if (this.args.selectedFilterType) {
      return { [this.args.selectedFilterType]: [this.args.selectedFilterValue] };
    }
    return {};
  }

  get hasFilter() {
    return Object.keys(this.selectedFilters).length > 0;
  }

  get hasSelectedTeammates() {
    return this.selectedTeammates.length > 0;
  }

  get selectedTeammates() {
    return this.teammatesToDisplay.filter((data) => data.isSelected);
  }

  get allTeammatesSelected() {
    return this.teammatesToDisplay.every(
      (data) => data.isSelected || this.appService.app.currentAdmin === data.teammate,
    );
  }

  @cached
  get allSortedTeammates() {
    let teammates = this.args.teammates;

    if (this.sortState.valuePath) {
      teammates = teammates.sortBy(this.sortState.valuePath);
      if (this.sortState.direction === 'desc') {
        teammates = teammates.reverse();
      }
    }

    return teammates.map((teammate) => {
      return {
        teammate,
        teams: this.calculateTeams(teammate),
        isCurrentAdmin: this.appService.app.currentAdmin === teammate,
        isSelected:
          this.selectedTeammateIds.includes(teammate.id) &&
          this.appService.app.currentAdmin !== teammate,
      };
    });
  }

  @cached
  get teammatesToDisplay() {
    return isPresent(this.debouncedSearchTerm) ? this.searchedTeammates : this.filteredTeammates;
  }

  @cached
  get filteredTeammates() {
    let updatedTeammates = this.allSortedTeammates;
    // eslint-disable-next-line guard-for-in
    for (let type in this.selectedFilters) {
      switch (type) {
        case ROLE_FILTER_TYPE:
          updatedTeammates = this.processRoleFilter(type, updatedTeammates);
          break;
        case SEAT_FILTER_TYPE:
          updatedTeammates = this.processSeatFilter(type, updatedTeammates);
          break;
        case STATUS_FILTER_TYPE:
          updatedTeammates = this.processStatusFilter(type, updatedTeammates);
          break;
        case TEAM_FILTER_TYPE:
          updatedTeammates = this.processTeamFilter(type, updatedTeammates);
          break;
        case LAST_ACTIVE_FILTER_TYPE:
          updatedTeammates = this.processLastActiveFilter(type, updatedTeammates);
          break;
        case COPILOT_FILTER_TYPE:
          updatedTeammates = this.processFinCopilotFilter(type, updatedTeammates);
          break;
      }
    }
    return updatedTeammates;
  }

  @cached
  get searchedTeammates() {
    let downcasedSearchTerm = this.debouncedSearchTerm.toLocaleLowerCase();
    return this.filteredTeammates.filter(({ teammate }) => {
      return (
        teammate.name.toLocaleLowerCase().includes(downcasedSearchTerm) ||
        teammate.email.includes(downcasedSearchTerm)
      );
    });
  }

  @cached
  get paginatedTeammatesToDisplay() {
    return this.teammatesToDisplay.slice(0, this.maxTeammateRowsToRender);
  }

  get canLoadMoreRows() {
    return this.paginatedTeammatesToDisplay.length < this.teammatesToDisplay.length;
  }

  get shouldLoadMoreRows() {
    let allRows = document.querySelectorAll('.settings__teammate__teammate-list tr').values();
    let lastRenderedRow = Array.from(allRows).slice(-1)[0];

    if (lastRenderedRow) {
      let lastRenderedRowBounds = lastRenderedRow.getBoundingClientRect();
      return lastRenderedRowBounds.y - BOTTOM_SCROLL_PADDING <= window.innerHeight;
    } else {
      return false;
    }
  }

  get editRoute() {
    return this.args.newSettings
      ? 'apps.app.settings.workspace.teammates.teammate.permissions'
      : 'apps.app.settings.teammates.teammate.permissions';
  }

  get selectedFilterList() {
    if (isEmpty(this.selectedFilters)) {
      return null;
    }

    return Object.keys(this.selectedFilters);
  }

  seatsToDisplay(app, teammateSeats) {
    return teammateSeats.filter((seat) => seat !== COPILOT || app.canUseFinAiCopilotAddon);
  }

  @task
  *loadSeatUsage() {
    this.seatsUsed = yield get('/ember/settings/seats/used_and_pending_seat_count', {
      app_id: this.appService.app.id,
    });
  }

  calculateTeams(teammate) {
    let id = parseInt(teammate.id, 10);
    return this.teammateToTeamMap[id] || [];
  }

  resetPagination() {
    this.maxTeammateRowsToRender = this.initialRowsToRender;
  }

  updateSearchTerm() {
    this.debouncedSearchTerm = this.searchTerm;
    this.resetPagination();
  }

  @action
  onScroll(scrollOffset) {
    let isScrollingDown = this.lastScrollOffset < scrollOffset;

    if (isScrollingDown && this.canLoadMoreRows && this.shouldLoadMoreRows) {
      this.maxTeammateRowsToRender += ROW_PAGE_SIZE;
    }
    this.lastScrollOffset = scrollOffset;
  }

  @action
  bulkEditPermissions() {
    if (this.selectedTeammates.length === 1) {
      // There is no point in doing bulk edit permissions if only single teammate is selected,
      // so lets render the edit permissions page for this single admin
      this.router.transitionTo(
        this.args.newSettings
          ? 'apps.app.settings.workspace.teammates.teammate.permissions'
          : 'apps.app.settings.teammates.teammate.permissions',
        this.selectedTeammates.firstObject.teammate.id,
      );
    } else {
      let transition = this.router.transitionTo(
        this.args.newSettings
          ? 'apps.app.settings.workspace.teammates.permissions'
          : 'apps.app.settings.teammates.permissions',
      );
      transition.data.teammates = this.selectedTeammates.map((teammateObj) => teammateObj.teammate);
      if (this.appService.app.canUseBulkEditSeats) {
        this._fireBulkEditSeatsAnalyticsEvent();
      }
    }
  }

  @action
  clearFilters() {
    this.selectedFilters = {};
    this.debouncedSearchTerm = this.searchTerm = '';
  }

  @action
  onChangeSearchTerm() {
    debounce(this, this.updateSearchTerm, ENV.APP._250MS);
  }

  @action
  onSelectionChanged(row) {
    if (this.selectedTeammateIds.includes(row.teammate.id)) {
      this.selectedTeammateIds.removeObject(row.teammate.id);
    } else {
      this.selectedTeammateIds.pushObject(row.teammate.id);
    }
  }

  @action
  selectAll() {
    this.teammatesToDisplay.forEach((data) => {
      if (
        this.selectedTeammateIds.includes(data.teammate.id) ||
        this.appService.app.currentAdmin === data.teammate
      ) {
        return;
      }

      this.selectedTeammateIds.pushObject(data.teammate.id);
    });
  }

  @action
  clearSelection() {
    this.teammatesToDisplay.forEach((data) => {
      this.selectedTeammateIds.removeObject(data.teammate.id);
    });
  }

  @action
  onChangeFilterValue(type, value) {
    if (value.includes(NO_FILTER)) {
      this.selectedFilters = omit(this.selectedFilters, type);
    } else {
      this.selectedFilters = { ...this.selectedFilters, [type]: value };
    }
    this.resetPagination();
  }

  @action
  changeLastActiveDateFilter(lastActiveTimestamp) {
    if (lastActiveTimestamp) {
      this.onChangeFilterValue(LAST_ACTIVE_FILTER_TYPE, [lastActiveTimestamp]);
    } else {
      this.onChangeFilterValue(LAST_ACTIVE_FILTER_TYPE, [NO_FILTER]);
    }
  }

  @action
  exportTeammates() {
    let exporter = new TeammatesCsvExporter(this.appService.app, this.teammatesToDisplay, {
      showMultipleSeats: true,
    });

    this.csv.export(exporter.data, {
      fileName: exporter.filename,
      withSeparator: false,
    });
  }

  @action
  bulkRemoveTeammates() {
    if (this.selectedTeammates.length === 1) {
      // There is no point in doing bulk edit permissions if only single teammate is selected,
      // lets render edit permissions page for this single admin
      this.router.transitionTo(
        'apps.app.settings.teammates.teammate.remove',
        this.selectedTeammates.firstObject.teammate.id,
      );
    } else {
      let transition = this.router.transitionTo('apps.app.settings.teammates.remove');
      transition.data.teammates = this.selectedTeammates.map((teammateObj) => teammateObj.teammate);
    }
  }

  @action
  onSort(valuePath) {
    let direction = 'asc';
    if (this.sortState.valuePath === valuePath && this.sortState.direction === 'asc') {
      direction = 'desc';
    }

    this.sortState = { valuePath, direction };
  }

  get statusFilter() {
    return this.selectedFilters[STATUS_FILTER_TYPE] || [NO_FILTER];
  }

  get seatsFilter() {
    return this.selectedFilters[SEAT_FILTER_TYPE] || [NO_FILTER];
  }

  get roleFilter() {
    return this.selectedFilters[ROLE_FILTER_TYPE] || [NO_FILTER];
  }

  get teamFilter() {
    return this.selectedFilters[TEAM_FILTER_TYPE] || [NO_FILTER];
  }

  get finCopilotFilter() {
    return this.selectedFilters[COPILOT_FILTER_TYPE] || [NO_FILTER];
  }

  processRoleFilter(type, updatedTeammates) {
    return updatedTeammates.filter((teammateObj) =>
      this.selectedFilters[type].includes(
        String(teammateObj.teammate.currentAppPermissions?.role_id),
      ),
    );
  }

  processSeatFilter(type, updatedTeammates) {
    let seatFilters = this.selectedFilters[type];
    if (isEqual(seatFilters, [WITHOUT_FILTER_VALUE])) {
      return updatedTeammates.filter((teammateObj) => isEmpty(teammateObj.teammate.seats));
    } else {
      return updatedTeammates.filter((teammateObj) =>
        every(seatFilters, (seatFilter) => teammateObj.teammate.seats.includes(seatFilter)),
      );
    }
  }

  processStatusFilter(type, updatedTeammates) {
    return updatedTeammates.filter((teammateObj) =>
      this.teammateStatusMatches(teammateObj.teammate, this.selectedFilters[type]),
    );
  }

  processTeamFilter(type, updatedTeammates) {
    let teamFilters = this.selectedFilters[type];
    if (isEqual(teamFilters, [WITHOUT_FILTER_VALUE])) {
      return updatedTeammates.filter((teammateObj) => teammateObj.teams.length <= 0);
    } else {
      return updatedTeammates.filter(({ teams }) => {
        let teammateTeamIds = teams.map((team) => team.id);
        return isEmpty(difference(teamFilters, teammateTeamIds));
      });
    }
  }

  processLastActiveFilter(type, updatedTeammates) {
    let lastActiveTimestamp = this.selectedFilters[type][0];

    if (lastActiveTimestamp) {
      return updatedTeammates.filter(({ teammate }) => {
        return !teammate.last_active || lastActiveTimestamp > teammate.last_active;
      });
    }
  }

  processFinCopilotFilter(type, updatedTeammates) {
    return updatedTeammates.filter((teammateObj) =>
      this.copilotAccessMatches(teammateObj.teammate, this.selectedFilters[type]),
    );
  }

  teammateStatusMatches(teammate, statusFilter) {
    return statusFilter.some((filter) => {
      if (filter === 'active') {
        return !teammate.away_mode_enabled && !teammate.reassign_conversations;
      } else if (filter === 'away') {
        return teammate.away_mode_enabled && !teammate.reassign_conversations;
      } else {
        return teammate.away_mode_enabled && teammate.reassign_conversations;
      }
    });
  }

  copilotAccessMatches(teammate, copilotFilter) {
    return copilotFilter.some((filter) => {
      return teammate.copilotAccessType === filter;
    });
  }

  _fireBulkEditSeatsAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'bulk_edit_seats_edit_button',
    });
  }
}
