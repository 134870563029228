/* import __COLOCATED_TEMPLATE__ from './edit-nav-create-folder.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  item: any;
}
interface Signature {
  Args: Arguments;
}
const EditNavCreateFolder = templateOnlyComponent<Signature>();
export default EditNavCreateFolder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::EditNavCreateFolder': typeof EditNavCreateFolder;
    'reporting/nav-submenu-components/edit-nav-create-folder': typeof EditNavCreateFolder;
  }
}
