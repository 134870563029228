/* import __COLOCATED_TEMPLATE__ from './teammate-performance-report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { filterableProperties as defaultFilterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import PALETTE from '@intercom/pulse/lib/palette';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

const DEFAULT_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: null,
  metric_property: null,
  metric_function: null,
  date_range: {
    property: 'comment_at',
    selection: 'past_12_weeks',
    start: null,
    end: null,
  },
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10000,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

export default class TeammatePerformanceReport extends Component {
  @service store;
  @service intl;
  @service appService;
  @service reportingMetrics;
  @service router;

  get app() {
    return this.appService.app;
  }

  getMetricIdVariant(metricId) {
    let { excludeOooHours } = this.args.settings;
    if (excludeOooHours) {
      return metricId;
    }
    let metric = this.reportingMetrics.getMetricById(metricId);
    let metricFamily = this.reportingMetrics.getMetricsByFamilyId(metric.metricFamilyId);

    return metric.metricFamilyDefault
      ? metricFamily.find((metric) => !metric.metricFamilyDefault && !metric.legacyDurationMetric)
          .id
      : metricFamily.find((metric) => metric.metricFamilyDefault).id;
  }

  get reportDefinition() {
    return {
      id: `teammate_performance_report`,
      title: null,
      description: null,
      charts: this.charts,
      date_range: null,
      filters: {
        type: 'and',
        filters: [
          {
            type: 'category',
            data: {
              attribute: 'teammate',
              property: 'teammate',
              values: [REPORTING_FILTER_SELECT_ALL],
            },
          },
        ],
      },
    };
  }

  get filterableProperties() {
    return defaultFilterableProperties().concat('teammate');
  }

  get filterableMetricProperties() {
    return this.reportingMetrics.getPropertiesByIds(this.filterableProperties);
  }

  get firstResponseTimeMetric() {
    let { excludeOooHours } = this.args.settings;
    let metric_id = excludeOooHours
      ? 'v2.first_response_time.bot_inbox_excluded.in_office_hours'
      : 'v2.first_response_time.bot_inbox_excluded';
    return {
      metric_id,
      aggregation: 'median',
    };
  }

  getTooltip(path) {
    return this.intl.t(`${path}-new`);
  }

  get charts() {
    return [
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_teammate_first_response_time',
        tooltip: this.getTooltip(
          'reporting.teammate-performance.teammate-first-response-time-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        should_render_chrome: true,
        chart_series: [
          {
            metric_id: this.getMetricIdVariant('v2.teammate.first_response_time.in_office_hours'),
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_teammate_subsequent_response_time',
        tooltip: this.getTooltip(
          'reporting.teammate-performance.teammate-subsequent-response-time-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        should_render_chrome: true,
        chart_series: [
          {
            metric_id: this.getMetricIdVariant(
              'v2.teammate.subsequent_response_time.in_office_hours',
            ),
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_teammate_handling_time',
        title: this.intl.t('reporting.teammate-performance.teammate-handling-time-chart-title'),
        tooltip: this.intl.t(
          'reporting.teammate-performance.teammate-handling-time-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        should_render_chrome: true,
        chart_series: [
          {
            metric_id: this.getMetricIdVariant('conversations.handling_time.in_office_hours'),
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_teammate_time_to_close',
        tooltip: this.getTooltip(
          'reporting.teammate-performance.teammate-time-to-close-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        should_render_chrome: true,
        chart_series: [
          {
            metric_id: this.getMetricIdVariant('v2.teammate.time_to_close.in_office_hours'),
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_report_conversations_assigned_per_hour',
        title: this.intl.t(
          'reporting.teammate-performance.conversations-assigned-per-hour-chart-title-new',
        ),
        tooltip: this.intl.t(
          'reporting.teammate-performance.conversations-assigned-per-hour-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'teammate.conversations_assigned_per_hour_active',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_report_conversations_replied_to_per_hour',
        title: this.intl.t(
          'reporting.teammate-performance.conversations-replied-to-per-hour-chart-title-new',
        ),
        tooltip: this.intl.t(
          'reporting.teammate-performance.conversations-replied-to-per-hour-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'teammate.conversations_replied_to_per_hour_active',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_report_conversations_closed_per_hour',
        title: this.intl.t(
          'reporting.teammate-performance.conversations-closed-per-hour-chart-title-new',
        ),
        tooltip: this.intl.t(
          'reporting.teammate-performance.conversations-closed-per-hour-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'teammate.conversations_closed_per_hour_active',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_report_multi-metric_bar_chart',
        title: this.intl.t('reporting.teammate-performance.teammate-multi-metric-bar-chart-title'),
        visualization_type: VISUALIZATION_TYPES.BAR,
        view_by: 'time',
        width_type: WIDTH_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'teammate.conversations_assigned_per_hour_active',
            color: PALETTE['vis-yellow-50'],
          },
          {
            metric_id: 'teammate.conversations_replied_to_per_hour_active',
            color: PALETTE['vis-blue-50'],
          },
          {
            metric_id: 'teammate.conversations_closed_per_hour_active',
            color: PALETTE['vis-mint-50'],
          },
        ],
        footer_cta_component:
          'reporting/reports/teammate-performance-report/multi-metric-bar-chart-cta',
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_report_csat_score',
        title: this.intl.t('reporting.teammate-performance.csat-counter-chart-title'),
        tooltip: this.intl.t('reporting.teammate-performance.csat-counter-chart-description'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'v2.csat',
          },
        ],
        footer_text: 'reporting.teammate-performance.csat-counter-chart-footer',
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_report_conversation_rate',
        title: this.intl.t('reporting.teammate-performance.conversations-rated-chart-title'),
        tooltip: this.intl.t(
          'reporting.teammate-performance.conversations-rated-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER,
        view_by: this.app.canSeeCSATZeroPointFiveReporting
          ? 'rating_index'
          : 'conversation_rating.rating_index',
        width_type: WIDTH_TYPES.THREE_COLUMN,
        height_type: HEIGHT_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'conversations.rated.count',
          },
        ],
        stacked: true,
        visualization_options: {
          show_relative_values: true,
        },
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_performance_overview',
        title: this.intl.t(
          'reporting.teammate-performance.teammate-performance-overview-chart-title',
        ),
        tooltip: this.intl.t(
          'reporting.teammate-performance.teammate-performance-overview-chart-tooltip',
          {
            url: this.router.urlFor('apps.app.messenger.settings', this.appService.app.id, {
              queryParams: { tab: 'general', section: 'office-hours' },
            }),
            htmlSafe: true,
          },
        ),
        visualization_type: VISUALIZATION_TYPES.TABLE,
        view_by: 'teammate',
        width_type: WIDTH_TYPES.FULL,
        number_rows_visible: 10,
        show_default_chart_title: false,
        should_render_chrome: true,
        visualization_options: {
          show_summary_row: true,
        },
        chart_series: [
          {
            metric_id: 'conversations.assigned.count',
          },
          {
            metric_id: 'conversations.participated.count',
          },
          {
            metric_id: 'v1.replies_sent',
          },
          {
            metric_id: 'conversations.closed.exclude_bot.count',
          },
          {
            metric_id: 'v2.csat',
          },
          {
            metric_id: 'conversations.sla_miss_rate',
          },
          this.firstResponseTimeMetric,
          {
            metric_id: this.getMetricIdVariant('v2.teammate.first_response_time.in_office_hours'),
            aggregation: 'median',
          },
          {
            metric_id: this.getMetricIdVariant(
              'v2.teammate.subsequent_response_time.in_office_hours',
            ),
            aggregation: 'median',
          },
          {
            metric_id: this.getMetricIdVariant('v2.teammate.time_to_close.in_office_hours'),
            aggregation: 'median',
          },
          {
            metric_id: this.getMetricIdVariant('conversations.handling_time.in_office_hours'),
            aggregation: 'median',
          },

          {
            metric_id: 'notes.created.bot_exclude.count',
          },
          {
            metric_id: 'mentions.count',
          },
          {
            metric_id: 'teammate.conversations_assigned_per_hour_active',
          },
          {
            metric_id: 'teammate.conversations_replied_to_per_hour_active',
          },
          {
            metric_id: 'teammate.conversations_closed_per_hour_active',
          },
        ].compact(),
      },
    ];
  }
}
