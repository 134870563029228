/* import __COLOCATED_TEMPLATE__ from './get-started.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class GetStarted extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;

  @tracked showModal = false;

  domainProviders = [
    {
      name: this.intl.t('settings.domains.manage.get-started.domain-providers.aws'),
      link: 'https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/resource-record-sets-creating.html',
    },
    {
      name: this.intl.t('settings.domains.manage.get-started.domain-providers.azure'),
      link: 'https://azure.microsoft.com/en-us/blog/a-records-cname-and-using-dns-with-windows-azure-web-sites-waws',
    },
    {
      name: this.intl.t('settings.domains.manage.get-started.domain-providers.cloudflare'),
      link: 'https://developers.cloudflare.com/dns/manage-dns-records/how-to/create-dns-records',
    },
    {
      name: this.intl.t('settings.domains.manage.get-started.domain-providers.go-daddy'),
      link: 'https://ie.godaddy.com/help/add-a-cname-record-19236',
    },
    {
      name: this.intl.t('settings.domains.manage.get-started.domain-providers.google-domains'),
      link: 'https://support.google.com/domains/answer/3290350#zippy=%2Cadd-a-resource-record',
    },
  ];

  get app() {
    return this.appService.app;
  }

  get domainProviderOptions() {
    return [
      {
        items: this.domainProviders.map((provider) => ({
          text: provider.name,
          onSelectItem: () => {
            this.openInstructionsLink(provider);
          },
        })),
      },
    ];
  }

  get domainSettingsInstructionsText() {
    let message = [];

    message.push(
      this.intl.t('settings.domains.manage.get-started.instructions-modal.intro', {
        value: this.args.customBounceSettings.rootDomain,
        htmlSafe: true,
      }),
    );

    message.push(
      this.intl.t(
        'settings.domains.manage.get-started.instructions-modal.cname-host-value-record',
        {
          host: `${this.args.dkimRecord.domainForCustomerDnsRecord}.`,
          value: `${this.args.dkimRecord.domainForIntercomDnsRecord}.`,
          htmlSafe: true,
        },
      ),
    );

    message.push(
      this.intl.t(
        'settings.domains.manage.get-started.instructions-modal.cname-host-value-record',
        {
          host: `${this.args.customBounceSettings.host}.`,
          value: `${this.args.customBounceSettings.value}.`,
          htmlSafe: true,
        },
      ),
    );

    message.push(
      this.intl.t('settings.domains.manage.get-started.instructions-modal.txt-host-value-record', {
        host: `_dmarc.${this.args.dkimRecord.domain}.`,
        value: `v=DMARC1; p=none`,
        htmlSafe: false,
      }),
    );

    message.push(
      this.intl.t('settings.domains.manage.get-started.instructions-modal.can-you-help'),
    );

    return message.join('\n');
  }

  @action openInstructionsLink(provider) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      section: 'settings_email_domains',
      object: `${provider.name.toLowerCase()}_instructions_link`,
    });

    return safeWindowOpen(provider.link);
  }
}
