/* import __COLOCATED_TEMPLATE__ from './stacked-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import SerieschartBuilder from 'embercom/lib/reporting/flexible/serieschart-builder';
import HighchartsDataBuilder from 'embercom/lib/reporting/flexible/highcharts-data-builder';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';
import { getOwner } from '@ember/application';

export default class StackedColumnchart extends Component {
  @service intl;
  @service intercomEventService;
  @service appService;

  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
    renderableChart: this.args.renderableChart,
  }));

  @cached
  get chartData() {
    try {
      return new HighchartsDataBuilder(
        getOwner(this),
        this.args.dataConfig,
        this.args.viewConfig,
      ).forStackedChartResponse(this.dataResource.rawChartData);
    } catch (e) {
      console.error(e);
      this.dataResource.notifyError();
      return [];
    }
  }

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.args.viewConfig,
      dataConfig: this.args.dataConfig,
      app: this.appService.app,
      chartHeight: this.args.chartHeight,
    };

    if (!options.viewConfig.columnChart?.stacking) {
      options.viewConfig.showLegendInTooltips = true;
    }

    let builder = new SerieschartBuilder(options);
    return builder.buildTheme();
  }

  get hasData() {
    return this.chartData.some((singleSeries) => {
      return singleSeries.data.some((dataArray) => dataArray[1]);
    });
  }

  get showEmptyState() {
    return this.args.isPaywalled || !this.hasData;
  }
}
