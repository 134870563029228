/* import __COLOCATED_TEMPLATE__ from './chart-grid-layout.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type Chart from 'embercom/models/reporting/custom/chart';
import { inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Report from 'embercom/models/reporting/custom/report';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
import { type Filter } from '../filters';
import { indexBy } from 'underscore';
import generateUUID from 'embercom/lib/uuid-generator';

interface ReportState {
  settings: string;
  dateRange: Range;
  filters: Filter;
  timezone: string;
}

interface GridItem {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

interface Args {
  charts: Chart[];
  reportState: ReportState;
  report: Report;
  deleteChart: () => void;
  editChart: () => void;
  duplicateChart: () => void;
  reorderCharts: () => void;
  saveReport: () => void;
  trackAnalyticsEvent: () => void;
  editMode: boolean;
  paywall: Component;
  isTemplate?: boolean;
  actionsAreDisabled?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class ChartGridLayout extends Component<Signature> {
  @service declare reportingChartService: ReportingChartService;
  @service appService: any;
  @service store: any;
  @service declare intercomEventService: any;

  @tracked showPropagationFinishedBanner = false;
  newCharts: string[] = [];
  gridId: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.gridId = generateUUID(); // we can render multiple grids on the same page, so we need a unique id
    this.newCharts = args.charts.filterBy('isNew').mapBy('id');
  }

  resetColors = modifier(() => {
    this.reportingChartService.resetColors();
  });

  get gridIsStatic() {
    return !this.args.editMode;
  }

  @action
  updateShowPropagationFinishedBanner() {
    this.showPropagationFinishedBanner = true;
  }

  @action
  reloadPage() {
    window.location.reload();
  }

  @action
  onGridChange(_: any, items: GridItem[]) {
    items.forEach((item) => {
      let chart = this.args.charts.find((chart) => chart.id === item.id);
      if (!chart) {
        return;
      }
      chart.gridPositionX = item.x;
      chart.gridPositionY = item.y;
      chart.gridWidth = item.w;
      chart.gridHeight = item.h;
    });
  }

  @action
  onGridAdded(_: any, items: GridItem[]) {
    let chartId = items[0].id;
    let indexedCharts = indexBy(this.args.charts.toArray(), 'id');
    let chartToUpdate = indexedCharts[chartId];
    if (
      this.newCharts.includes(chartId) ||
      chartToUpdate.get('isNew') ||
      (!chartToUpdate.gridPositionX && !chartToUpdate.gridPositionY)
    ) {
      let item = items[0];
      chartToUpdate.updateChartPosition(item.x, item.y);
      if (this.args.isTemplate) {
        return;
      }
      let chartElement = document.getElementById(`grid-item-${chartId}`);
      if (!chartElement) {
        return;
      }
      chartElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  @action
  onChartResized(_: any, el: any) {
    let chart = this.store.peekRecord('reporting/custom/chart', el.gridstackNode.id);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'resized',
      object: 'reporting_chart',
      chartId: el.gridstackNode.id,
      gridWidth: el.gridstackNode.w,
      gridHeight: el.gridstackNode.h,
      visType: chart?.visualizationType,
    });
  }

  @action
  onChartMoved(_: any, el: any) {
    let chart = this.store.peekRecord('reporting/custom/chart', el.gridstackNode.id);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'moved',
      object: 'reporting_chart',
      chartId: el.gridstackNode.id,
      gridX: el.gridstackNode.x,
      gridY: el.gridstackNode.y,
      visType: chart?.visualizationType,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ChartGridLayout': typeof ChartGridLayout;
    'reporting/custom/report/chart-grid-layout': typeof ChartGridLayout;
  }
}
