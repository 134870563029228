/* import __COLOCATED_TEMPLATE__ from './set-office-hours.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly, map, filterBy, or } from '@ember/object/computed';
import { findBy, findByProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  tagName: '',
  appService: service(),
  store: service(),
  app: readOnly('appService.app'),

  showModal: false,
  newSchedule: null,
  savedSchedules: filterBy('schedules', 'isNew', false),
  defaultSchedule: findBy('schedules', 'isDefault'),
  dropdownSchedules: map('savedSchedules', function (schedule) {
    return this.getDropdownItemFromSchedule(schedule);
  }),
  selectedScheduleId: or('teamSettings.officeHoursScheduleId', 'defaultSchedule.id'),
  selectedSchedule: findByProperty('schedules', 'id', 'selectedScheduleId'),

  getDropdownItemFromSchedule(schedule) {
    return {
      value: schedule.id,
      text: schedule.name,
      description: schedule.labelWithTimezone,
    };
  },

  actions: {
    onSelectItem(scheduleId) {
      this.set('teamSettings.officeHoursScheduleId', scheduleId);
    },

    onModalSave(schedule) {
      this.dropdownSchedules.pushObject(this.getDropdownItemFromSchedule(schedule));
      this.set('teamSettings.officeHoursScheduleId', schedule.id);
    },

    openNewScheduleModal() {
      this.set('newSchedule', this.store.createRecord('office-hours-schedule'));
      this.set('showModal', true);
    },
  },
});
