/* import __COLOCATED_TEMPLATE__ from './ticket-type-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { AVAILABLE_EMOJI } from 'embercom/lib/available-emoji';
import shuffle from '@intercom/pulse/lib/shuffle';
import { action } from '@ember/object';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { isPresent } from '@ember/utils';
import { TicketCategory } from '../../../objects/inbox/ticket';
import type Store from '@ember-data/store';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type TicketType from 'embercom/models/inbox/ticket-type';
import type GuideLibraryService from 'embercom/services/guide-library-service';

const DEFAULT_INTERNAL = false;
const DEFAULT_AI_ASSIST_DISABLED = false;

interface Args {
  app: $TSFixMe;
  ticketTypeToEdit: TicketType;
  category: TicketCategory;
  onModalClose: () => void;
  archiveTicketType: () => void;
  onSetDependentObjects: (dependentObjects: $TSFixMe) => void;
}

export default class TicketTypeModal extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare appService: any;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked editMode = isPresent(this.args.ticketTypeToEdit);
  @tracked name = this.editMode ? this.args.ticketTypeToEdit.name : '';
  @tracked description = this.editMode ? this.args.ticketTypeToEdit.description : '';
  @tracked emoji = this.editMode ? this.args.ticketTypeToEdit.emoji : this._randomEmoji();
  @tracked internal =
    this.editMode && this.args.ticketTypeToEdit.internal !== undefined
      ? this.args.ticketTypeToEdit.internal
      : this.defaultInternal;

  @tracked disableAiAssist =
    this.editMode && this.args.ticketTypeToEdit.disableAiAssist !== undefined
      ? this.args.ticketTypeToEdit.disableAiAssist
      : DEFAULT_AI_ASSIST_DISABLED;

  @tracked category =
    (this.editMode && this.args.ticketTypeToEdit.category) ||
    this.args.category ||
    this.defaultCategory;

  get defaultCategory() {
    return TicketCategory.Request;
  }

  get updateTicketTypeIsRunning() {
    return taskFor(this.updateTicketType).isRunning;
  }

  get updateTicketTypeIsIdle() {
    return taskFor(this.updateTicketType).isIdle;
  }

  get defaultInternal() {
    if (this.category === TicketCategory.Tracker) {
      return true;
    }

    if (!this.editMode && this.category === TicketCategory.Task) {
      return true;
    }

    return DEFAULT_INTERNAL;
  }

  get modalTitle() {
    if (this.updateTicketTypeIsRunning) {
      return this.intl.t('settings.ticket-data.ticket-type-modal.dependency-objects-checking');
    } else if (this.editMode) {
      return this.intl.t('settings.ticket-data.ticket-type-modal.edit-title');
    }
    return this.intl.t('settings.ticket-data.ticket-type-modal.create-title');
  }

  get isValid() {
    return this.name.trim() !== '';
  }

  get isNotArchived() {
    if (this.editMode) {
      return !this.args.ticketTypeToEdit.archived;
    } else {
      return false;
    }
  }

  @action selectEmoji(emojiIdentifier: string) {
    let unicodeEmoji = intermoji.unicodeFromIdentifier(emojiIdentifier);
    let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
    this.emoji = twemojiCompatibleEmoji;
  }

  @action onCategorySelectionChange(value: TicketCategory) {
    this.category = value;
    if (this.category === TicketCategory.Request) {
      this.internal = false;
      return;
    }

    if (this.category === TicketCategory.Tracker) {
      this.internal = true;
      return;
    }

    // If we are creating a new ticket type and the category is task, we default to internal
    if (!this.editMode && this.category === TicketCategory.Task) {
      this.internal = true;
      return;
    } else {
      this.internal = false;
      return;
    }
  }

  @action
  saveTicketType() {
    if (this.editMode) {
      taskFor(this.updateTicketType).perform();
    } else {
      taskFor(this.createTicketType).perform();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        context: 'create-ticket-type',
        object: `create-ticket-type-cta`,
        section: 'settings.helpdesk.tickets.modal',
        place: 'settings.helpdesk.tickets',
      });
    }
  }

  get visibilitySettingsDisabled() {
    return this.category === TicketCategory.Request || this.category === TicketCategory.Tracker;
  }

  get visibilitySettingsDisabledReason() {
    let baseKey = 'settings.ticket-data.ticket-type-modal.visibility-settings-disabled-reason';
    return `${baseKey}.${this.category}`;
  }

  get hasVisibilitySettings() {
    return this.category === TicketCategory.Task;
  }

  @task({ drop: true })
  *createTicketType() {
    let ticketType = this.store.createRecord('inbox/ticket-type', {
      name: this.name,
      description: this.description,
      emoji: this.emoji,
      internal: this.internal,
      category: this.category,
      disableAiAssist: this.disableAiAssist,
    });

    try {
      yield ticketType.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-data.ticket-type-modal.successfully-created'),
      );
      this.args.onModalClose();
      let transitionUrl = 'apps.app.settings.ticket-data.detail';
      if (this.appService.app.canSeeNewIASettingsHub) {
        transitionUrl = 'apps.app.settings.helpdesk.tickets.ticket-types.detail';
      }
      yield this.router.transitionTo(transitionUrl, ticketType.id).finally(async () => {
        if (this.guideLibraryService.canUseGuideLibraryService) {
          await this.guideLibraryService.markStepCompleted('display_only_setup_your_tickets');
        }
      });
    } catch (error) {
      this.store.unloadRecord(ticketType);
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.ticket-type-modal.ticket-type-name-duplicate'),
        );
      } else if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.ticket-type-modal.unknown-error'),
        );
      }
    }
  }

  @task({ drop: true })
  *updateTicketType() {
    let ticketType = this.args.ticketTypeToEdit;

    try {
      ticketType.setProperties({
        name: this.name,
        description: this.description,
        emoji: this.emoji,
        internal: this.internal,
        category: this.category,
        disableAiAssist: this.disableAiAssist,
      });

      yield ticketType.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-data.ticket-type-modal.successfully-updated'),
      );
      this.args.onModalClose();
      if (this.guideLibraryService.canUseGuideLibraryService) {
        yield this.guideLibraryService.markStepCompleted('display_only_setup_your_tickets');
      }
    } catch (error) {
      ticketType.rollbackAttributes();
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.ticket-type-modal.ticket-type-name-duplicate'),
        );
      } else if (error.jqXHR.status === 412) {
        let failedTypes = error.jqXHR.responseJSON.failed_types;
        let dependentObjects = error.jqXHR.responseJSON.dependencies;

        this.args.onSetDependentObjects({
          customBots: failedTypes.includes('custom_bots')
            ? dependentObjects.dependent_custom_bots
            : [],
          ticketLinks: failedTypes.includes('ticket_links')
            ? dependentObjects.dependent_ticket_links
            : [],
        });
      } else if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.ticket-type-modal.unknown-error'),
        );
      }
    }
  }

  _randomEmoji() {
    return shuffle(AVAILABLE_EMOJI)[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::TicketTypeModal': typeof TicketTypeModal;
    'settings/ticket-types/ticket-type-modal': typeof TicketTypeModal;
  }
}
