/* import __COLOCATED_TEMPLATE__ from './filter-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  DYNAMIC_FILTER_TYPES,
  filterComponentForAttributeType,
  TIME_FILTER_IDENTIFIER,
  FilterLogicalOperators,
} from 'embercom/lib/reporting/custom/filter-helpers';
import { cached } from 'tracked-toolbox';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import TicketType from 'embercom/models/inbox/ticket-type';
import { indexBy, pick } from 'underscore';
import { ANSWER_TO_QUESTION_IDENTIFIER } from 'embercom/lib/reporting/survey-helpers';
import {
  type Attribute as DatasetAttribute,
  CONVERSATION_ATTRIBUTES,
  CUSTOM_COMPANY_ATTRIBUTE,
  CUSTOM_USER_ATTRIBUTE,
  SYSTEM_DEFINED_ATTRIBUTES,
  TICKETS_ATTRIBUTES,
} from 'embercom/objects/reporting/unified/datasets/types';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import type IntlService from 'ember-intl/services/intl';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type {
  MetricProperty,
  MetricPropertyDefinition,
} from 'embercom/objects/reporting/unified/properties/types';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type Range from 'embercom/models/reporting/range';
import type MutableArray from '@ember/array/mutable';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { type SafeString } from 'handlebars';
import {
  type BarFilter,
  type LogicalBarFilter,
  type LogicalFilterOperator,
} from 'embercom/components/reporting/custom/filters';
import type Registry from '@glint/environment-ember-loose/registry';
import { type DropDownItem, groupDropDownItems } from '../chart-builder-helpers';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';

interface FilterConfig {
  component: string;
  selectorLabel: string;
  icon: string;
  tooltipText: string | SafeString;
  attributeType: string;
  type: string;
  labelPrefix: string | null;
  selectAllLabel: string | null;
  isVariant: boolean;
  disableMultiple: boolean;
  field: string;
  category?: string | null;
  group?: string | null;
  reference?: {
    type: string;
    id: string;
  };
}

interface FilterComponentConfig {
  component: keyof Registry; // string name of an existing component
  type: string;
  isOpen: boolean;
  name: string;
  icon: string;
  attributeType: string;
  sticky: boolean;
  labelPrefix: string | null;
  selectAllLabel: string | null;
  index: number;
}

interface GroupedDropDownItem {
  heading?: SafeString | string | null;
  items: DropDownItem[];
}

export interface StickyFilter {
  id: string;
}

interface Signature {
  Args: {
    barFilters: LogicalBarFilter;
    chartConfig: RenderableChart;
    range: Range | null;
    onFiltersChanged: (
      index: number,
      propertyId: string,
      filterValues: any,
      operator: any,
      chartSeries: ChartSeries | null,
    ) => void;
    onFiltersCleared: (chartSeries: ChartSeries | null) => void;
    filterableMetricProperties: (MetricPropertyDefinition | DatasetAttribute | MetricProperty)[];
    conversationAttributeDescriptors: MutableArray<ConversationAttributeDescriptor>;
    customFilterArgs: $TSFixMe;
    isDisabled: boolean;
    analyticsObject: $TSFixMe;
    applyLogicalFilterOperator: (operator: LogicalFilterOperator) => void;
    stickyFilterTypes: StickyFilter[];
    advancedFiltersEnabled: boolean;
    isAddFilterDisabled: boolean;
    loadKnownValuesSources: string[];
    timezone: string | null;
    chartSeries: ChartSeries | null;
    chartSeriesIndex: number | null;
  };
}

export default class FilterBar extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare attributeService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare intercomEventService: $TSFixMe;
  @tracked filterTypeAdded: string | null = null;
  @tracked openFilterIndex: number | null = null;

  andOperator = FilterLogicalOperators.and;
  orOperator = FilterLogicalOperators.or;

  get labelForFilterType() {
    return this.filterType === FilterLogicalOperators.and
      ? this.intl.t('reporting.filter-bar.and')
      : this.intl.t('reporting.filter-bar.or');
  }

  get filterType() {
    return this.args.barFilters.type;
  }

  get addedFilterTypes() {
    let filters = this.initialFiltersAdded;
    if (this.filterTypeAdded) {
      filters.push(this.filterTypeAdded);
    }
    return filters;
  }

  get conversationAttributeDescriptors() {
    return this.args.conversationAttributeDescriptors || [];
  }

  @cached
  get ticketTypes(): MutableArray<TicketType> {
    return TicketType.peekAllAndMaybeLoad() || [];
  }

  getComponentNameForConversationCDA(dataType: string) {
    if (dataType === 'integer' || dataType === 'decimal') {
      return 'number';
    }

    if (this.args.advancedFiltersEnabled && dataType === 'string') {
      return 'regex-string';
    }
    return dataType;
  }

  getComponentNameForUserCompanyCDA(dataType: string) {
    if (this.args.advancedFiltersEnabled && dataType === 'string') {
      return 'regex-string';
    }

    if (this.args.advancedFiltersEnabled && (dataType === 'float' || dataType === 'integer')) {
      return 'number';
    }

    return dataType === 'boolean' ? 'boolean' : 'string';
  }

  get availableFilterConfigsFromAttributes() {
    let filterIds = (this.args.filterableMetricProperties as DatasetAttribute[]).map(
      ({ id }) => id,
    );
    return pick(this.allFilterConfigsFromAttributes, filterIds);
  }

  @cached
  get allFilterConfigsFromAttributes(): Record<string, FilterConfig> {
    // Ticket parents come with a type ticket, we need to exclude them.
    let configs = Object.values(this.reportingMetrics.metricProperties as DatasetAttribute[])
      .reject(
        (attribute) =>
          isEmpty(attribute.type) ||
          (attribute.type === TICKETS_ATTRIBUTES && attribute.group === TICKETS_ATTRIBUTES),
      )
      .map(
        ({
          id,
          field,
          type,
          name,
          filterTooltip,
          icon,
          filterLabelPrefix,
          filterSelectAllLabel,
          variantOf,
          disableMultiple,
          group,
          headerName,
        }) => {
          let component;
          if (
            group === CONVERSATION_ATTRIBUTES ||
            group === TICKETS_ATTRIBUTES ||
            group === SYSTEM_DEFINED_ATTRIBUTES
          ) {
            component = `reporting/custom/chart-builder/filter-bar/custom-attributes/${this.getComponentNameForConversationCDA(
              type!,
            )}-filter`;
          } else if (group === CUSTOM_USER_ATTRIBUTE || group === CUSTOM_COMPANY_ATTRIBUTE) {
            component = `reporting/custom/chart-builder/filter-bar/custom-attributes/${this.getComponentNameForUserCompanyCDA(
              type!,
            )}-filter`;
          } else {
            component = filterComponentForAttributeType(type!);
          }

          let configName = isPresent(variantOf) && headerName ? headerName : name;
          return {
            component,
            selectorLabel: configName,
            icon,
            tooltipText: filterTooltip ? sanitizeHtml(filterTooltip) : name,
            attributeType: 'string',
            type: id,
            labelPrefix: filterLabelPrefix || name,
            selectAllLabel: filterSelectAllLabel || null,
            isVariant: isPresent(variantOf),
            disableMultiple,
            field,
            category: group,
            group,
          };
        },
      );

    return indexBy(configs, 'type');
  }

  @cached
  get supportedFiltersConfig(): Record<string, FilterConfig> {
    let filters = this.availableFilterConfigsFromAttributes;

    if (!this.appService.app.canAssignToTeamAndTeammate) {
      delete filters.admin_assignee_id;
      delete filters.team_assignee_id;
    }

    if (!this.appService.app.canUseTopicFilter) {
      delete filters.topic;
    }

    return filters;
  }

  @cached
  get allFilterConfigs(): Record<string, FilterConfig> {
    return this.allFilterConfigsFromAttributes;
  }

  get suggestedProperties(): string[] {
    let metric = this.args.chartConfig?.chartSeries?.firstObject.metric;
    let suggestedAttributeIds = metric?.suggestedAttributeIds || [];
    // Filters don't yet support attribute ids so we work with properties
    let suggestedProperties = suggestedAttributeIds.map(
      (attributeId: string) => this.reportingMetrics.getAttributeById(attributeId).field,
    );
    let teammateProperties = [];
    if (metric?.teammateProperties && Object.keys(metric?.teammateProperties).length > 0) {
      teammateProperties = Object.values(metric?.teammateProperties);
    } else {
      teammateProperties = [metric?.teammateProperty];
    }
    let suggested = [...suggestedProperties, ...teammateProperties, metric?.teamProperty].compact();

    if (this.args.chartConfig && suggested !== undefined) {
      return suggested;
    }
    return [];
  }

  _getAttributesFromGroup(groupedAttributes: { [index: string]: DropDownItem[] }, group: string) {
    return groupedAttributes[group] || [];
  }

  get groupedFiltersForAttributes(): GroupedDropDownItem[] {
    let groupedProperties = groupDropDownItems(
      this.supportedFilters,
      this.args.filterableMetricProperties as DatasetAttribute[],
      this.intl,
    );

    let suggestedProperties = this.supportedFilters.filter((p) =>
      this.suggestedProperties.includes(p.value),
    );

    return [
      {
        heading: this.intl.t('app.lib.reporting.custom.filter-headings.suggested-data'),
        items: suggestedProperties,
      },
      ...groupedProperties,
    ];
  }

  get groupedFilters(): GroupedDropDownItem[] {
    return this.groupedFiltersForAttributes;
  }

  // filters available for selection when user clicks on "+ Add filter" button
  get supportedFilters(): DropDownItem[] {
    //ignore any non-supported filter type
    let filters = Object.values(this.supportedFiltersConfig)
      .map((config) => {
        // don't allow multiple filters of the same type if the config disables it
        if (
          config.isVariant ||
          (this.multipleOfSameFilterDisabled(config) && this.filterExists(config.type))
        ) {
          return null;
        }

        return {
          text: config.selectorLabel,
          value: config.type,
          tooltipText: config.tooltipText,
          icon: config.icon,
          reference: config.reference,
          category: config.category,
          field: config.field,
          group: config.group,
        } as DropDownItem;
      })
      .compact();

    return filters;
  }

  multipleOfSameFilterDisabled(config: FilterConfig) {
    if (this.args.advancedFiltersEnabled) {
      return config.disableMultiple;
    }

    return true;
  }

  filterExists(filterId: string) {
    if (DYNAMIC_FILTER_TYPES.includes(filterId)) {
      return this.addedFilterTypes.some((type) => type.startsWith(filterId));
    }

    let filterAndVariants = [filterId, ...this.filterVariants(filterId)];

    return this.addedFilterTypes.some((type) => filterAndVariants.includes(type));
  }

  filterVariants(filterId: string) {
    if (!this.reportingMetrics.propertyExists(filterId)) {
      return [];
    }

    let property = this.reportingMetrics.getPropertyById(filterId);
    return this.reportingMetrics.getPropertyVariants(property).map((variant) => variant.id);
  }

  // filters added to the page
  get enabledFilters(): FilterComponentConfig[] {
    let filters: FilterComponentConfig[] = this.allFilters
      .map(({ id, index }) => {
        let filterConfig = this.allFilterConfigs[id] || this._dynamicFilterTypeConfig(id);
        return filterConfig ? this._enabledFilterComponentConfig(filterConfig, id, index) : null;
      })
      .compact(); //ignore any non-supported filters

    if (this.filterTypeAdded) {
      let id = this.filterTypeAdded;
      let filterConfig = this.allFilterConfigs[id] || this._dynamicFilterTypeConfig(id);
      let index = this.allFilters.length;
      filters.pushObject(
        this._enabledFilterComponentConfig(filterConfig, this.filterTypeAdded, index, true),
      );
    }

    return filters;
  }

  get stickyFilterTypes() {
    if (isBlank(this.args.stickyFilterTypes)) {
      return [];
    }

    let stickyFilterTypeIds = this.args.stickyFilterTypes.map((stickyFilter) => stickyFilter.id);

    return stickyFilterTypeIds;
  }

  get showClearFilterButton() {
    return this.enabledFilters.length > this.stickyFilterTypes.length && !this.args.isDisabled;
  }

  // all filters including custom attribute filters
  get allFilters(): BarFilter[] {
    return this.sortFilters(this.args.barFilters.filters);
  }

  sortFilters(filters: BarFilter[]): BarFilter[] {
    // Ensure sticky filters are shown in the order declared in stickyFilterTypes
    let sortedStickyFilters = this.stickyFilterTypes
      .map((stickyFilterType) =>
        filters.find((filter) => {
          if (filter.id.startsWith(ANSWER_TO_QUESTION_IDENTIFIER)) {
            return filter.id.startsWith(stickyFilterType);
          }
          return filter.id === stickyFilterType;
        }),
      )
      .compact();

    let nonStickyFilters = filters
      .filter((filter) => !this.isStickyFilterType(filter.id))
      .sort((filterA, filterB) => {
        if (filterA.id === TIME_FILTER_IDENTIFIER) {
          return -1;
        } else if (filterB.id === TIME_FILTER_IDENTIFIER) {
          return 1;
        } else {
          return 0;
        }
      });

    return [...sortedStickyFilters, ...nonStickyFilters];
  }

  isStickyFilterType(filterType: string) {
    let stickyFilter = this.stickyFilterTypes.find((stickyFilterType) => {
      if (filterType.startsWith(ANSWER_TO_QUESTION_IDENTIFIER)) {
        return filterType.startsWith(stickyFilterType);
      }
      return filterType === stickyFilterType;
    });

    return isPresent(stickyFilter);
  }

  get initialFiltersAdded() {
    return this.allFilters.map(({ id }) => id);
  }

  get indexOfLastFilter() {
    return this.enabledFilters.length - 1;
  }

  _enabledFilterComponentConfig(
    config: FilterConfig,
    id: string,
    index: number,
    isOpen = false,
  ): FilterComponentConfig {
    return {
      component: config.component as keyof Registry,
      type: id,
      isOpen,
      name: config.selectorLabel,
      icon: config.icon || 'transfer',
      attributeType: config.attributeType,
      sticky: this.stickyFilterTypes.includes(id),
      labelPrefix: config.labelPrefix,
      selectAllLabel: config.selectAllLabel,
      index,
    };
  }

  _dynamicFilterTypeConfig(filterType: string): FilterConfig | null {
    let filterId = DYNAMIC_FILTER_TYPES.find((filter) => filterType.startsWith(filter));
    return filterId ? this.allFilterConfigs[filterId] : null;
  }

  @action
  addFilter(filterType: string) {
    this.openFilterIndex = this.enabledFilters.length;
    this.filterTypeAdded = filterType;
  }

  @action
  clearFilters() {
    this.filterTypeAdded = null;
    this.openFilterIndex = null;
    this.args.onFiltersCleared(this.args.chartSeries);
  }

  @action
  removeFilter(index: number, filterType: string) {
    this.filterTypeAdded = null;
    this.openFilterIndex = null;
    this.args.onFiltersChanged(index, filterType, [], null, this.args.chartSeries);
  }

  @action
  onFiltersChanged(
    filterIndex: number,
    filterType: string,
    filterValues: any[],
    operator = 'category',
  ) {
    this.filterTypeAdded = null;
    this.openFilterIndex = null;
    this.args.onFiltersChanged(
      filterIndex,
      filterType,
      filterValues,
      operator,
      this.args.chartSeries,
    );
  }

  @action
  getSelectedFilterValue(filterIndex: number) {
    let filter = this.args.barFilters.filters.find(({ index }) => index === filterIndex);
    if (filter) {
      return filter;
    } else {
      let filterId = this.filterTypeAdded;

      if (filterId === TIME_FILTER_IDENTIFIER) {
        return this.args.range;
      }

      return {};
    }
  }

  @action
  getCustomFilterArgs(filterType: string) {
    if (!this.args.customFilterArgs) {
      return {};
    }
    let key = Object.keys(this.args.customFilterArgs).find((filterId) => {
      return filterType.startsWith(filterId);
    });
    return key ? this.args.customFilterArgs[key] || {} : {};
  }

  @action
  applyLogicalFilterOperator(operator: LogicalFilterOperator) {
    if (this.args.applyLogicalFilterOperator) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'changed_logical_operator',
        operator,
        object: this.args.analyticsObject,
        place: 'reporting',
      });

      this.args.applyLogicalFilterOperator(operator);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::FilterBar': typeof FilterBar;
    'reporting/custom/chart-builder/filter-bar/filter-bar': typeof FilterBar;
  }
}
