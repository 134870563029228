/* import __COLOCATED_TEMPLATE__ from './add-reports.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type NavigationFolder from 'embercom/models/reporting/navigation-folder';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

interface Args {
  folder: NavigationFolder;
  searchString?: string;
  onSearchInput: (value: string) => void;
}
export default class ReportingNavSubmenuComponentsSubMenuAddReports extends Component<Args> {
  @service declare intercomEventService: $TSFixMe;

  @tracked declare hoveredOn?: string;

  get folderReports() {
    if (isPresent(this.args.searchString)) {
      return this.args.folder.folderReports.filter((folderReport) =>
        folderReport.title.toLowerCase().includes(this.args.searchString!.toLowerCase()),
      );
    } else {
      return this.args.folder.folderReports;
    }
  }

  get hasReports() {
    return this.folderReports.length !== 0;
  }

  @action
  search(event: InputEvent) {
    let target = event.target as HTMLInputElement;
    this.args.onSearchInput(target.value);
  }

  @action
  reset() {
    this.hoveredOn = undefined;
  }

  @action
  isPinned(id: string) {
    return this.args.folder.isReportPinned(id);
  }

  @task({ enqueue: true })
  *togglePin(reportId: string) {
    let folder = this.args.folder;
    if (this.isPinned(reportId)) {
      yield folder.unpinReportFromFolder(reportId);
      this.trackAnalytics('unpin_report', reportId);
    } else {
      yield folder.pinReportToFolder(reportId);
      this.trackAnalytics('pin_report', reportId);
    }
  }

  trackAnalytics(action: string, reportId: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'reporting_navigation_folder',
      folder_id: this.args.folder.id,
      report_id: reportId,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::SubMenu::AddReports': typeof ReportingNavSubmenuComponentsSubMenuAddReports;
    'reporting/nav-submenu-components/sub-menu/add-reports': typeof ReportingNavSubmenuComponentsSubMenuAddReports;
  }
}
