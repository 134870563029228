/* import __COLOCATED_TEMPLATE__ from './copilot-access-editor.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  SUPPORT_SEAT_TYPE as SUPPORT,
  COPILOT_SEAT_TYPE as COPILOT,
  PRICING_5_X_FULL_SEAT_TYPE as FULL,
  VBP2_SEAT_LABELS_KEYS,
  COPILOT_SECTION_ID,
} from 'embercom/lib/settings/seats/constants';
import { PLAN_DATA, FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';
import { getWorkspaceSeatUsageAndPrices } from 'embercom/lib/admins/multiple-seats-info';
import {
  COPILOT_PERMISSION,
  NO_ACCESS,
  LIMITED_USAGE,
  UNLIMITED_USAGE,
} from 'embercom/lib/settings/copilot-access-editor/constants';
import mapSelectionToSeatAndPermission from 'embercom/lib/settings/copilot-access-editor/map-selection-to-seat-and-permission';
import { FIN_AI_COPILOT_ID } from 'embercom/lib/billing';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { trackedReset } from 'tracked-toolbox';
import type IntlService from 'ember-intl/services/intl';
import type Product from 'embercom/models/product';
import type Price from 'embercom/models/price';
import { taskFor } from 'ember-concurrency-ts';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type TaskGenerator } from 'ember-concurrency';
import type RouterService from '@ember/routing/router-service';

interface Args {
  onChange: () => void;
  toggleSeat: (seatType: string) => void;
  numberOfTeammates: number;
  isInvite: boolean;
  permissionsObject: any;
  openSections: { [key: string]: boolean };
  onOpenSectionChange: (key: string) => void;
  seatTypes: string[];
  disabledCopilotOptions: string[];
  displayWithoutSeatEditor: boolean;
}

interface AccessOptionItem {
  text: string;
  value: string;
  selectLabel?: string;
  requiresPaidSeat?: boolean;
  componentAttrs: { selectedValue: string; buttonDisabled: boolean };
  price?: string;
  disabled: boolean;
}

export default class CopilotAccessEditor extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;
  @service declare router: RouterService;

  @tracked copilotSeatUsages = { contractedLimit: 0, used: 0, pending: 0 };
  @tracked copilotPrice = 0;
  @tracked priceLoading = true;
  @tracked accordionState: string | null = COPILOT_SECTION_ID;
  @trackedReset('args.openSections') copilotAccessEditorId = this.sectionState;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadCopilotSeatUsageAndPrices).perform();
  }

  get app() {
    return this.appService.app;
  }

  get accessOptionItems(): AccessOptionItem[] {
    let componentAttrs = {
      selectedValue: this.accessType,
      buttonDisabled: false,
    };

    let optionItems = [
      {
        text: this.intl.t('components.settings.teammates.copilot-access-editor.no-access'),
        value: NO_ACCESS,
        componentAttrs,
        disabled:
          this._rolePreventsAccessLevel(NO_ACCESS) ||
          this.args.disabledCopilotOptions?.includes(NO_ACCESS),
      },
      {
        text: this.intl.t('components.settings.teammates.copilot-access-editor.free-limited-usage'),
        value: LIMITED_USAGE,
        componentAttrs,
        disabled:
          this._rolePreventsAccessLevel(LIMITED_USAGE) ||
          this.args.disabledCopilotOptions?.includes(LIMITED_USAGE),
      },
      {
        text: this.intl.t('components.settings.teammates.copilot-access-editor.unlimited-usage'),
        value: UNLIMITED_USAGE,
        requiresPaidSeat: true,
        componentAttrs,
        price: this._formatPriceFromCents(this.copilotPrice),
        disabled:
          this._rolePreventsAccessLevel(UNLIMITED_USAGE) ||
          this.args.disabledCopilotOptions?.includes(UNLIMITED_USAGE),
      },
    ];

    return optionItems;
  }

  get copilotOnTrial() {
    let product = this.app.products.find((product: Product) => product.id === FIN_AI_COPILOT_ID);

    return product?.isOnTrial;
  }

  get copilotDisabled() {
    return !this.app.hasCopilotEnabled;
  }

  get seatTagTranslationKey(): string {
    if (this.app.hasMultipleSeatTypes && !this.app.onPricing5) {
      return VBP2_SEAT_LABELS_KEYS[SUPPORT] ?? '';
    }

    if (this.app.canUsePerProductPricingFlow) {
      return 'settings.seats.inbox';
    }

    return 'settings.seats.full';
  }

  get copilotSeatsRemaining() {
    return this.copilotSeatUsages.contractedLimit - this.copilotSeatUsages.used;
  }

  get copilotSeatsRemainingIncludingPending() {
    return this.copilotSeatsRemaining - this.copilotSeatUsages.pending;
  }

  get showCopilotSeatsPending() {
    return this.copilotSeatsRemaining > 0 && this.copilotSeatsRemainingIncludingPending <= 0;
  }

  get selfServeAnnualCopilotTooltipURL() {
    return this.router.urlFor(
      'apps.app.settings.workspace.billing.manage-subscription',
      this.app.id,
      {
        queryParams: {
          section: 'seats',
        },
      },
    );
  }

  get salesLedCopilotLearnMoreURl() {
    return PLAN_DATA[FIN_AI_COPILOT_BASE_ID].articleLink;
  }

  get isP5SelfServeAnnualCustomer() {
    return this.customerService.isP5SelfServeAnnualCustomer;
  }

  get bulkEditingTeammates() {
    return !this.args.isInvite && this.args.numberOfTeammates > 1;
  }

  @keepLatestTask
  *loadCopilotSeatUsageAndPrices(): TaskGenerator<void> {
    let allSeatUsages: any = yield getWorkspaceSeatUsageAndPrices();
    this.copilotSeatUsages = allSeatUsages[COPILOT];
    yield this.customerService.bulkLoadPrices([
      {
        planIds: [parseInt(FIN_AI_COPILOT_BASE_ID, 10)],
      },
    ]);
    this.copilotPrice = this.customerService.prices.find((price: Price) => {
      return price.hasSamePlans([parseInt(FIN_AI_COPILOT_BASE_ID, 10)]);
    }).perUnitPrice;
    this.priceLoading = false;
  }

  _formatPriceFromCents(price: number, minimumFractionDigits = 0) {
    return this.intl.formatNumber(price / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
    });
  }

  _rolePreventsAccessLevel(accessLevel: string) {
    let role = this.args.permissionsObject?.role;

    // Directly return false if role is null or undefined
    if (role === null || role === undefined) {
      return false;
    }

    // Return true if the role does not have copilot access or if trying to set access level to NO_ACCESS
    return !role[COPILOT_PERMISSION] || accessLevel === NO_ACCESS;
  }

  get sectionState() {
    return this.args.openSections
      ? this.args.openSections[COPILOT_SECTION_ID] && COPILOT_SECTION_ID
      : this.accordionState;
  }

  get icon(): InterfaceIconName {
    return 'fin';
  }

  get sectionKey() {
    return COPILOT_SECTION_ID;
  }

  get isAdminPermissionWithIneligibleSeats() {
    return !this.hasSeatThatAllowsCopilot && !this.args.displayWithoutSeatEditor;
  }

  get accessType() {
    if (
      !this.args.permissionsObject?.[COPILOT_PERMISSION] ||
      this.isAdminPermissionWithIneligibleSeats
    ) {
      return NO_ACCESS;
    }

    return this.args.seatTypes?.includes(COPILOT) ? UNLIMITED_USAGE : LIMITED_USAGE;
  }

  get hasSeatThatAllowsCopilot() {
    if (this.app.hasMultipleSeatTypesPaywalled || this.app.hasMultipleSeatTypes) {
      return this.app.hasMultipleSeatTypes
        ? this.args.seatTypes?.includes(SUPPORT) || this.args.seatTypes?.includes(FULL)
        : true;
    }

    return !this.app.canUsePerProductPricingFlow || this.args.permissionsObject.has_inbox_access;
  }

  @action
  toggleCopilotAccess(selection: string) {
    mapSelectionToSeatAndPermission(
      selection,
      this.args.toggleSeat,
      this.args.permissionsObject,
      this.accessType,
    );
  }

  get getStampText() {
    switch (this.accessType) {
      case UNLIMITED_USAGE:
        return this.intl.t('components.settings.teammates.copilot-access-editor.unlimited-usage');
      case LIMITED_USAGE:
        return this.intl.t(
          'components.settings.teammates.copilot-access-editor.free-limited-usage',
        );
      default:
        return this.intl.t('components.settings.teammates.copilot-access-editor.no-access');
    }
  }

  get getStampColor() {
    switch (this.accessType) {
      case NO_ACCESS:
        return 'gray';
      case LIMITED_USAGE:
        return 'sky';
      default:
        return 'blue';
    }
  }

  @action
  onOpenSectionChange() {
    this.accordionState = this.accordionState ? null : COPILOT_SECTION_ID;
    if (this.args.onOpenSectionChange) {
      this.args.onOpenSectionChange(COPILOT_SECTION_ID);
    } else {
      this.copilotAccessEditorId = this.sectionState;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::CopilotAccessEditor': typeof CopilotAccessEditor;
    'settings/teammates/copilot-access-editor': typeof CopilotAccessEditor;
  }
}
