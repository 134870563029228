/* import __COLOCATED_TEMPLATE__ from './sla-table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

export default class SlaTable extends Component {
  @service appService;

  @tracked currentTab = 'live';
  @tracked showArchiveModal = false;
  @tracked showDeleteModal = false;
  @tracked slaToArchive = null;
  @tracked slaToDelete = null;
  @tracked slaInputToShow = -1;
  @tracked slaName = '';

  get app() {
    return this.appService.app;
  }

  get inboxRulesLink() {
    return 'apps.app.settings.inbox-rules.list';
  }

  get liveTabActive() {
    return this.currentTab === 'live';
  }

  get slaList() {
    return this.currentTab === 'live' ? this.liveSlaList : this.archivedSlaList;
  }

  get liveSlaList() {
    return this.rows.rejectBy('archived');
  }

  get archivedSlaList() {
    return this.rows.filterBy('archived');
  }

  get rows() {
    return this.slasWithOfficeHours;
  }

  get hasSlas() {
    return this.slasWithOfficeHours.length > 0;
  }

  get hasArchivedSlas() {
    return this.archivedSlaList.length > 0;
  }

  get nameExists() {
    return (
      this.rows
        .filter((sla) => sla.id !== this.slaInputToShow)
        .filterBy('name', this.slaName.trim()).length > 0
    );
  }

  get slasWithOfficeHours() {
    return this.args.slas.map((sla) => {
      if (sla.officeHoursSchedule === undefined) {
        sla.officeHoursSchedule = this.args.officeHoursSchedules.findBy(
          'id',
          sla.officeHoursScheduleId,
        );
      }

      return sla;
    });
  }

  get columns() {
    let columns = [];
    columns.push({
      label: 'Name',
      valuePath: 'name',
      isSortable: true,
      sortedAscending: true,
      width: '60%',
    });

    let dateLabel;
    let datePath;

    if (this.liveTabActive) {
      dateLabel = 'Created';
      datePath = 'createdAt';
    } else {
      dateLabel = 'Archived';
      datePath = 'archivedAt';
    }

    columns.push({
      label: dateLabel,
      valuePath: datePath,
      isSortable: true,
      sortedAscending: false,
    });

    return columns;
  }

  @action
  changeTab(tab) {
    this.currentTab = tab;
  }

  @action
  confirmArchiveSla(sla) {
    this.showArchiveModal = true;
    this.slaToArchive = sla;
  }

  @action
  confirmDeleteSla(sla) {
    this.showDeleteModal = true;
    this.slaToDelete = sla;
  }

  @action
  closeArchiveModal() {
    this.showArchiveModal = false;
    this.slaToArchive = null;
  }

  @action
  closeDeleteModal() {
    this.showDeleteModal = false;
    this.slaToDelete = null;
  }

  @action
  showSlaInputFor(sla) {
    this.slaInputToShow = sla.id;
    this.slaName = sla.name;
  }

  @task({ drop: true })
  *archiveSla() {
    let sla = this.slaToArchive;
    yield this.toggleArchiveState.perform(sla, true);
    this.closeArchiveModal();
  }

  @task({ drop: true })
  *restoreSla(sla) {
    yield this.toggleArchiveState.perform(sla, false);
    if (this.hasArchivedSlas === false) {
      this.changeTab('live');
    }
  }

  @task({ drop: true })
  *toggleArchiveState(sla, newState) {
    sla.archived = newState;
    yield sla.save();
  }

  @task({ drop: true })
  *deleteSla() {
    let sla = this.slaToDelete;
    yield sla.destroyRecord();
    this.closeDeleteModal();
    if (this.hasArchivedSlas === false) {
      this.changeTab('live');
    }
  }

  @task({ drop: true })
  *changeSlaName(sla, name) {
    sla.name = name.trim();
    yield sla.save();
    this.slaInputToShow = -1;
    this.slaName = '';
  }
}
