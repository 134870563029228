/* import __COLOCATED_TEMPLATE__ from './permissions-row-section.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { trackedReset } from 'tracked-toolbox';
import { action } from '@ember/object';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    titleTranslationKey: string;
    isSelectAllState: boolean;
    openSections: { [key: string]: boolean };
    sectionKey: string;
    unallowedPermissionList: [any];
    seatDependentPermissionList: any;
    disableToggleAllPermissionsButton: any;
    permissionSection: any;
    sectionState: 'complete' | 'incomplete' | 'none';
    stampState: 'complete' | 'incomplete' | 'none';
    onOpenSection: (sectionKey: string) => void;
    toggleSectionPermissions: any;
    withSeats: boolean;
    isDisabled: boolean;
    isDisabledByRole: boolean;
    updatePermission: (key: string, value?: boolean) => void;
    permissionsObject: any;
    changed: () => void;
  };
  Blocks: {
    default: [{ toggle?: any; custom?: any; crudcard?: any }];
  };
}

export default class PermissionsRowSection extends Component<Signature> {
  @service appService: $TSFixMe;
  @trackedReset('args.sectionState') isToggleAllChecked = this._isToggleAllChecked;
  @service intl: $TSFixMe;
  _accordionSectionState: string | null = this.args.sectionKey;
  @trackedReset('args.openSections') sectionId = this.accordionSectionState;

  get selectAllLabel() {
    return `${this.intl.t(`settings.permissions.${this.args.titleTranslationKey}`)} 
        ${this.intl.t('settings.teammates.permissions.access')}`;
  }

  get accordionSectionState() {
    return this.args.openSections
      ? this.args.openSections[this.args.sectionKey] && this.args.sectionKey
      : this._accordionSectionState;
  }

  get isOpen() {
    return (
      this.args.openSections && this.args.openSections[this.args.sectionKey] && this.args.sectionKey
    );
  }
  get hideToggleSection() {
    return (
      this.args.permissionSection?.children_ids?.length < 2 || this.args.sectionKey === 'reports'
    );
  }

  get isSectionDisabled() {
    if (!this.args.unallowedPermissionList) {
      // when "ignoreSeatDisabling" is set to true
      return this.args.isDisabledByRole;
    }
    let isDisabled = this.args.disableToggleAllPermissionsButton;
    // check if all permissions in section cannot be changed
    isDisabled ||= this.args.permissionSection.children_ids.every(
      (cid: string) =>
        -1 !== this.args.unallowedPermissionList.findIndex((a) => a === `can_${cid}`),
    );
    return this.args.isDisabledByRole || isDisabled;
  }

  get anyPermissionNotAvailable() {
    if (!this.args.unallowedPermissionList) {
      // when "ignoreSeatDisabling" is set to true
      return false;
    }
    return this.args.permissionSection.children_ids.any(
      (cid: string) =>
        -1 !== this.args.unallowedPermissionList.findIndex((a) => a === `can_${cid}`),
    );
  }

  get _isToggleAllChecked() {
    return this.args.sectionState === 'complete';
  }

  get isToggleAllIndeterminate() {
    return this.args.sectionState === 'incomplete';
  }

  get getStampText() {
    switch (this.args.stampState) {
      case 'complete':
        return this.intl.t('settings.teammates.permissions.full-access-lower');
      case 'incomplete':
        return this.intl.t('settings.teammates.permissions.custom-access');
      case 'none':
        return this.intl.t('settings.teammates.permissions.no-access');
    }
  }

  get getStampColor() {
    switch (this.args.stampState) {
      case 'complete':
        return 'sky';
      case 'incomplete':
        return 'blue';
      case 'none':
        return 'gray';
    }
  }

  get icon(): InterfaceIconName | null {
    switch (this.args.sectionKey) {
      case 'settings':
        return 'settings';
      case 'data_and_security':
        return 'locked';
      case 'apps_and_integrations':
        return 'app-store';
      case 'articles':
        return 'article';
      case 'knowledge':
        return 'insights';
      case 'automation':
        return 'auto-message';
      case 'outbound':
        return 'outbound-filled';
      case 'inbox':
        return 'inbox';
      case 'reports':
        return 'bar-charts';
      default:
        return null;
    }
  }

  @action
  onOpenSectionChange(sectionKey: string) {
    this._accordionSectionState = this._accordionSectionState ? null : this.args.sectionKey;
    if (this.args.onOpenSection) {
      this.args.onOpenSection(sectionKey);
    }
    if (!this.args.openSections) {
      // in case there is no external state forced, we update our state
      this.sectionId = this.accordionSectionState;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Permissions::PermissionsRowSection': typeof PermissionsRowSection;
    'settings/teammates/permissions/permissions-row-section': typeof PermissionsRowSection;
  }
}
