/* import __COLOCATED_TEMPLATE__ from './first-assignment-time-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  MEDIAN,
  MEAN,
  MIN,
  MAX,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  ASSIGNMENT_BEFORE_FIRST_ADMIN_REPLY_TIME,
} from 'embercom/lib/reporting/flexible/constants';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { botInboxAndOfficeHoursField } from 'embercom/lib/reporting/office-hours-field';
import { inject as service } from '@ember/service';

export default class FirstAssignmentTimeCounter extends Component {
  @service appService;
  @service intl;
  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.seconds };
    viewConfig.aggregations = { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        isComparison: true,
        createTitleTooltipText: (aggregation) => {
          let defaultTooltip = this.intl.t(
            'components.reporting.reports.effectiveness.first-assignment-time-counter.tooltip',
            { selectedAggregation: aggregation },
          );
          let officeHoursTooltip = this.intl.t(
            'components.reporting.flexible.chart-header-custom-tooltip.office-hours-tooltip.tooltip-description',
            { includeOfficeHours: this.args.settings.reportingExcludeOooHours },
          );
          return this.intl.t(
            'components.reporting.reports.effectiveness.office-hours.tooltip-with-office-hours',
            { defaultTooltip, officeHoursTooltip, htmlSafe: true },
          );
        },
      },
    };
    viewConfig.counter = {
      first_assignment_time_counter: {
        improvementDirection: improvementDirections.DECREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      series: [
        {
          name: 'first_assignment_time_counter',
          source: 'conversation_with_message_thread',
          type: MEDIAN,
          data: {
            property: this.aggregationProperty,
          },
          filter: this.appService.app.canUseEffectivenessMetrics
            ? {
                type: 'exists',
                data: {
                  property: this.aggregationProperty,
                },
              }
            : {
                type: 'range',
                data: {
                  operators: ['gt'],
                  property: this.aggregationProperty,
                  values: [0],
                },
              },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }

  get aggregationProperty() {
    return botInboxAndOfficeHoursField(
      ASSIGNMENT_BEFORE_FIRST_ADMIN_REPLY_TIME,
      this.args.settings.reportingExcludeOooHours,
    );
  }
}
