/* import __COLOCATED_TEMPLATE__ from './export-options-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Chart from 'embercom/models/reporting/custom/chart';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { restartableTask } from 'ember-concurrency-decorators';
import { type Task, type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import { buildFiltersForDataConfig } from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import type Report from 'embercom/models/reporting/custom/report';
import type ReportingCsvExport from 'embercom/services/reporting-csv-export';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import { type LogicalFilter } from 'embercom/components/reporting/custom/filters';
import { type DrillInResult } from 'embercom/services/reporting-underlying-data-service';
import type ReportingChartService from 'embercom/services/reporting-chart-service';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  chart: Chart;
  renderableChart: RenderableChart;
  report: Report | null;
  isReportLevel: boolean;
  eventPlace: string;
}

export default class ExportOptionsModal extends Component<Args> {
  @service declare appService: any;
  @service declare reportingCsvExport: ReportingCsvExport;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare permissionsService: any;
  @service declare intercomEventService: any;
  @service declare reportingChartService: ReportingChartService;

  @tracked exportType = null;
  @tracked showModal = true;
  @tracked totalHits = 0;
  @tracked sortDirection: 'asc' | 'desc' = 'desc';

  fetchTotalHitsTask: Task<any, any> = taskFor(this.fetchTotalHits);

  totalDrillinHits = trackedTask(this, this.fetchTotalHitsTask, () => [
    this.args.renderableChart,
    this.sortBy,
    this.sortDirection,
    this.filters,
  ]);

  //remove buttons for exports types
  get canSeeChartDataExport() {
    let metricModel = this.args.chart.chartSeries.firstObject?.metric.model;
    return metricModel === 'conversation' && !this.args.renderableChart.reportState?.isStatic;
  }

  get canSeeChartDataPointsExport() {
    return !this.args.renderableChart.isCounter;
  }

  //disable the buttton for drillin export over 10,000 hits
  get exceedsMaxLimit() {
    return this.totalHits > 10000;
  }

  get filters() {
    // TODO pass in per-metric filters
    return buildFiltersForDataConfig(
      this.args.renderableChart,
      this.metric,
      undefined,
      this.args.chart.chartSeries.firstObject.filters,
      false,
      this.metric.datasetId !== 'admin_status_change',
    );
  }

  get metric(): Metric {
    return this.args.renderableChart.firstMetric;
  }

  get sortBy() {
    return this.reportingUnderlyingDataService.getSortByAttributeId(this.metric);
  }

  get sortByProperty() {
    return this.reportingUnderlyingDataService.getSortByProperty(this.metric);
  }

  get suggestedColumns() {
    return this.reportingUnderlyingDataService.getSuggestedAttributeIds(this.metric);
  }

  @action
  exportSelectedOption() {
    if (this.exportType === 'drillin-export') {
      let analyticsEvent = { object: 'export_options_modal', rows: this.totalHits };
      // TODO update this use the chosen chartSeries, and not just the first one
      this.reportingCsvExport.exportCsv(
        this.args.renderableChart,
        this.args.chart.chartSeries.firstObject,
        this.args.chart.chartSeries.firstObject.filters,
        this.suggestedColumns,
        this.args.renderableChart.time,
        this.sortBy,
        this.sortDirection,
        this.args.isReportLevel,
        analyticsEvent,
        this.args.renderableChart.reportState.timezone,
      );
    } else if (this.exportType === 'chart-data-export') {
      this.reportingCsvExport.exportChartData(this.args.chart, this.args.report);
    } else if (this.exportType === 'chart-data-points-export') {
      this.reportingCsvExport.exportChartDataPoints(
        this.args.renderableChart,
        this.args.chart,
        this.args.report,
      );
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'export_button_clicked',
      place: this.args.eventPlace,
      object: 'export',
      exportType: this.exportType,
    });
    this.onClose();
  }

  @action
  onClose() {
    this.exportType = null;
    this.args.onClose();
  }

  @action
  updateExportType(exportType: any) {
    this.exportType = exportType;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'export_type_updated',
      place: this.args.eventPlace,
      object: exportType,
    });
  }

  //query the by_custom_charts to get total hits
  @restartableTask
  *fetchTotalHits(): TaskGenerator<any> {
    try {
      let columns = this.reportingUnderlyingDataService.getDefaultColumns(
        //TODO: support multimetric here
        this.args.chart.chartSeries.firstObject,
        this.args.renderableChart,
      );
      let response: DrillInResult = yield this.reportingUnderlyingDataService.loadDrillInResult(
        this.appService.app.id,
        this.metric,
        this.filters as LogicalFilter,
        0,
        0,
        this.args.renderableChart.time,
        columns,
        this.reportingUnderlyingDataService.getSortByAttributeId(this.metric),
        this.sortDirection,
        this.reportingChartService.useSyntheticData,
        this.args.renderableChart.reportState.timezone,
      );
      this.totalHits = response.totalHits;

      return this.totalHits;
    } catch (e) {
      console.error(e);
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ExportOptionsModal': typeof ExportOptionsModal;
  }
}
