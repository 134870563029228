/* import __COLOCATED_TEMPLATE__ from './donut-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { getOwner } from '@ember/application';
import type Range from 'embercom/models/reporting/range';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
import {
  type RawChartData,
  type ViewConfig,
  type OptionalNumber,
  type DonutSeriesData,
} from 'embercom/services/reporting-chart-service';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { cached } from 'tracked-toolbox';
import { zip } from 'underscore';
import {
  mapFromTimeStampToLabelForPieChart,
  mapXAxisLabel,
} from 'embercom/lib/reporting/flexible/label-formatter';
import { isPresent } from '@ember/utils';
import DonutChartBuilder from 'embercom/lib/reporting/flexible/donut-chart-builder';
import { inject as service } from '@ember/service';
import { DONUT_CHART_INNER_SIZE } from 'embercom/lib/reporting/flexible/default-donut-chart-config';
import { HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { shouldAllowZeroValues } from 'embercom/lib/reporting/flexible/data-response-helpers';
import { type SeriesPieOptions } from 'highcharts';

interface Signature {
  Args: Args;
  Blocks: {
    default: [{ Empty: Function }];
  };
}

interface Args {
  dataConfig: any;
  range: Range;
  width: string;
  isBeingRenderedOnAReport: boolean;
  viewConfig: ViewConfig;
  renderableChart: RenderableChart;
  chartHeight: number;
  isPaywalled: boolean;
}

export default class ReportingFlexibleDonutChart extends Component<Signature> {
  @service declare reportingChartService: ReportingChartService;
  @service declare intercomEventService: any;
  @service declare appService: any;

  @use dataLoader = AsyncData<ChartDataResourceCompatible>(async () => {
    return new ChartDataResourceCompatible(getOwner(this), {
      dataConfig: this.args.dataConfig,
      viewConfig: this.args.viewConfig,
      renderableChart: this.args.renderableChart,
    });
  });

  get dataResource(): ChartDataResourceCompatible | undefined {
    return this.dataLoader.value;
  }

  get aggregationFunction() {
    return this.args.dataConfig?.series?.firstObject?.type;
  }

  get shouldAllowZeroValues() {
    return shouldAllowZeroValues(this.args.viewConfig.metrics?.[0], this.aggregationFunction);
  }

  get rawChartData() {
    return this.dataResource?.rawChartData || [];
  }

  private buildSeriesData(data: RawChartData): DonutSeriesData[] {
    let group = data.groups[0];
    let aggregation = group.aggregations[0];
    let seriesData = zip(group.values, aggregation.values).map(([name, value]) => {
      return {
        name,
        value,
      };
    });
    return seriesData.map((data, idx) => {
      // If the value is zero and we allow zeros, return zero else return null
      let cleanedValue: OptionalNumber =
        data.value !== 0 || this.shouldAllowZeroValues ? data.value : null;
      if (this.args.dataConfig.xAxis.type === 'nominal') {
        let unMappedName: string = data.name;
        let mappedX: string = mapXAxisLabel(this.args.dataConfig, this.args.viewConfig, data.name);
        return { name: mappedX, y: cleanedValue, unMappedName };
      } else {
        // we need to convert timestamps to date time
        let interval = this.args.dataConfig.xAxis?.data?.interval;

        return {
          name: mapFromTimeStampToLabelForPieChart(
            this.args.viewConfig,
            this.args.range,
            interval,
            seriesData,
            idx,
          ),
          y: cleanedValue,
        };
      }
    });
  }

  get chartOptions() {
    let builder = new DonutChartBuilder(
      this.args.range,
      this.args.viewConfig,
      this.args.dataConfig,
      this.args.width,
      this.heightType,
      this.chartData,
      this.appService.app,
      this.args.chartHeight,
    );
    return builder.buildTheme();
  }

  get heightType() {
    return this.args.isBeingRenderedOnAReport ? 'custom' : HEIGHT_TYPES.TALL;
  }

  get chartData(): SeriesPieOptions[] {
    let metricIds = this.args.viewConfig.metrics.map((metric) => metric.id);
    return zip(metricIds, this.seriesData).map(([metricId, data]) => {
      let seriesName = metricId;
      return {
        type: 'pie',
        name: seriesName,
        colorByPoint: true,
        innerSize: DONUT_CHART_INNER_SIZE,
        data,
      };
    });
  }

  @cached
  get seriesData(): DonutSeriesData[][] {
    try {
      return this.rawChartData.map((data) => this.buildSeriesData(data));
    } catch (e) {
      console.error(e);
      this.dataResource?.notifyError();
      return [];
    }
  }

  get hasData() {
    if (this.shouldAllowZeroValues) {
      return this.seriesData.flat().some((data) => isPresent(data.y));
    }
    return this.seriesData.flat().some((data) => data.y);
  }

  get showEmptyState() {
    return this.args.isPaywalled || !this.hasData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::DonutChart': typeof ReportingFlexibleDonutChart;
    'reporting/flexible/donut-chart': typeof ReportingFlexibleDonutChart;
  }
}
