/* import __COLOCATED_TEMPLATE__ from './link-to-report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    route: string;
  };
}

const LinkToReport = templateOnlyComponent<Signature>();
export default LinkToReport;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::InContext::LinkToReport': typeof LinkToReport;
  }
}
